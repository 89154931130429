<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="text-subtitle-1 grey lighten-2">
                {{title}}
            </v-card-title>
            <v-card-text>
                <br />
                <v-data-table :headers="headers"
                              :items="list"
                              :items-per-page="5"
                              hide-default-header
                              :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[5]
                                          }"
                              class="elevation-1">
                </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-tooltip v-if="excel && !fileExcel" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon
                               color="brown"
                               v-bind="attrs"
                               v-on="on"
                               :loading="loadingExcel"
                               @click="listaExcel">
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Genera lista Excel dall'elenco</span>
                </v-tooltip>
                <v-tooltip v-if="excel && fileExcel" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :loading="loadingExcel"
                               icon
                               color="error"
                               v-bind="attrs"
                               v-on="on"
                               @click="scaricaExcel">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>Scarica file Excel</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn color="deep-orange darken-4" dark
                                                            @click="onOk">
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import { bus } from '@/main.js'
    import { callPost } from '@/modules/utilities.js'

    export default {
        data: () => ({
            dialog: false,
            excel: true,
            loadingExcel: false,
            fileExcel: '',
            snackError: false,
            headers: [
                { text: '', value: 'desc' },
                { text: '', value: 'subDesc'},
                { text: '', value: 'value', align:'right'}
            ],
            list: [],
            title:''
        }),
        methods: {                      
            onOpen({ list, title, excel = true }) {
                this.list = list;
                this.title = title;
                this.excel = excel;
                this.dialog = true;
            },
            onOk() {
                this.dialog = false;
            },
            async listaExcel() {
                this.loadingExcel = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    listaClienti: this.list,
                };
                let outputPdf = await callPost('api/excel/listaclienti', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                }
                else this.snackError = true;
                this.loadingExcel = false;
            },
            scaricaExcel() {
                //alert(this.filePdf);
                window.open(this.fileExcel);
                this.fileExcel = '';
            }
        },
        async created() {           
            bus.$on('msg-list-open', this.onOpen)            
        },
        beforeDestroy() {
            bus.$off('msg-list-open', this.onOpen);
        }
    }

</script>

