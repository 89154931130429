<template>
    <v-card class="ma-2">
        <v-card-title>
            <v-icon x-large left>
                mdi-clock-edit-outline
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Operatori: {{listaStat.length}}
            </span>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="2">
                    <v-switch class="mt-0"
                        v-model="storico"
                        @click="change"
                        :label="storico ? 'storico: ultimo anno' : 'storico: ultimo mese'"
                        >
                    </v-switch>
                </v-col>             
                <v-col cols="2">
                    <v-text-field class="mt-2 mx-1"
                                    v-model="dataDa"
                                    label="Dal"
                                    outlined
                                    clearable
                                    persistent-hint
                                    type="date"
                                    @change="change"
                                    dense>
                    </v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field class="mt-2"
                                    v-model="dataA"
                                    label="Al"
                                    outlined
                                    clearable
                                    persistent-hint
                                    type="date"
                                    @change="change"
                                    dense>
                    </v-text-field>                       
                </v-col>
                <v-col cols="3">
                    <v-select class="mt-2"
                              v-model="operatore.id"
                              :items="listaOperatore"
                              item-value="id"
                              item-text="denominazioneNormalizzata"
                              label="Filtra per Operatore"
                              clearable
                              outlined
                              @change="changeSearch"
                              dense></v-select>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" 
                          :items="listaStat"
                          item-key="operatore.id"
                          show-expand
                          single-expand
                          :expanded.sync="expanded"
                          @item-expanded="expandOperatore"
                          loading-text="Caricamento dati..."
                          no-results-text="Nessun operatore nel periodo selezionato"
                          :items-per-page="20"
                          :loading="loading"
                          :search="search"
                          :custom-filter="customFilter"
                          class="elevation-0 transparent"
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                          no-data-text="Nessun operatore nel periodo selezionato">

                          
                <template v-slot:item.operatore="{ item }">
                    <span class="font-weight-bold" v-text="item.operatore.denominazioneNormalizzata" />                  
                </template>
                <template v-slot:item.minData="{ item }">
                    <span class="text-subtitle" v-text="localData(item.minData)" />    <br/>
                    <span class="text-subtitle" v-text="localData(item.maxData)" />              
                </template>
                <template v-slot:item.work="{ item }">
                    <span class="text-subtitle">{{item.work}}m</span>
                    <br/>
                    <span class="text-subtitle">{{item.workHour}}</span>
                </template>
                <template v-slot:item.idle="{ item }">
                    <span class="text-subtitle">{{item.idle}}m</span>
                    <br/>
                    <span class="text-subtitle">{{item.idleHour}}</span>
                </template>              
                <template v-slot:item.pWork="{ item }">
                    <v-progress-linear      dark                   
                        :value="item.pWork" 
                        color="teal"
                        height="20"
                        rounded>
                        {{ item.pWork }}%   
                    </v-progress-linear>
                    <v-progress-linear                         
                        :value="item.pIdle" 
                        color="deep-orange darken-4"
                        height="20"
                        rounded>
                        {{ item.pIdle }}%   
                    </v-progress-linear>                
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td></td>
                    <td colspan="12">
                        <v-card>
                            <v-data-table class="my-2"
                                          :headers="headersDettagli"
                                          :items="dettagli"
                                          :items-per-page="7"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessun task presente"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,7,10,20,-1]
                                          }"
                                          >    
                                          <template v-slot:item.data="{ item }">
                                            <span class="text-subtitle" v-text="localData(item.data)" />  
                                        </template>    
                                        <template v-slot:item.work="{ item }">
                                            <span class="text-subtitle">{{item.work}}m</span>
                                            <br/>
                                            <span class="text-subtitle">{{item.workHour}}</span>
                                        </template>
                                        <template v-slot:item.idle="{ item }">
                                            <span class="text-subtitle">{{item.idle}}m</span>
                                            <br/>
                                            <span class="text-subtitle">{{item.idleHour}}</span>
                                        </template>        
                                        <template v-slot:item.pWork="{ item }">
                                            <v-progress-linear      dark                   
                                                :value="item.pWork" 
                                                color="teal"
                                                height="20"
                                                rounded>
                                                {{ item.pWork }}%   
                                            </v-progress-linear>
                                            <v-progress-linear                         
                                                :value="item.pIdle" 
                                                color="deep-orange darken-4"
                                                height="20"
                                                rounded>
                                                {{ item.pIdle }}%   
                                            </v-progress-linear>                
                                        </template> 
                                        <template v-slot:item.dettaglio="{ item }">                                            
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="primary"
                                                           dark
                                                           v-bind="attrs"
                                                           icon
                                                           @click="viewTask(item)"
                                                           v-on="on">
                                                        <v-icon>mdi-magnify</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-data-table :headers="headersTaskWork"
                                                                  :items="dettagliTaskEstesi"
                                                                  :items-per-page="100"
                                                                  class="elevation-1 ma-2"
                                                                  hide-default-footer                                                               
                                                                  loading-text="Caricamento dati..."
                                                                  no-data-text="Nessun dato presente in archivio"
                                                                  no-results-text="Nessun task presente">
                                                                  
                                                        <template v-slot:item.cliente="{ item }">
                                                            <span class="text-title">{{item.task.pratica.cliente.denominazioneNormalizzata}}</span>
                                                            <br/>
                                                            <span class="text-subtitle red--text">{{item.task.note}}</span>
                                                        </template> 
                                                        <template v-slot:item.dataStart="{ item }">
                                                            <span class="text-subtitle" v-text="localDateTime(item.dataStart)" />
                                                        </template>
                                                        <template v-slot:item.dataStop="{ item }">
                                                            <span class="text-subtitle" v-text="localDateTime(item.dataStop)" />
                                                        </template>
                                                        <template v-slot:item.work="{ item }">
                                                            <span class="text-subtitle">{{item.work}}m</span>
                                                            <br/>
                                                            <span class="text-subtitle">{{item.workHour}}</span>
                                                        </template>   
                                                        <template v-slot:item.gpsStart="{ item }">
                                                            <a v-if="item.coordinateStart" target="_blank"
                                                               style="text-decoration: none;"
                                                               :href="'https://maps.google.it/?q='+item.coordinateStart">
                                                                <v-icon color="brown">mdi-map-marker</v-icon>
                                                            </a>
                                                        </template>
                                                        <template v-slot:item.gpsStop="{ item }">
                                                            <a v-if="item.coordinateStop" target="_blank"
                                                               style="text-decoration: none;"
                                                               :href="'https://maps.google.it/?q='+item.coordinateStop">
                                                                <v-icon color="brown">mdi-map-marker</v-icon>
                                                            </a>                                                            
                                                        </template>
                                                    </v-data-table>
                                                </v-list>
                                            </v-menu>
                                        </template>            
                            </v-data-table>
                        </v-card>
                    </td>
                </template>                       
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>



<script>
    import { callService, gLocalDateTime, gLocalData, gServerData, gDataCalendar } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        data: () => ({
            loading: false,
            hCard: 450,
            listaStat: [],
            listaGroup: [],
            listaTaskWork:[],
            pratica:{},
            expanded:[],
            dettagli:[],
            storico: false,
            search: '',
            searchText: '',
            dettagliTaskEstesi:[],
            dataA: null,
            dataDa: null,
            operatore: { id: '' },
            listaOperatore: [],
            headers: [
                { text: 'Operatore', value: 'operatore' },
                { text: 'Periodo', value: 'minData' },
                { text: 'Lavoro', value: 'work' },
                { text: 'Pause', value: 'idle' },
                { text: 'Percentuale %', value: 'pWork', width:'30%' },
            ],
            headersDettagli: [
                { text:'',value: 'dettaglio', sortable: false,width:'5%' },
                { text: 'Data', value: 'data' },
                { text: 'Lavoro', value: 'work' },
                { text: 'Pause', value: 'idle' },
                { text: 'Percentuale %', value: 'pWork', width:'30%' },
            ],
            headersTaskWork: [
                { text: 'Cliente', value: 'cliente' },
                { text: 'Lavoro', value: 'work'},
                { text: 'Start', value: 'dataStart' },
                { text: 'GPS', value: 'gpsStart' },
                { text: 'Stop', value: 'dataStop' },
                { text: 'GPS', value: 'gpsStop' },
            ],
        }),
        methods: {            
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            async change() {
                await this.internalCreated();
            },
            localData(v) {
                return gLocalData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            async onPraticaChanged(){
                await this.internalCreated();
            },
            dataCalendar(v) {
                return gDataCalendar(v);
            },
            async internalCreated() {
                this.loading = true;
                let da =JSON.parse(JSON.stringify(this.dataDa));
                let a =JSON.parse(JSON.stringify(this.dataA));
                if(da==null) da="1900-01-01";
                if(a==null) a="2500-12-31";
                let param = [this.storico,da,a];
                let outputStat = await callService("api/working", param);
                this.listaStat = outputStat.listaStat;
                this.listaGroup = outputStat.listaGroup;
                this.listaTaskWork = outputStat.listaTaskWork;
                this.loading = false;
            },
            colorGrey(v) {
                if(v>150000) v=150000;
                if(v<50000) v=50000;
                v=v-50000;
                let r=255;
                let xv=100000-v;
                // g:100000 = x : xv;
                let r1 = xv*r/100000-100;
                return this.rgb(r1, r1, r1);       
            },
            colorGreyTotal(v) {
                if(v>1500000) v=1500000;
                if(v<300000) v=300000;
                v=v-300000;
                let r=255;
                let xv=1200000-v;
                // g:100000 = x : xv;
                let r1 = xv*r/1200000-100;
                return this.rgb(r1, r1, r1);       
            },            
            colorPink(v) {
                if(v>280000) v=280000;
                if(v<50000) v=50000;
                v=v-50000;
                let r=255;
                let g=0;
                let b=155;
                let xv=230000-v;
                // g:100000 = x : xv;
                let r1 = xv*r/230000;
                let b1 = xv*b/230000;
                return this.rgb(r1, g, b1);       
            },
            colorBlue(v) {
                if(v>15000) v=15000;
                let r=0;
                let g=0;
                let b=255;
                let xv=15000-v;
                let b1 = xv*b/15000;
                return this.rgb(r, g, b1);       
            },
            colorGreen(v) {
                let r=0;
                let b=0;
                if(v==0) v=1;
                let max=100;               
                let xv=max-v;
                let x=xv*200/100;
                return this.rgb(r, 55+x, b);     
            },
            colorRed(v) {
                let g=0;
                let b=0;
                if(v==0) v=1;
                let max=100;               
                let xv=max-v;
                let x=xv*255/100;
                return this.rgb(x, g, b);     
            },
            colorPinkTotal(v) {
                if(v>5000000) v=5000000;
                if(v<100000) v=100000;
                v=v-300000;
                let r=255;
                let g=0;
                let b=155;
                let xv=4900000-v;
                // g:100000 = x : xv;
                let r1 = xv*r/4900000;
                let b1 = xv*b/4900000;
                return this.rgb(r1, g, b1);       
            },
            rgb(r, g, b){
                return "rgb("+r+","+g+","+b+")";
            },     
            expandOperatore({ item, value }) {
                if (value) {
                    this.dettagli = this.listaGroup.filter(x=>x.operatore.id==item.operatore.id);
                }
            },
            viewTask(item) {
                this.dettagliTaskEstesi=this.listaTaskWork
                .filter(x=>x.task.operatore.id==item.operatore.id &&
                           x.task.dataPresaInCaricoSecca == item.data);
            },
            changeSearch() {
                            this.search =
                            this.operatore.id                         
                            + this.dataDa + this.dataA;
                    },
            customFilter(value, search, item) {
                //console.log(JSON.stringify(item));
                let ok = true;                
                
                if (ok && this.operatore.id) {
                    ok =  item.operatore.id == this.operatore.id;
                }                          
                
                return ok;
            },
        },
        computed: {
            
        },
        async created() {
            this.listaOperatore = this.$store.state.listaOperatore;
            await this.internalCreated();
        },        
        beforeDestroy() {
        }
    }</script>
