<template>
    <v-dialog v-model="dialog" persistent max-width="1400px">
        <v-card :loading="loading">
            <v-form v-model="valid">
                <v-card-text>
                    <v-row dense>
                        <v-col cols="4">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                        SCHEDA CLIENTE
                                    </div>
                                    <v-list-item-title class="text-h5 mb-1">
                                        <span class="blue--text text--darken-3"><b>{{cliente.codice}}</b></span> {{cliente.denominazioneNormalizzata}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{cliente.tipoCliente.descrizione}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                
                            </v-list-item>
                        </v-col>
                        <v-col offset="4">
                            <v-select
                                class="mt-2"
                                v-model="idAnno"
                                :items="listaAnni"
                                item-value="id"
                                item-text="anno"
                                label="Seleziona anno"
                                hint="Non è possibile registrare addebiti o pagamenti per gli anni già chiusi"
                                persistent-hint
                                outlined
                                @change="selectAnno"
                                dense>
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-btn v-if="tab==0" :disabled="!valid" class="mt-2 mr-2" color="deep-orange darken-4" :dark="valid"
                               @click="salvaNoteAttivita()">
                            Salva
                        </v-btn>                   
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="chiudi">
                            Chiudi
                        </v-btn>
                        </v-col>
                    </v-row>
                    
                    <template>
                        <v-tabs v-model="tab">
                            <v-tab>Riepilogo</v-tab>
                            <v-tab>Tariffe e Pagamenti</v-tab>
                            <v-tab>
                                Dettaglio Scheda
                                <v-tooltip v-if="!filePdf && tab==2" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :loading="loadingPdf"
                                               :disabled="scheda.movimenti.length == 0"
                                               icon x-large
                                               color="pink"
                                               v-bind="attrs"
                                               v-on="on"
                                               @click="programmaPdf">
                                            <v-icon>mdi-file-pdf-box</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Genera PDF</span>
                                </v-tooltip>
                                <v-tooltip v-if="filePdf && tab==2" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :loading="loadingPdf"
                                               icon x-large
                                               color="error"
                                               v-bind="attrs"
                                               v-on="on"
                                               @click="visualizzaPdf">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Visualizza PDF</span>
                                </v-tooltip>
                                <v-tooltip v-if="filePdf && tab==2" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :loading="loadingPdf"
                                               icon x-large
                                               color="success"
                                               v-bind="attrs"
                                               v-on="on"
                                               @click="inviaMail">
                                            <v-icon>mdi-email-fast-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Invia PDF</span>
                                </v-tooltip>

                            </v-tab>
                            <v-tab>Addebiti in lavorazione</v-tab>
                            <v-spacer></v-spacer>
                            <v-btn v-if="tab==2" class="mt-2 mr-2" color="deep-orange darken-4" 
                                    :dark="!disableSave"
                                    :disabled="disableSave"                                    
                                   @click="viewAS(false)">
                                Addebito
                            </v-btn>
                            <v-btn v-if="tab==2" class="mt-2 mr-2" color="teal"
                                    :dark="!disableSave"
                                    :disabled="disableSave" 
                                   @click="viewPAG(false)">
                                Pagamento
                            </v-btn>
                            <v-btn v-if="tab==2" class="mt-2 mr-2" color="orange darken-2"
                                    :dark="!disableSave"
                                    :disabled="disableSave" 
                                   @click="viewPAG(true)">
                                Abbuono/Storno
                            </v-btn>
                            <v-btn v-if="tab==3" class="mt-2 mr-2" color="orange darken-2" 
                                    :dark="!disableSave"
                                    :disabled="disableSave" 
                                   @click="viewAS(true)">
                                Nuovo movimento
                            </v-btn>
                            <v-btn v-if="tab==3" class="mt-2 mr-2" color="teal" 
                                   :dark="selezione.length>0 || $store.state.annoAperto"
                                   :disabled="selezione.length==0 || disableSave"
                                   @click="viewAP()">
                                Addebita totale al cliente
                            </v-btn>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-row dense>
                                    <v-col cols="3">
                                        <v-card class="ma-2" :color="scheda.isRosso ? 'deep-orange darken-4':(scheda.isGiallo ? 'orange':'teal')" :height="hCard"
                                                dark>
                                            <v-card-title>
                                                <v-icon x-large left>
                                                    mdi-calculator
                                                </v-icon>
                                                <span class="text-h6 font-weight-light">Saldo</span>
                                            </v-card-title>

                                            <v-card-subtitle v-if="scheda.saldo>=0">
                                                Importo dovuto corrispondende al saldo della scheda
                                            </v-card-subtitle>
                                            <v-card-subtitle v-if="scheda.saldo<0">
                                                Importo in avanzo corrispondende al saldo della scheda
                                            </v-card-subtitle>

                                            <v-card-text align="right">
                                                <v-row>
                                                    <v-col align="right" align-self="end">
                                                        <span class="text-subheading-1 font-weight-black"
                                                              v-text="localData(scheda.dataSaldo)" />
                                                        <br />
                                                        <span class="text-h4 font-weight-black"
                                                              v-text="importoEuro(scheda.saldo)" />
                                                        <br />
                                                        <span v-if="scheda.cliente.importoFido>0" class="text-subheading-1 font-weight-black"
                                                              v-text="'(Fido '+importoEuro(scheda.cliente.importoFido)+')'" />
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>

                                        </v-card>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-card class="ma-2" color="blue darken-2" :height="hCard"
                                                dark>
                                            <v-card-title>
                                                <v-icon x-large left>
                                                    mdi-calendar-clock
                                                </v-icon>
                                                <span class="text-h6 font-weight-light">Ultimo Pagamento</span>
                                            </v-card-title>

                                            <v-card-subtitle>
                                                Data e importo dell'ultimo pagamento eseguito dal cliente
                                            </v-card-subtitle>
                                            <v-card-text align="right">
                                                <v-row>
                                                    <v-col align="right" align-self="end">
                                                        <br v-if="scheda.importoUltimoPagamento==0" />
                                                        <span v-if="scheda.importoUltimoPagamento==0"
                                                              class="text-h4 font-weight-black">
                                                            -
                                                        </span>
                                                        <span v-if="scheda.importoUltimoPagamento>0"
                                                              class="text-subheading-1 font-weight-black"
                                                              v-text="localData(scheda.dataUltimoPagamento)" />
                                                        <br v-if="scheda.importoUltimoPagamento>0" />
                                                        <span v-if="scheda.importoUltimoPagamento>0"
                                                              class="text-h4 font-weight-black"
                                                              v-text="importoEuro(scheda.importoUltimoPagamento)" />
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-textarea v-model="cliente.note"
                                                    class="mt-2" rows="7"
                                                    outlined
                                                    label="Note e indicazioni sul Cliente"
                                                    placeholder="Note e indicazioni sul Cliente">

                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row dense class="mt-n6">
                                    <v-layout child-flex>
                                        <v-data-table no-data-text="Non sono presenti attività"
                                                      :headers="headersAttivita"
                                                      :items="listaAttivita"
                                                      :items-per-page="6"
                                                      dense
                                                      class="elevation-1 ma-4"
                                                      :footer-props="{
                                            'items-per-page-text':'',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[6]
                                          }">
                                            <template slot="header.elimina">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" color="info" class="ml-2"
                                                               v-on="on"
                                                               icon @click="newAttivita">
                                                            <v-icon>mdi-folder-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        Aggiungi Attività
                                                    </span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:item.operatore="{ item }">
                                                <span v-text="item.operatoreCreazione.denominazioneNormalizzata" />
                                                <br />
                                                <span class="font-weight-light" v-text="localData(item.dataCreazione)" />
                                            </template>
                                            <template v-slot:item.note="{ item }">
                                                <v-textarea v-model="item.note"
                                                            outlined
                                                            :rules="[v=> !!v || 'inserire note']"
                                                            rows="1"
                                                            auto-grow hide-details
                                                            dense />
                                            </template>
                                            <template v-slot:item.dataScadenza="{ item }">
                                                <v-text-field v-if="!item.isFissa" v-model="item.dataScadenza"
                                                              placeholder="gg/mm/aaaa"
                                                              outlined
                                                              :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                              hide-details
                                                              persistent-hint
                                                              clearable
                                                              dense></v-text-field>
                                            </template>
                                            <template v-slot:item.operatoreDestinazione="{ item }">
                                                <v-select v-if="!item.isFissa" v-model="item.operatoreDestinazione.id"
                                                          :items="listaOperatore"
                                                          item-value="id"
                                                          item-text="denominazioneNormalizzata"
                                                          hide-details
                                                          outlined
                                                          required
                                                          :rules="[v=> v.length>0 || 'campo richiesto']"
                                                          dense></v-select>
                                            </template>
                                            <template v-slot:item.isFissa="{ item }">
                                                <v-checkbox class="mt-0"
                                                            v-model="item.isFissa"
                                                            hide-details />
                                            </template>
                                            <template v-slot:item.isChiusa="{ item }">
                                                <v-checkbox v-if="!item.isFissa" class="mt-0"
                                                            v-model="item.isChiusa"
                                                            hide-details />
                                            </template>
                                            <template v-slot:item.elimina="{ item }">
                                                <v-btn icon @click="eliminaAttivita(item)" color="error darken-2" class="ml-2">
                                                    <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <template slot="footer.prepend">
                                                <v-checkbox @click="filterAttivita" v-model="viewAll" label="Visualizza anche attività chiuse" />
                                            </template>
                                        </v-data-table>
                                    </v-layout>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-card class="ma-2" :height="2.11*hCard" color="orange lighten-3">
                                            <v-card-title>
                                                <v-icon x-large left>
                                                    mdi-chart-timeline-variant
                                                </v-icon>
                                                <span class="text-h6 font-weight-light">
                                                    Distribuzione pagamenti ultimi 12 mesi
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <GChart class="ma-n6" type="ComboChart"
                                                        :data="chartData"
                                                        :options="chartOptions"
                                                        :settings="{language: 'it'}" />

                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col v-if="scheda">
                                        <v-row dense v-if="isContabilita">
                                            <v-col v-if="scheda.mappaTariffe[0].tariffe.length>0">
                                                <v-card class="ma-2" color="indigo"
                                                        dark>
                                                    <v-card-title>
                                                        <v-icon x-large left>
                                                            mdi-file-sign
                                                        </v-icon>
                                                        <span class="text-h6 font-weight-light">{{scheda.mappaTariffe[0].tipoConsulenza.descrizione}}</span>
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        Tariffe specifiche impostate per il cliente per l'anno in corso
                                                    </v-card-subtitle>
                                                    <v-card-text>
                                                        <v-row class="mt-4">
                                                            <v-col cols="2" class="pa-0" align="center" align-self="end" v-for="tariffa in scheda.mappaTariffe[0].tariffe" :key="tariffa.id">
                                                                <span style="font-size:small">{{tariffa.tipoServizio.descrizione}}</span>
                                                                <br />
                                                                <span class="text-body-1 font-weight-black"
                                                                      v-text="importoEuro(tariffa.importo)" />
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col v-else>
                                                <v-card class="ma-2" color="indigo" :height="hCard"
                                                        dark>
                                                    <v-card-title>
                                                        <v-icon x-large left>
                                                            mdi-file-sign
                                                        </v-icon>
                                                        <span class="text-h6 font-weight-light">{{scheda.mappaTariffe[0].tipoConsulenza.descrizione}}</span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        Tariffe non impostate
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row dense v-if="isLavoro">
                                            <v-col v-if="scheda.mappaTariffe[1].tariffe.length>0">
                                                <v-card class="ma-2" color="purple"
                                                        dark>
                                                    <v-card-title>
                                                        <v-icon x-large left>
                                                            mdi-hammer-wrench
                                                        </v-icon>
                                                        <span class="text-h6 font-weight-light">{{scheda.mappaTariffe[1].tipoConsulenza.descrizione}}</span>
                                                    </v-card-title>

                                                    <v-card-subtitle>
                                                        Tariffe specifiche impostate per il cliente per l'anno in corso
                                                    </v-card-subtitle>
                                                    <v-card-text>
                                                        <v-row class="mt-4">
                                                            <v-col cols="2" class="pa-0" align="center" align-self="end" v-for="tariffa in scheda.mappaTariffe[1].tariffe" :key="tariffa.id">
                                                                <span style="font-size:small">{{tariffa.tipoServizio.descrizione}}</span>
                                                                <br />
                                                                <span class="text-body-1 font-weight-black"
                                                                      v-text="importoEuro(tariffa.importo)" />
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col v-else>
                                                <v-card class="ma-2" color="purple" :height="hCard"
                                                        dark>
                                                    <v-card-title>
                                                        <v-icon x-large left>
                                                            mdi-hammer-wrench
                                                        </v-icon>
                                                        <span class="text-h6 font-weight-light">{{scheda.mappaTariffe[1].tipoConsulenza.descrizione}}</span>
                                                    </v-card-title>

                                                    <v-card-text>
                                                        Tariffe non impostate
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row dense v-else>
                                            <v-col v-if="scheda.mappaContratti[0].contratti.length>0">
                                                <v-card class="ma-2" color="indigo"
                                                        dark>
                                                    <v-card-title>
                                                        <v-icon x-large left>
                                                            mdi-hammer-wrench
                                                        </v-icon>
                                                        <span class="text-h6 font-weight-light">{{scheda.mappaContratti[0].tipoConsulenza.descrizione}}</span>
                                                    </v-card-title>

                                                    <v-card-subtitle>
                                                        Contratti impostati per il cliente per l'anno in corso
                                                    </v-card-subtitle>
                                                    <v-card-text>
                                                        <v-row class="mt-4">
                                                            <v-col cols="12" class="pa-0" align="left" align-self="end" v-for="contratto in scheda.mappaContratti[0].contratti" :key="contratto.id">
                                                                <span style="font-size:small">Descrizione: {{contratto.descrizione}} - Frequenza addebito: {{contratto.tipoPeriodicita.descrizione}} - Importo addebitato: {{importoEuro(contratto.importo)}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col v-else>
                                                <v-card class="ma-2" color="purple" :height="hCard"
                                                        dark>
                                                    <v-card-title>
                                                        <v-icon x-large left>
                                                            mdi-hammer-wrench
                                                        </v-icon>
                                                        <span class="text-h6 font-weight-light">{{scheda.mappaContratti[0].tipoConsulenza.descrizione}}</span>
                                                    </v-card-title>

                                                    <v-card-text>
                                                        Contratti non impostati
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-data-table v-if="movimentiProf.length<=1" no-data-text="Non sono presenti movimenti"
                                              :headers="headers"
                                              :items="movimenti"
                                              :items-per-page="6"
                                              class="elevation-1 ma-4"
                                              :page="pageBP"
                                              :footer-props="{
                                            'items-per-page-text':'',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[6]
                                          }">

                                    <template v-slot:item.allegato="{ item }">
                                        <div v-if="item.isAddebito && item.allegato">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon
                                                           color="blue"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           @click="viewAllegato(item)">
                                                        <v-icon>mdi-magnify</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Apri allegato</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon
                                                           color="blue"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           @click="eliminaAllegato(item)">
                                                        <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Elimina allegato</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="item.isAddebito && !item.allegato">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon
                                                           color="blue"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           @click="aggiungiAllegato(item)">
                                                        <v-icon color="blue">mdi-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Aggiungi allegato</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                    <template v-slot:item.data="{ item }">
                                        <span v-text="localData(item.data)" />
                                    </template>
                                    <template v-slot:item.dare="{ item }">
                                        <span v-if="item.dare!=0" class="deep-orange--text text--darken-4"
                                              dark>
                                            <b>{{importoEuro(item.dare)}}</b>
                                        </span>
                                    </template>
                                    <template v-slot:item.avere="{ item }">
                                        <span v-if="item.avere!=0" :class="item.isAbbuono ? 'orange--text darken-2--text' :'teal--text'"
                                              dark>
                                            <b>{{importoEuro(item.avere)}}</b>
                                        </span>
                                    </template>
                                    <template v-slot:item.saldo="{ item }">
                                        <span :class="item.saldo>0 ? 'deep-orange--text text--darken-4' :'teal--text'"
                                              dark>
                                            <b>{{importoEuro(item.saldo)}}</b>
                                        </span>
                                    </template>
                                    <template v-slot:item.professionista="{ item }">
                                        <span>
                                            <b>{{descrizioneProfessionista(item.professionista)}}</b>
                                        </span>
                                    </template>
                                    <template v-slot:item.isProgress="{ item }">
                                        <div v-if="item.isProgress">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon
                                                           color="green"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           @click="viewDettagli(item)">
                                                        <v-icon>mdi-magnify</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Dettagli</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                    <template v-slot:item.elimina="{ item }">
                                        <v-tooltip bottom v-if="!disableSave && ((isStudioPro && (($store.state.utente.operatore && $store.state.utente.operatore.professionista && cliente.professionista && $store.state.utente.operatore.professionista.id == cliente.professionista.id) || $store.state.utente.isGestoreStudio)) || (isServicePro && ($store.state.utente.isGestoreStudio || $store.state.utente.isReception))) ">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="blue"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="elimina(item)">
                                                    <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Elimina addebito</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>

                                
                                <v-tabs v-else vertical grow color="red" class="mt-6">

                                    <v-tab v-for="lista in movimentiProf" :key="lista[0].professionista">
                                        {{namingProfessionista(lista[0].professionista)}}
                                    </v-tab>

                                    <v-tab-item v-for="lista in movimentiProf" :key="lista[0].professionista">
                                        <v-data-table no-data-text="Non sono presenti movimenti"
                                                      :headers="headers"
                                                      :items="lista"
                                                      :items-per-page="6"
                                                      class="elevation-1 ma-4"
                                                      :page="lista.length/6"
                                                      :footer-props="{
                                            'items-per-page-text':'',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[6]
                                          }">

                                            <template v-slot:item.allegato="{ item }">
                                                <div v-if="item.isAddebito && item.allegato">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon
                                                                   color="blue"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   @click="viewAllegato(item)">
                                                                <v-icon>mdi-magnify</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Apri allegato</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon
                                                                   color="blue"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   @click="eliminaAllegato(item)">
                                                                <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Elimina allegato</span>
                                                    </v-tooltip>
                                                </div>
                                                <div v-if="item.isAddebito && !item.allegato">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon
                                                                   color="blue"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   @click="aggiungiAllegato(item)">
                                                                <v-icon color="blue">mdi-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Aggiungi allegato</span>
                                                    </v-tooltip>
                                                </div>
                                            </template>
                                            <template v-slot:item.data="{ item }">
                                                <span v-text="localData(item.data)" />
                                            </template>
                                            <template v-slot:item.saldo="{ item }">
                                                <v-chip :color="item.saldo>=0 ? 'deep-orange darken-4':'teal'"
                                                        dark v-text="importoEuro(item.saldo)">
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.dare="{ item }">
                                                <span v-if="item.dare!=0" class="deep-orange--text text--darken-4"
                                                      dark>
                                                    <b>{{importoEuro(item.dare)}}</b>
                                                </span>
                                            </template>
                                            <template v-slot:item.avere="{ item }">
                                                <span v-if="item.avere!=0" :class="item.isAbbuono ? 'orange--text darken-2--text' :'teal--text'"
                                                      dark>
                                                    <b>{{importoEuro(item.avere)}}</b>
                                                </span>
                                            </template>                                                                               
                                            <template v-slot:item.isProgress="{ item }">
                                                <div v-if="item.isProgress">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon
                                                                   color="green"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   @click="viewDettagli(item)">
                                                                <v-icon>mdi-magnify</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Dettagli</span>
                                                    </v-tooltip>
                                                </div>
                                            </template>
                                            <template v-slot:item.elimina="{ item }">
                                                <v-tooltip bottom v-if="!disableSave && ((isStudioPro && (($store.state.utente.operatore && $store.state.utente.operatore.professionista && cliente.professionista && $store.state.utente.operatore.professionista.id == cliente.professionista.id) || $store.state.utente.isGestoreStudio)) || (isServicePro && ($store.state.utente.isGestoreStudio || $store.state.utente.isReception))) ">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon
                                                               color="blue"
                                                               v-bind="attrs"
                                                               v-on="on"
                                                               @click="elimina(item)">
                                                            <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Elimina addebito</span>
                                                </v-tooltip>
                                            </template>
                                        </v-data-table>

                                    </v-tab-item>                                  
                                </v-tabs>
                            </v-tab-item>
                            <v-tab-item>
                                <v-data-table v-model="selezione"
                                              no-data-text="Non sono presenti movimenti"
                                              :headers="isStudioPro ? headersProgress : headersProgressService"
                                              show-select
                                              :items="scheda.addebitoProgress"
                                              :items-per-page="6"
                                              class="elevation-1 ma-4"
                                              :footer-props="{
                                            'items-per-page-text':'',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[6]
                                          }">
                                    <template v-slot:item.data="{ item }">
                                        <span v-text="localData(item.data)" />
                                    </template>
                                    <template v-slot:item.importo="{ item }">
                                        <vuetify-money v-if="importoPedit && idMovimentoEdit == item.id" class="mt-2 mb-n4" v-model="item.importo"
                                                       label="Importo"
                                                       outlined
                                                       :background-color="theme ? 'gray':'white'"
                                                       dense
                                                       :rules="[v=> !!v || 'inserire importo']"
                                                       :options="moneyOptions">
                                        </vuetify-money>
                                        <span v-else v-text="importoEuro(item.importo)" />
                                    </template>
                                    <template v-slot:item.modifica="{ item }">
                                        <v-btn v-if="importoPedit && idMovimentoEdit == item.id" icon @click="saveImportoP(item)" color="green" class="ml-2">
                                            <v-icon color="teal">mdi-content-save</v-icon>
                                        </v-btn>
                                        <v-btn v-if="!importoPedit" icon @click="editP(item)" color="green" class="ml-2">
                                            <v-icon color="teal">mdi-credit-card-edit-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.professionista.id="{ item }">
                                        <span>
                                            <b>{{descrizioneProfessionista(item.professionista.id)}}</b>
                                        </span>
                                    </template>
                                    <template v-slot:item.elimina="{ item }">
                                        <v-btn v-if="!disableSave && ((isStudioPro && (($store.state.utente.operatore && $store.state.utente.operatore.professionista && cliente.professionista && $store.state.utente.operatore.professionista.id == cliente.professionista.id) || $store.state.utente.isGestoreStudio)) || (isServicePro && ($store.state.utente.isGestoreStudio || $store.state.utente.isReception))) "
                                                icon @click="eliminap(item)" color="error darken-2" class="ml-2">
                                            <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </template>

                                </v-data-table>
                            </v-tab-item>
                        </v-tabs-items>
                    </template>
                </v-card-text>
            </v-form>
            <template>
                <v-dialog v-model="dialogPAG" persistent
                          max-width="800">
                    <v-stepper v-model="pag">
                        <v-stepper-header>
                            <v-stepper-step :complete="pag > 1"
                                            step="1">
                                Dati {{titoloPag}}
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step :complete="pag > 2"
                                            step="2">
                                Riepilogo
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step step="3">
                                Esito
                            </v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-container class="mb-12">
                                    <v-form ref="formPAG"
                                            v-model="validPAG">
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="dataPagamento"
                                                              label="Data pagamento"
                                                              outlined
                                                              :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                              hint="gg/mm/aaaa"
                                                              persistent-hint
                                                              clearable
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <vuetify-money v-model="importoPagamento"
                                                               :label="'Importo ' + this.titoloPag"
                                                               outlined
                                                               :background-color="theme ? 'gray':'white'"
                                                               dense
                                                               :rules="[v => !!v || 'inserire importo', v => (importoPagamento && importoPagamento>0) || 'inserire importo >0']"
                                                               :options="moneyOptions">
                                                </vuetify-money>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="abbuono">
                                            <v-col>
                                                <v-text-field v-model="descrizioneAbbuono" class="mn-4"
                                                              label="Descrizione"
                                                              outlined
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-select v-model="idProfessionistaPagamento"
                                                          :items="listaOperatoriProfessionisti"
                                                          item-value="professionista.id"
                                                          item-text="denominazioneNormalizzata"
                                                          label="Professionista di riferimento"
                                                          :rules="[v=> !!v || 'indicare il professionista']"
                                                          outlined
                                                          dense></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!abbuono">
                                            <v-col>
                                                <v-select v-model="idProfessionistaPagamento"
                                                          :items="listaOperatoriProfessionisti"
                                                          item-value="professionista.id"
                                                          item-text="denominazioneNormalizzata"
                                                          label="Professionista di riferimento"
                                                          :rules="[v=> !!v || 'indicare il professionista']"
                                                          outlined
                                                          dense></v-select>
                                            </v-col>
                                            <v-col>
                                                <v-select v-model="idTipoPagamento"
                                                          :items="tipiPagamento"
                                                          item-value="id"
                                                          item-text="descrizione"
                                                          label="Tipologia Pagamento"
                                                          :rules="[v=> !! v || 'indicare il tipo di pagamento']"
                                                          outlined
                                                          dense></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!abbuono">
                                            <v-col>
                                                <v-text-field v-model="notaPagamento" class="mn-4"
                                                              label="Nota pagamento (opzionale)"
                                                              outlined
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                                <v-row>
                                    <v-col cols="2">
                                        <v-btn :disabled="!validPAG" color="primary" depressed
                                               @click="pag = 2">
                                            Avanti
                                        </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-btn @click="dialogPAG = false" color="warning">
                                            Annulla
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-container class="mb-12">
                                    <v-row>
                                        <v-col cols="5" offset="1">
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-format-list-bulleted-type</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{dataPagamento}}</v-list-item-title>
                                                    <v-list-item-subtitle>data</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-cash</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{importoPagamentoEuro}}</v-list-item-title>
                                                    <v-list-item-subtitle v-text="'Importo ' + this.titoloPag"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item two-line v-if="!abbuono">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-information-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{descrizionePagamento}}</v-list-item-title>
                                                    <v-list-item-subtitle>tipo di pagamento</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-account-multiple</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{cliente.denominazioneNormalizzata}}</v-list-item-title>
                                                    <v-list-item-subtitle>cliente</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-tooltip-account</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{descrizioneProfessionista(idProfessionistaPagamento)}}</v-list-item-title>
                                                    <v-list-item-subtitle>Professionista di riferimento</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item v-if="!abbuono && notaPagamento != null" two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-note-text-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{notaPagamento}}</v-list-item-title>
                                                    <v-list-item-subtitle>Nota del pagamento</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>

                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-row>
                                    <v-col cols="2">
                                        <v-btn color="primary"
                                               @click="pag = 1">
                                            Indietro
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn color="success" depressed
                                               @click="sendPAG">
                                            Registra
                                            <v-icon right>
                                                mdi-cash-register
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-btn @click="dialogPAG = false" color="warning">
                                            Annulla
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <v-container class="mb-12" align="center">
                                    <v-col cols="6" offset="3">
                                        <v-card v-if="loadingPAG" color="primary"
                                                dark>
                                            <v-card-text>
                                                Esecuzione Operazione...
                                                <v-progress-linear indeterminate
                                                                   color="white"
                                                                   class="mb-0">
                                                </v-progress-linear>
                                            </v-card-text>
                                        </v-card>
                                        <v-card v-if="!loadingPAG && esitoPAGOK" color="success"
                                                dark>
                                            <v-card-text>
                                                <v-icon dark>
                                                    mdi-checkbox-marked-circle
                                                </v-icon>
                                                Operazione eseguita correttamente
                                            </v-card-text>
                                        </v-card>
                                        <v-card v-if="!loadingPAG && !esitoPAGOK" color="error"
                                                dark>
                                            <v-card-text>
                                                <v-icon dark>
                                                    mdi-alert-circle
                                                </v-icon>
                                                Errore durante l'esecuzione dell'operazione
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                </v-container>

                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-btn @click="dialogPAG = false"
                                               color="warning"
                                               :disabled="loadingPAG">
                                            Chiudi
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-dialog>
            </template>

            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Modifiche annullate.
            </v-snackbar>
            <v-snackbar v-model="snackRemote"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Aggiornamento remoto.
            </v-snackbar>

            <v-file-input v-model="fileDocumento"
                          prepend-icon=""
                          id="idFileDocumentoAllegato"
                          label="File Documento"
                          outlined
                          hide-input
                          type="file"
                          @change="uploadAllegato"
                          dense>
            </v-file-input>

        </v-card>

        <addebito-spesa-dialog :type="'scheda'" />
        <addebito-progress-dialog :type="'scheda'" />

    </v-dialog>

</template>


<script>

    import { callService, callPost, callPostMultipart, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'

    import { GChart } from 'vue-google-charts/legacy'
    import AddebitoSpesaDialog from '@/views/components/AddebitoSpesaDialog.vue'
    import AddebitoProgressDialog from '@/views/components/AddebitoProgressDialog.vue'

    import { bus } from '@/main.js'

    export default {
        components: {
            GChart, AddebitoSpesaDialog, AddebitoProgressDialog
        },
        data: () => ({
            valid: true,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            checkSave: false,
            viewAll: false,
            pageBP: 0,
            filePdf: '',
            idAnno:'',
            listaAnni:[],
            importoPedit: false,
            importoPPrec: 0,
            idMovimentoEdit: '',
            loadingPdf: false,
            cliente: {
                tipoCliente: ''
            },
            tipiPagamento:[],
            scheda: {
                datiGrafico: null,
                cliente: { importoFido: 0},
                mappaTariffe:[
                    {tariffe:[],
                     tipoConsulenza: {descrizione:''}
                    },
                    {tariffe:[],
                     tipoConsulenza: {descrizione:''}
                    },
                    {tariffe:[],
                     tipoConsulenza: {descrizione:''}
                    },
                    {tariffe:[],
                     tipoConsulenza:{descrizione:''}
                    }
                ],
                mappaContratti: [
                    {
                        contratti: [],
                        tipoConsulenza: { descrizione: '' }
                    }
                ]
            },
            loading: true,
            hCard: 200,
            headers: [
                { text: 'Allegato', value: 'allegato' },
                {
                    text: 'Data',
                    align: 'start',
                    sortable: true,
                    value: 'data',
                },
                { text: 'Descrizione', value: 'descrizione' },
                { text: 'Dare', value: 'dare', align: 'right' },
                { text: 'Avere', value: 'avere', align: 'right'},
                { text: 'Saldo', value: 'saldo', align: 'right' },
                { text: 'Addebito aggregato', value: 'isProgress',align: 'center'  },
                { text: '', value: 'elimina', sortable: false },

            ],
            headersProgress: [
                {
                    text: 'Data',
                    align: 'start',
                    sortable: true,
                    value: 'data',
                },
                { text: 'Tipo operazione', value: 'tipoServizio.descrizione' },
                { text: 'Descrizione', value: 'descrizione' },
                { text: 'Professionista', value: 'professionista.id' },
                { text: 'Importo', value: 'importo', align: 'right' },
                { text: 'Azioni su importo', value: 'modifica', sortable: false },
                { text: 'Elimina', value: 'elimina', sortable: false },

            ],
            headersProgressService: [
                {
                    text: 'Data',
                    align: 'start',
                    sortable: true,
                    value: 'data',
                },
                { text: 'Tipo operazione', value: 'tipoServizio.descrizione' },
                { text: 'Descrizione', value: 'descrizione' },
                { text: 'Importo', value: 'importo',align: 'right' },
                { text: 'Azioni su importo', value: 'modifica', sortable: false },
                { text: 'Elimina', value: 'elimina', sortable:false },

            ],
            headersAttivita: [
                { text: 'Operatore/Data', value: 'operatore', width:"15%" },
                { text: 'Note Attività', value: 'note', width: "30%" },
                { text: 'Fissa', align: 'start', sortable: true, value: 'isFissa'},
                { text: 'Data Scadenza', align: 'start', sortable: true, value: 'dataScadenza' },
                { text: 'Destinatario', align: 'start', sortable: true, value: 'operatoreDestinazione' },
                { text: 'Chiusa', align: 'start', sortable: true, value: 'isChiusa' },
                { text: '', value: 'elimina', sortable:false },
            ],
            listaAttivita:[],
            abbuono: false,
            pag: 1,
            dialog: false,
            dialogPAG: false,
            selezione: [],
            filtro: [],
            listaOperatore:[],
            listaOperatoriProfessionisti: [],
            oggetto:'',
            testo:'',
            validPAG: false,
            loadingPAG: false,
            esitoPAGOK: false,
            dataPagamento:'',
            importoPagamento: 0,
            descrizioneAbbuono: '',
            notaPagamento: '',
            idTipoPagamento: '',
            idProfessionistaPagamento: '',
            theme: '',
            tab:0,
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },                                 
            chartOptions: {
                settings:"{ packages: ['corechart'], language:'it' }",
                height: 350,
                width: 700,
                legend: { position: 'top' },
                backgroundColor: { fill:'transparent' },
                vAxis: { format: '€ #' },
                colors: ["green", "blue"],
                seriesType: 'bars',
                series: { 1: { type: 'line' } },
            },
            fileDocumento: null,
            addebito: null,
            movimenti:[],
            movimentiProf:[],
        }),
        methods: {    
            async selectAnno() {
                await this.internalCreated(Snack.nothing);
            },
            filterAttivita() {
                if (!this.viewAll) this.listaAttivita = this.scheda.listaAttivita.filter(a => !a.isChiusa);
                else this.listaAttivita = this.scheda.listaAttivita;
            },
            importoEuro(v) {   
                return euro(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            descrizioneProfessionista(idProf) {
                //alert(idProf);
                let p = this.listaOperatoriProfessionisti.find(x => x.professionista.id === idProf);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            tail(s,n) {
                if(!s) return "";
                if(s.length<n) return s;
                return s.substring(0,n-3);
            },
            namingProfessionista(idProf) {
                //alert(idProf);
                let p = this.listaOperatoriProfessionisti.find(x => x.professionista.id === idProf);
                if (!p) return "";
                let dnz = p.denominazioneNormalizzata;
                let l = dnz.length;
                let k = 30-l;
                for(let i=1;i<=k;i++) {
                    dnz = dnz+" ";
                }
                return this.tail(dnz,20);
            },
            viewAS(progress) {
                let cliente = JSON.parse(JSON.stringify(this.cliente));
                bus.$emit('dialog-show-as', [{ cliente: cliente }], 'scheda', progress);
                
            },
            async programmaPdf() {
                this.loadingPdf = true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idCliente: this.cliente.id,
                    idAnnoContabile: this.idAnno
                };
                let outputPdf = await callPost('api/pdf/stampaschedamulti', paramJson);
                if (outputPdf) {
                    this.filePdf = outputPdf.fileOutput;
                }
                else this.snackError = true;
                this.loadingPdf = false;
            },
            visualizzaPdf() {
                //alert(this.filePdf);
                window.open(this.filePdf);
                this.filePdf = '';
            },

            inviaMail() {
                //let idx = this.listaTask.findIndex(t => t.operatore.id && t.operatore.id != '0' && t.dataEsecuzione == null);
                var filtro = this.$store.state.contattiEstesi.find(ce => ce.cliente.id === this.cliente.id);
                var indirizzo = '';
                if (filtro) {
                    indirizzo = filtro.mailList[0];
                    //alert(indirizzo);
                }

                //console.log(JSON.stringify(filtro.ListaRecapitiMail));
                bus.$emit('msg-mail-open',
                    {
                        message: "In allegato la scheda contabile aggiornata",
                        label: "Scheda contabile aggiornata", value: this.cliente.denominazioneNormalizzata,
                        item: this.cliente, title: ": scheda contabile del cliente " + this.cliente.denominazioneNormalizzata,
                        mail: indirizzo
                    });
                bus.$on('msg-mail-cancel', this.inviaMailCancel);
                bus.$on('msg-mail-ok', this.inviaMailOk);
            },
            inviaMailCancel(cliente) {
                bus.$off('msg-mail-cancel', this.salvaModelloCancel);
                bus.$off('msg-mail-ok', this.salvaModelloOk);
            },
            async inviaMailOk(cliente, descrizione, mail, message) {
                //alert(mail);
                //alert(message);
                bus.$off('msg-mail-cancel', this.salvaModelloCancel);
                bus.$off('msg-mail-ok', this.salvaModelloOk);
                //await this.save(false, false, false, descrizione);
                await this.inviaPdf(message, mail);
            },

            async inviaPdf(message, mail) {
                this.loadingPdf = true;
                //invia la mail al cliente corrente
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    cliente: this.cliente,
                    file: this.filePdf,
                    studio: this.$store.state.studioName,
                    messaggio: message,
                    mail: mail
                };
                const result = await callPost('api/broadcast/inviaPDFScheda', param);
                this.loadingPdf = false;

                if(!result) {
                    await this.internalCreated(Snack.error);
                }
                if(result.spedita) await this.internalCreated(Snack.success);
                else {
                    bus.$emit('msg-simple-open', { title: "Mail non iviata", message: "Non sono disponibili indirizzi mail per questo cliente"});                
                }                
            },
            viewAllegato(item) {
                window.open(item.allegatoPath);
            },
            viewAP() {
                bus.$emit('dialog-show-ap', this.selezione, 'scheda');
            },
            viewPAG(abb) {
                this.abbuono = abb;
                this.pag=1;
                this.loadingPAG=false;
                this.dialogPAG=true;
                this.$nextTick(() => {
                    this.$refs.formPAG.resetValidation();
                    this.$refs.formPAG.reset();
                    this.$nextTick(() => {
                        this.dataPagamento = this.localData(new Date());
                        this.idProfessionistaPagamento = this.cliente.professionista.id;
                    });  
                });                
            },
            async sendPAG() {
                this.loadingPAG = true;
                this.pag = 3;

                //imposto lettura

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idCliente: this.cliente.id,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idTipoPagamento: this.idTipoPagamento,
                    data: this.serverData(this.dataPagamento),
                    importo: this.importoPagamento,
                    isAbbuono: this.abbuono,
                    descrizioneAbbuono: this.descrizioneAbbuono,
                    idProfessionista: this.idProfessionistaPagamento,
                    notaPagamento: this.notaPagamento
                };
                        
                const result = await callPost('api/scheda/pagamento', param);
                        
                this.esitoPAGOK = result;
                if (result) {
                    bus.$emit('cliente-changed', { lista: [this.cliente.id], sessionID: "-1" });
                    await this.internalCreated(Snack.none);
                }               
                this.loadingPAG = false;
            },
            async eliminaAllegatoCancel(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);
            },
            async eliminaAllegatoOk(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAddebito: item.id                    
                };

                this.loading = true;

                const esito = await callPost('api/addebito/eliminaAllegato', param);

                this.loading = false;

                if (esito) {
                    item.allegato=null;
                    this.snackSuccess = true;
                }
                else this.snackError = true; 

            },
            async eliminaCancel(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);
            },
            async saveImportoPCancel(item) {
                bus.$off('msg-cancel', this.saveImportoPCancel);
                bus.$off('msg-ok', this.saveImportoPOk);
                this.importoPedit = false;
                this.idMovimentoEdit=null;
                item.importo=this.importoPPrec;
            },
            async editPCancel(item) {
                bus.$off('msg-cancel', this.editPCancel);
                bus.$off('msg-ok', this.editPOk);
            },
            async eliminaOk(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idCliente: this.cliente.id,
                    id: item.id,
                    isDare: item.isDare,
                    isAvere: item.isAvere,
                    isAbbuono: item.isAbbuono,                   
                    isAddebito: item.isAddebito,
                    isPagamento: item.isPagamento, 
                    progress: false
                };

                this.loading = true;

                const result = await callPost('api/scheda/eliminaMovimento', param);

                if (result) {
                    bus.$emit('cliente-changed', { lista: [this.cliente.id], sessionID: "-1" });
                    await this.internalCreated(Snack.success);
                }
                else {
                    await this.internalCreated(Snack.error);
                }
                this.loading = false;
            },
            async eliminapOk(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idCliente: this.cliente.id,
                    id: item.id,
                    isDare: true,
                    isAvere: false,
                    isAbbuono: false,
                    isAddebito: true,
                    isPagamento: false,                    
                    progress: true
                };

                this.loading = true;

                const result = await callPost('api/scheda/eliminaMovimento', param);

                if (result) {
                    bus.$emit('cliente-changed', { lista: [this.cliente.id], sessionID: "-1" });
                    await this.internalCreated(Snack.success);
                }
                else {
                    await this.internalCreated(Snack.error);
                }
                this.loading = false;
            },
            async saveImportoPOk(item) {
                bus.$off('msg-cancel', this.saveImportoPCancel);
                bus.$off('msg-ok', this.saveImportoPOk);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idCliente: this.cliente.id,
                    id: item.id,
                    importo: item.importo
                };

                this.loading = true;

                const result = await callPost('api/scheda/aggiornaMovimentoP', param);

                if (result) {
                    this.importoPedit = false;
                    this.idMovimentoEdit = '';
                    bus.$emit('cliente-changed', { lista: [this.cliente.id], sessionID: "-1" });
                    await this.internalCreated(Snack.success);
                }
                else {
                    await this.internalCreated(Snack.error);
                }
                this.loading = false;
            },

            editPOk(item) {
                bus.$off('msg-cancel', this.ediPCancel);
                bus.$off('msg-ok', this.editPOk);
                this.importoPedit = true;
                this.idMovimentoEdit = item.id;
                this.importoPPrec=item.importo;
            },

            newAttivita() {
                this.listaAttivita.push(
                    {
                        id: new Date().getTime().toString(),
                        operatoreCreazione: this.$store.state.utente.operatore,
                        operatoreDestinazione:{id: '' },
                        dataCreazione: new Date()
                    })
            },
            async eliminaAttivita(item) {
                var i = this.listaAttivita.indexOf(item);
                this.listaAttivita.splice(i, 1);
            }, 
            async elimina(item) {
                bus.$emit('msg-open', { message: "Eliminare il movimento contabile?", item });                
                bus.$on('msg-cancel', this.eliminaCancel); 
                bus.$on('msg-ok', this.eliminaOk);
            },
            async eliminaAllegato(item) {
                bus.$emit('msg-open', { message: "Eliminare l'allegato?", item });                
                bus.$on('msg-cancel', this.eliminaAllegatoCancel); 
                bus.$on('msg-ok', this.eliminaAllegatoOk);
            }, 
            async eliminap(item) {
                bus.$emit('msg-open', { message: "Eliminare il movimento contabile?", item });
                bus.$on('msg-cancel', this.eliminaCancel);
                bus.$on('msg-ok', this.eliminapOk);
            }, 
            async saveImportoP(item) {
                bus.$emit('msg-open', { message: "Salvare l'importo?", item });
                bus.$on('msg-cancel', this.saveImportoPCancel);
                bus.$on('msg-ok', this.saveImportoPOk);
            }, 
            async editP(item) {
                bus.$emit('msg-open', { message: "Modificare questo importo?", item });
                bus.$on('msg-cancel', this.editPCancel);
                bus.$on('msg-ok', this.editPOk);
            }, 
            async onClienteChanged({ lista, sessionID }) {
                if (sessionID == this.$store.state.sessionID) return;
                
                if (lista.find(cod => cod == this.cliente.id)) {
                    await this.internalCreated(sessionID == "-1" ? Snack.nothing:Snack.remote);
                }
            },
            async internalCreated(snack) {
                this.cliente = await callService("api/cliente", this.cliente.id);
                let param = [this.cliente.id, this.idAnno];
                this.scheda = await callService('api/scheda', param);
                console.log(JSON.stringify(this.scheda.movimenti));
                for (let i = 0; i < this.scheda.listaAttivita.length; i++) {
                    var attivita = this.scheda.listaAttivita[i];
                    attivita.dataScadenza = this.localData(attivita.dataScadenza);
                    attivita.dataChiusura = this.localData(attivita.dataChiusura);
                    if (!attivita.operatoreDestinazione) attivita.operatoreDestinazione = { id: '' };
                }
                //for (let i = 0; i < this.scheda.mappaContratti[0].contratti.length; i++) {
                //    var contratto = this.scheda.mappaContratti[0].contratti[i].contratto;
                //    contratto.dataInizio = this.localData(contratto.dataInizio);
                //    contratto.dataFine = this.localData(contratto.dataFine);
                //}
                this.listaAttivita = this.scheda.listaAttivita;
                //uso il doppio passaggio per evitare il watch sulla scheda quando tocco gli allegati
                this.movimenti = JSON.parse(JSON.stringify(this.scheda.movimenti));
                this.movimentiProf = JSON.parse(JSON.stringify(this.scheda.movimentiProf));                
                this.pageBP = Math.ceil(this.movimenti.length/6);
                if (!this.viewAll) this.listaAttivita=this.listaAttivita.filter(a => !a.isChiusa);
                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);                
                this.$nextTick(() => {
                    this.checkSave = false;
                });
            },
            async viewScheda(cliente) {
                this.tab = 0;
                this.cliente = cliente;
                this.idAnno = this.$store.state.idAnnoContabile;
                this.listaOperatore = this.$store.state.listaOperatore;
                this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;
                this.importoPedit = false;
                this.idMovimentoEdit=null;
                this.dialog = true;
                this.loading = true;
                this.filePdf = '';
                let param = [];
                if(this.tipiPagamento.length==0) {
                    this.tipiPagamento = await callService("api/tipopagamento", param);
                }
                if(this.listaAnni.length==0) {
                    this.listaAnni = await callService("api/annoContabile/lista", param);
                }                
                await this.internalCreated(Snack.nothing);
                this.loading = false;
            },
            chiudi() {
                if(!this.checkSave) {
                    this.dialog=false;     
                }
                else {
                    bus.$emit('msg-open', { message: "Sono state rilevate delle modifiche.", item: this.cliente, okTxt:"Rimani sulla scheda cliente",cancelTxt:"Chiudi senza salvare"});                
                    bus.$on('msg-cancel', this.esci); 
                    bus.$on('msg-ok', this.rimani);
                }
            },            
            async rimani(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
            },
            async esci(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
                this.dialog=false; 
            },
            async salvaNoteAttivita() {
                let listaAttivita = JSON.parse(JSON.stringify(this.listaAttivita));

                for (let i = 0; i < listaAttivita.length; i++) {
                    var attivita = listaAttivita[i];
                    attivita.dataScadenza = this.serverData(attivita.dataScadenza);
                    attivita.dataChiusura = this.serverData(attivita.dataChiusura);
                }

                let paramJson = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.utente.username,
                        token: this.$store.state.utente.token,
                        sessionID: this.$store.state.sessionID,
                        cliente: this.cliente,
                        listaAttivita: listaAttivita,
                        all: this.viewAll
                    };                    
                    this.loadingSalva = true;

                    let cli = await callPost('api/cliente/salvaNoteAttivita', paramJson);

                    await this.internalCreated(cli ? Snack.success : Snack.error);

                    this.loadingSalva = false;
            },
            aggiungiAllegato(item) {
                let fileDocumento = document.getElementById('idFileDocumentoAllegato')
                this.addebito = item;
                fileDocumento.click();
            },
            async uploadAllegato() {
                this.loading = true;
                const formData = new FormData();
                formData.append('partition', this.$store.state.partition);
                formData.append('username', this.$store.state.utente.username);
                formData.append('token', this.$store.state.utente.token);
                formData.append('sessionID', this.$store.state.sessionID);
                formData.append('idCliente', this.cliente.id);
                formData.append('idAddebito', this.addebito.id);
                formData.append('fileDocumento', this.fileDocumento);
                const esito = await callPostMultipart('api/addebito/upload', formData);
                this.loading = false;

                if (esito) {
                    this.fileDocumento = null;
                    this.addebito.allegato=esito.allegato;
                    this.snackSuccess = true;
                }
                else this.snackError = true;                                          
            },
            async viewDettagli(item) {
                let param = [item.id];
                let lista = await callService('api/addebito/addebitiProgress', param);

                if (this.isStudioPro)
                {
                bus.$emit('msg-list-open',
                    {
                        list: lista.map(ap =>({ ...ap, 
                                 desc: this.descrizioneProfessionista(ap.professionista.id) + ': ' + ap.tipoServizio.descrizione,
                                 subDesc: ap.descrizione,
                                 value: this.importoEuro(ap.importo)
                                 })), title: "Dettaglio Addebiti", excel: false });
                }
                else
                {
                bus.$emit('msg-list-open',
                    {
                        list: lista.map(ap =>({ ...ap, 
                                 desc: ap.tipoServizio.descrizione,
                                 subDesc: ap.descrizione,
                                 value: this.importoEuro(ap.importo)
                                 })), title: "Dettaglio Addebiti", excel: false });
                }

            }
        },
        watch:{
            cliente: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            scheda: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            }
        },
        computed: {
            chartData() {               
                if (this.scheda==null) return null;
                return this.scheda.datiGrafico;
            },
            disableSave() {
                return !this.$store.state.annoAperto || this.idAnno != this.$store.state.idAnnoContabile;
            },
            importoPagamentoEuro() {
                return euro(this.importoPagamento);
            },
            descrizionePagamento(){
                let p = this.tipiPagamento.find(x => x.id === this.idTipoPagamento);
                if(p) return p.descrizione;
                else return "";
            },
            titoloPag() {
                return this.abbuono ? 'Abbuono/Storno' : 'Pagamento';
            },
            isLavoro() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isLavoro;
            },
            isContabilita() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isContabilita;
            },
            isPulizia() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
            },
            isManutenzione() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
            },
            isChiamata() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
            },
            isServicePro() {
                return this.$store.state.conf.isService;
            },
            isStudioPro() {
                return !this.$store.state.conf.isService;
            }            
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;                 
            this.$updaterHub.$on('cliente-changed', this.onClienteChanged)
            bus.$on('cliente-changed', this.onClienteChanged)
            bus.$on('dialog-show-scheda', this.viewScheda);
        },
        beforeDestroy() {
            this.$updaterHub.$off('utente-changed', this.onUtenteChanged);
            bus.$off('utente-changed', this.onUtenteChanged);
            bus.$off('dialog-show-scheda', this.viewScheda);
            this.filePdf = '';
        }
    }</script>
