<template>
    <v-card class="ma-2" :height="hCard" :loading="loading" color="blue-grey lighten-2" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-chart-timeline-variant
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Distribuzione Pagamenti
            </span>
        </v-card-title>
        <v-card-text>

            <GChart v-if="datiPagamenti && datiPagamenti.length>1" type="ColumnChart"
                    :data="chartPagamenti"
                    :options="chartOptionsPagamenti"
                    :settings="{language: 'it'}" />
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>

<script>
    import { callService } from '@/modules/utilities.js'

    import { GChart } from 'vue-google-charts/legacy'

    export default {
        components: { GChart },
        data: () => ({
            loading: false,
            hCard: 450,
            datiPagamenti: null,
            chartOptionsPagamenti: {
                height: 400,
                chartArea: {width: '80%', height: '65%'},
                legend: { position: 'left' },
                backgroundColor: { fill: 'transparent' },
                vAxis: { format: '€ #' }
            },
        }),
        methods: {            
            
        },
        computed: {
            chartPagamenti() {          
                if (this.datiPagamenti == null || this.datiPagamenti.length==0) return null;
                return this.datiPagamenti;
            }
        },
        async created() {
            this.loading = true;
            let param = [this.$store.state.idAnnoContabile];
            this.datiPagamenti = await callService("api/dashboard/distribuzionePagamenti", param);
            this.loading = false;
        }
    }</script>

