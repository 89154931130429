<template>
    <v-form ref="form"
            v-model="valid"
            class="mt-4 ml-4 mr-4"
            lazy-validation>

        <v-card class="ma-2" :loading="loading">

            <template>
                <v-tabs>
                    <v-tab>Report Excel</v-tab>
                    <v-tab-item>

                        <template>

                            <v-row>
                                <v-col>
                                    <v-card :loading="loadingRE1"
                                            class="mx-auto my-12"
                                            color="#fffcda"
                                            max-width="400">
                                        <template slot="progress">
                                            <v-progress-linear color="green"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi potenziali</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Basato sulle tariffe impostate
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="reportE1">
                                                Genera file Excel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>

                                <v-col>
                                    <v-card :loading="loadingRE2"
                                            class="mx-auto my-12"
                                            color="#fffcda"
                                            max-width="400">
                                        <template slot="progress">
                                            <v-progress-linear color="green"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi potenziali per cliente</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Basato sulle tariffe impostate
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="reportE2">
                                                Genera file Excel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>

                                <v-col>
                                    <v-card :loading="loadingRE3"
                                            class="mx-auto my-12"
                                            color="#fffcda"
                                            max-width="400">
                                        <template slot="progress">
                                            <v-progress-linear color="green"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi effettivi per cliente</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Incassato effettivo ripartito tra servizi
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="viewIC">
                                                Genera file Excel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>

                    </v-tab-item>

                    <v-tab>Report PDF</v-tab>
                    <v-tab-item>
                        <template>
                            <v-row>
                                <v-col>
                                    <v-card :loading="loadingRP1"
                                            class="mx-auto my-12"
                                            color="#daf5ea"
                                            max-width="400"
                                            disabled>
                                        <template slot="progress">
                                            <v-progress-linear color="red"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi potenziali</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Basato sulle tariffe impostate
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="reportP1">
                                                Genera file PDF
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                    </v-tab-item>

                    <v-spacer></v-spacer>

                </v-tabs>
            </template>

            <v-dialog v-model="dialogIC" persistent
                      max-width="800">
                <v-stepper v-model="ic">
                    <v-stepper-header>
                        <v-stepper-step :complete="ic > 1"
                                        step="1">
                            Scelta parametri
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="ic > 2"
                                        step="2">
                            Riepilogo
                        </v-stepper-step>


                        <v-divider></v-divider>

                        <v-stepper-step step="3">
                            Esito
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>

                        <v-stepper-content step="1">
                            <v-container class="mb-12">
                                <v-form ref="formIC"
                                        v-model="validIC">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select v-model="idProf"
                                                      :items="listaOperatoriProfessionisti"
                                                      item-value="professionista.id"
                                                      item-text="denominazioneNormalizzata"
                                                      :disabled="isAccessoLimitato"
                                                      label="Professionista di riferimento"
                                                      :rules="[v=> !!v || 'indicare il professionista']"
                                                      outlined
                                                      dense></v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select v-model="idPeriodo"
                                                      :items="periodi"
                                                      item-value="id"
                                                      item-text="descrizione"
                                                      label="Mensilità"
                                                      outlined
                                                      :rules="[v=> !!v || 'selezionare mensilità']"
                                                      dense></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="8" offset="2">
                                            <v-radio-group :rules="[v=> !!v || 'indicare il tipo di esportazione']" v-model="tipoCopia"
                                                           column>
                                                <v-radio value=1
                                                         color="info"
                                                         label="Esportare solo la ripartizione dei pagamenti">
                                                </v-radio>
                                                <v-radio value=0
                                                         color="info"
                                                         label="Esportare tutti i movimenti contabili e la ripartizione dei pagamenti">
                                                </v-radio>

                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-container>
                            <v-row>
                                <v-col cols="2">
                                    <v-btn :disabled="!validIC" :loading="loadingIC" color="primary" depressed
                                           @click="ic=2">
                                        Avanti
                                    </v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                    <v-btn @click="dialogIC = false" color="warning">
                                        Annulla
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-container class="mb-12">
                                <v-row>
                                    <v-col>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-card-account-details-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6 blue--text">Ripartizione incassi relativi ai clienti di {{descrizioneProfessionista}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-calendar-month-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6 red--text">Analisi relativa al mese di {{descrizioneMese}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash-clock</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6">{{descrizioneTipoCopia}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-row>
                                <v-col cols="2">
                                    <v-btn color="primary"
                                           @click="ic = 1">
                                        Indietro
                                    </v-btn>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn color="success" depressed
                                           @click="sendIC">
                                        Invia
                                        <v-icon right>
                                            mdi-send
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                    <v-btn @click="dialogIC = false" color="warning">
                                        Annulla
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-container class="mb-12" align="center">
                                <v-col cols="6" offset="3">
                                    <v-card v-if="loadingIC" color="primary"
                                            dark>
                                        <v-card-text>
                                            Esecuzione Operazione...
                                            <v-progress-linear indeterminate
                                                               color="white"
                                                               class="mb-0">
                                            </v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                    <v-card v-if="!loadingIC && esitoICOK" color="success"
                                            dark>
                                        <v-card-text>
                                            <v-icon dark>
                                                mdi-checkbox-marked-circle
                                            </v-icon>
                                            Operazione eseguita correttamente
                                        </v-card-text>
                                    </v-card>
                                    <v-card v-if="!loadingIC && !esitoICOK" color="error"
                                            dark>
                                        <v-card-text>
                                            <v-icon dark>
                                                mdi-alert-circle
                                            </v-icon>
                                            Errore durante l'esecuzione dell'operazione
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                            </v-container>

                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                    <v-btn @click="dialogIC = false"
                                           color="warning"
                                           :disabled="loadingIC">
                                        Chiudi
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-dialog>


            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Modifiche annullate.
            </v-snackbar>
        </v-card>
    </v-form>
</template>



<script>

    import { callService, callPost, callPostMultipart, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'

    export default {
        data: () => ({
            valid: true,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            loading: false,
            loadingRE1: false,
            loadingRE2: false,
            loadingRE3: false,
            loadingRP1: false,
            fileExcel: '',
            isSelecting: false,
            idProfessionista: '',
            isAccessoLimitato: false,
            idProf: '',
            ic: 1,
            dialogIC: false,
            validIC: false,
            loadingIC: false,
            esitoICOK: false,
            esitoICKO: false,
            tipoCopia: '',
            periodi: [],
            idPeriodo: '',
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },                                 
            chartOptions: {
                height: 120,
                legend: { position: 'none' },
                backgroundColor: { fill:'transparent' },
                vAxis: { format: '€ #' }
            },            
        }),
        methods: {
            async internalCreated(snack) {
                let param = [];
                let configurazione = await callService("api/configurazione/valoriconf", param);
                this.configurazione = configurazione;
                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
            },
            async reportE1() {
                this.loadingRE1 = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idProfessionista: this.idProfessionista,
                };
                let outputPdf = await callPost('api/excel/fatturatoatteso', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                    window.open(this.fileExcel);
                    this.fileExcel = '';
                }
                else this.snackError = true;
                this.loadingRE1 = false;
            },
            async reportE2() {
                this.loadingRE2 = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idProfessionista: this.idProfessionista,
                };
                let outputPdf = await callPost('api/excel/fatturatoattesoclienti', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                    window.open(this.fileExcel);
                    this.fileExcel = '';
                }
                else this.snackError = true;
                this.loadingRE2 = false;
            },
            async viewIC() {
                this.ic = 1;
                let param = [];
                this.periodi = await callService('api/excel/listamesi', param);
                this.loadingIC = false;
                this.dialogIC = true;
                this.$nextTick(() => {
                    this.$refs.formIC.resetValidation();
                });
            },
            async sendIC() {
                this.loadingIC = true;
                this.ic = 3;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idProfessionista: this.idProf,
                    tipoReport: this.tipoCopia,
                    periodo: this.idPeriodo,
                };
                let outputPdf = await callPost('api/excel/ripartizionepagamenti', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                    window.open(this.fileExcel);
                    this.fileExcel = '';
                }
                //else this.snackError = true;
                this.esitoICKO = !outputPdf;
                this.esitoICOK = outputPdf;
                this.loadingIC = false;
            },
            async reportP1() {
                this.loadingRP1 = true;
                setTimeout(() => (this.loadingRP1 = false), 2000);
            },
            scaricaExcel() {
                //alert(this.filePdf);
                window.open(this.fileExcel);
                this.fileExcel = '';
            },
            importoEuro(v) {
                return euro(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
        },
        computed: {
            importoPagamentoEuro() {
                return euro(this.importoPagamento);
            },
            descrizioneProfessionista() {
                let p = this.listaOperatoriProfessionisti.find(x => x.professionista.id === this.idProf);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            descrizioneTipoCopia() {
                if (this.tipoCopia == 1) return "Estrarre i soli incassi ripartiti";
                else return "Estrarre tutti i movimenti contabili e la ripartizione degli incassi";
            },
            descrizioneMese() {
                let p = this.periodi.find(x => x.id === this.idPeriodo);
                if (p) return p.descrizione;
                else return "";
            },
        },
        async created() {

            if (this.$store.state.utente.operatore.isProfessionista && this.$store.state.utente.isGestoreStudio) {
                this.idProfessionista = "all";
            }
            else if (this.$store.state.utente.operatore.isProfessionista) {
                this.idProfessionista = this.$store.state.utente.operatore.professionista.id;
            }
            else {
                this.idProfessionista = "all";
            }
            this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;

            if (this.$store.state.utente.operatore.isProfessionista) {
                this.idProf = this.$store.state.utente.operatore.professionista.id;
                if (this.$store.state.utente.operatore.isAccessoLimitato) {
                    this.isAccessoLimitato = true;
                }
            }
        }
    }
</script>
