<template>
    <v-dialog v-model="dialog" persistent max-width="1500px">

        <v-card :loading="loading">
            <v-card-text>
                <v-form ref="form"
                        v-model="valid"
                        class="mt-4 ml-4 mr-4"
                        lazy-validation>
                    <v-tabs>
                        <v-tab>Gestione Vulnerabilità dello Studio</v-tab>
                        <v-tab-item>
                            <v-row class="mt-n8">
                                <v-col cols="3">
                                    <v-card class="mx-auto my-12"
                                            max-width="300">

                                        <v-card-text style="text-align:center; font-weight:bold">Formazione</v-card-text>

                                        <v-card-text>
                                            <div>{{descrizionevulnerabilita("B1")}}</div>
                                        </v-card-text>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                        <v-card-text>
                                            <v-chip-group v-model="selectionB1"
                                                          active-class="deep-purple accent-4 white--text"
                                                          column
                                                          class="mt-n6">
                                                <v-chip>1</v-chip>

                                                <v-chip>2</v-chip>

                                                <v-chip>3</v-chip>

                                                <v-chip>4</v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                    </v-card>

                                    <!--<v-checkbox class="mt-n4"
                                                v-model="vulnerabilita.isCorrente"
                                                color="green"
                                                :label="descrizioneRisposta(vulnerabilita.isCorrente)">
                                    </v-checkbox>-->

                                </v-col>
                                <v-col cols="3">
                                    <v-card class="mx-auto my-12"
                                            max-width="300">

                                        <v-card-text style="text-align:center; font-weight:bold">Organizzazione degli adempimenti di adeguata verifica della clientela</v-card-text>

                                        <v-card-text>
                                            <div>{{descrizionevulnerabilita("B2")}}</div>
                                        </v-card-text>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                        <v-card-text>
                                            <v-chip-group v-model="selectionB2"
                                                          active-class="deep-purple accent-4 white--text"
                                                          column
                                                          class="mt-n6">
                                                <v-chip>1</v-chip>

                                                <v-chip>2</v-chip>

                                                <v-chip>3</v-chip>

                                                <v-chip>4</v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                    </v-card>

                                </v-col>
                                <v-col cols="3">
                                    <v-card class="mx-auto my-12"
                                            max-width="300">

                                        <v-card-text style="text-align:center; font-weight:bold">Organizzazione degli adempimenti relativi alla conservazione dei documenti, dati e informazioni</v-card-text>

                                        <v-card-text>
                                            <div>{{descrizionevulnerabilita("B3")}}</div>
                                        </v-card-text>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                        <v-card-text>
                                            <v-chip-group v-model="selectionB3"
                                                          active-class="deep-purple accent-4 white--text"
                                                          column
                                                          class="mt-n6">
                                                <v-chip>1</v-chip>

                                                <v-chip>2</v-chip>

                                                <v-chip>3</v-chip>

                                                <v-chip>4</v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="3">
                                    <v-card class="mx-auto my-12"
                                            max-width="300">

                                        <v-card-text style="text-align:center; font-weight:bold">Organizzazione in materia di segnalazione di operazioni sospette e comunicazione delle violazioni alle norme sull’uso del contante</v-card-text>

                                        <v-card-text>
                                            <div>{{descrizionevulnerabilita("B4")}}</div>
                                        </v-card-text>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                        <v-card-text>
                                            <v-chip-group v-model="selectionB4"
                                                          active-class="deep-purple accent-4 white--text"
                                                          column
                                                          class="mt-n6">
                                                <v-chip>1</v-chip>

                                                <v-chip>2</v-chip>

                                                <v-chip>3</v-chip>

                                                <v-chip>4</v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </v-tab-item>


                        <v-spacer></v-spacer>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="valid"
                               @click="salva"
                               :loading="loadingSalva"
                               :disabled="!valid">
                            Salva
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="orange darken-2" dark
                               :loading="loadingAnnulla"
                               @click="annulla">
                            Annulla modifiche
                        </v-btn>
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="dialog = false">
                            Chiudi
                        </v-btn>
                    </v-tabs>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import { callService, callPost, Snack, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'


    export default {
        data: () => ({
            dialog: false,
            valid: true,
            nuovo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogDelete: false,
            loading: false,
            loadingSalva: false,
            loadingAnnulla: false,
            listaTVV: [],
            idvulnerabilita: '',
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            }, 
            vulnerabilita: {
                id:''
            },
            selectionB1: 0,
            selectionB2: 0,
            selectionB3: 0,
            selectionB4: 0
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" });
            }            
        },
        computed: {

        },
        methods: {
            logout() {
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" });
            },
            checkData(d) {
                return gCheckData(d);
            },
            localData(v) {
                return gLocalData(v);
            },
            serverData(stringDate) {
                return gServerData(stringDate);
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            descrizionevulnerabilita(codice) {
                let p = this.listaTVV.find(x => x.codice === codice);
                if (p) return p.criteriValutazione;
                else return "";
            },
            descrizioneRisposta(v) {
                if (v == false) return "Valutazione storica o non usata";
                return "Valutazione valida in questo momento";
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);    
                this.loadingAnnulla = false;
            },            
            async salva() {

                if (!this.$refs.form.validate()) return;

                this.vulnerabilita.valutazioneB1 = this.selectionB1 + 1;
                this.vulnerabilita.valutazioneB2 = this.selectionB2 + 1;
                this.vulnerabilita.valutazioneB3 = this.selectionB3 + 1;
                this.vulnerabilita.valutazioneB4 = this.selectionB4 + 1;

                //alert(JSON.stringify(this.vulnerabilita));

                let vulnerabilita = JSON.parse(JSON.stringify(this.vulnerabilita));
                vulnerabilita.data = this.serverData(vulnerabilita.data);

                //alert(JSON.stringify(vulnerabilita));

                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    valutazioneVulnerabilita: vulnerabilita
                };

                this.loadingSalva = true;
                let sca = await callPost('api/antiriciclaggio/saveVV', paramJson);


                if (sca) {
                    this.vulnerabilita.id = sca.id;
                    this.nuovo = false;
                    bus.$emit('vulnerabilita-snack-succes',{});
                    bus.$emit('fascicoli-changed'); 
                    this.loadingSalva = false;  
                    this.dialog = false;
                }
                else {
                    await this.internalCreated(Snack.error);
                    this.loadingSalva = false;   
                }

            },
            async internalCreated(snack) {

                let param = [];
                this.listaTVV = this.$store.state.listaTVV;

                param = [this.vulnerabilita.id];
                let vulnerabilita = await callService("api/antiriciclaggio/vulnerabilita", param);
                if (this.nuovo) {
                    vulnerabilita.data = Date.now();
                }   

                vulnerabilita.data = this.localData(vulnerabilita.data);

                this.vulnerabilita = vulnerabilita;

                this.selectionB1 = this.vulnerabilita.valutazioneB1-1;
                this.selectionB2 = this.vulnerabilita.valutazioneB2-1;
                this.selectionB3 = this.vulnerabilita.valutazioneB3-1;
                this.selectionB4 = this.vulnerabilita.valutazioneB4-1;

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            }    
        },
        async created() {           

            var vm = this;

            bus.$on('dialog-show-vulnerabilita', async function (vulnerabilita) {
                vm.dialog = true;
                vm.loading = true;
                vm.nuovo = vulnerabilita.id == "0";
                vm.vulnerabilita.id = vulnerabilita.id;
                await vm.internalCreated(Snack.nothing);
                vm.loading = false;
            });   
        },
        beforeDestroy() {
            bus.$off('dialog-show-vulnerabilita');
        }
    }

</script>

