<template>
    <v-dialog v-model="dialog" persistent max-width="1500px">

        <v-card :loading="loading">
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab v-if="viewTab">
                        <v-badge dot v-if="!valid1" color="red">Anagrafica</v-badge><span v-else>Anagrafica</span>
                    </v-tab>
                    <v-tab v-if="viewTab && (!nuovo) && fascicolo.isTE">
                        <v-badge dot v-if="!valid2" color="red">Titolare Effettivo</v-badge><span v-else>Titolare Effettivo</span>
                    </v-tab>
                    <v-tab v-if="viewTab && (!nuovo)">
                        <v-badge dot v-if="!valid7" color="red">Documenti</v-badge><span v-else>Documenti</span>
                    </v-tab>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-2 mr-2" color="teal"
                           :dark="valid1 && valid2"
                           @click="salva"
                           :loading="loadingSalva"
                           :disabled="!valid1 || !valid2 || !valid7">
                        Salva
                    </v-btn>
                    <v-btn class="mt-2 mr-2"
                           color="orange darken-2"
                           :dark="$store.state.annoAperto"
                           :disabled="!$store.state.annoAperto"
                           :loading="loadingAnnulla"
                           @click="annulla">
                        Annulla modifiche
                    </v-btn>
                    <v-btn class="mt-2 mr-2"
                           color="blue darken-2" dark
                           @click.stop="chiudi">
                        Esci
                    </v-btn>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-form ref="form1"
                            v-model="valid1"
                            lazy-validation>
                        <v-tab-item v-if="viewTab">
                            <div>
                                <v-row class="mt-4">
                                    <v-col cols="3">
                                        <v-text-field v-model="fascicolo.cliente.tipoCliente.descrizione"
                                                      label="Tipo Cliente"
                                                      required
                                                      outlined
                                                      disabled
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.cliente.codice"
                                                      label="Codice"
                                                      required
                                                      disabled
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="4">
                                        <v-text-field v-model="fascicolo.cliente.denominazioneNormalizzata"
                                                      label="Denominazione"
                                                      required
                                                      disabled
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.cliente.professionista.denominazione"
                                                      label="Professionista responsabile"
                                                      required
                                                      disabled
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-n4">
                                    <v-col cols="3">
                                        <v-select v-model="fascicolo.tipoDocumentoIdentificativoCliente"
                                                  :items="listaTipiDocumentoIndentificativo"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipo documento identificativo Rapp.Legale"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.numeroDocumentoIdentificativo"
                                                      label="N.ro documento Rapp.Legale"
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="fascicolo.emittenteDocumentoIdentificativo"
                                                      label="Rilasciato da"
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.dataRilascioDocumentoIdentificativo"
                                                      label="Data rilascio documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.dataScadenzaDocumentoIdentificativo"
                                                      label="Data scadenza documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense class="align-content-center">
                                    <v-col>
                                        <v-chip class="ma-2"
                                                close
                                                :color="coloraRischio(valoreVRR, false)"
                                                :text-color="coloraRischio(valoreVRR, true)"
                                                close-icon="mdi-calculator"
                                                @click:close="gestisciRischioResiduo">
                                            <v-avatar left>
                                                <template>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on">
                                                                mdi-help-circle-outline
                                                            </v-icon>
                                                        </template>
                                                        La valutazione viene effettuata in relazione all’attività professionale svolta in favore del cliente, individuata e classificata per categorie omogenee. L`operazione determina anche il valore del Rischio Inerente.
                                                    </v-tooltip>
                                                </template>
                                            </v-avatar>
                                            Rischio Residuo: {{valoreVRR}}
                                        </v-chip>
                                    </v-col>
                                    <v-col>
                                        <v-chip class="ma-2"
                                                :color="coloraRischio(fascicolo.valutazioneRischioInerente, false)"
                                                :text-color="coloraRischio(fascicolo.valutazioneRischioInerente, true)"
                                                label>
                                            <v-avatar left>
                                                <template>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on">
                                                                mdi-help-circle-outline
                                                            </v-icon>
                                                        </template>
                                                        Il Rischio Inerente viene calcolato dalla funzione Rischio Residuo (a sinistra)
                                                    </v-tooltip>
                                                </template>
                                            </v-avatar>
                                            Rischio Inerente: {{fascicolo.valutazioneRischioInerente}}
                                        </v-chip>
                                    </v-col>

                                    <v-col>
                                        <v-chip class="ma-2"
                                                close
                                                :color="coloraRischio(fascicolo.valutazioneRischioSpecifico, false)"
                                                :text-color="coloraRischio(fascicolo.valutazioneRischioSpecifico, true)"
                                                close-icon="mdi-calculator"
                                                @click:close="gestisciRischioSpecifico">
                                            <v-avatar left>
                                                <template>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on">
                                                                mdi-help-circle-outline
                                                            </v-icon>
                                                        </template>
                                                        Per la valutazione del rischio specifico la piattaforma permetterà la valutazione del rischio relativo agli aspetti connessi al cliente e agli aspetti connessi alla prestazione professionale.
                                                    </v-tooltip>
                                                </template>
                                            </v-avatar>
                                            Rischio Specifico: {{fascicolo.valutazioneRischioSpecifico}}
                                        </v-chip>
                                    </v-col>

                                    <v-col>
                                        <v-chip class="ma-2"
                                                :color="coloraRischio(fascicolo.valutazioneRischioEffettivo, false)"
                                                :text-color="coloraRischio(fascicolo.valutazioneRischioEffettivo, true)"
                                                label>
                                            <v-avatar left>
                                                <template>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on">
                                                                mdi-help-circle-outline
                                                            </v-icon>
                                                        </template>
                                                        Per la valutazione del rischio effettivo la piattaforma effettuerà la media ponderata tra rischio inerente (30%) e rischio specifico (70%)
                                                    </v-tooltip>
                                                </template>
                                            </v-avatar>
                                            Rischio Effettivo: {{fascicolo.valutazioneRischioEffettivo}}
                                        </v-chip>
                                    </v-col>

                                    <v-col>
                                        <v-select v-model="fascicolo.tav"
                                                  :items="listaTipiAdeguataVerifica"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipologia Adeguata Verifica"
                                                  :rules="[v=> !!v || 'indicare il tipo di Adeguata Verifica']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>


                                </v-row>

                                <v-row>


                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.data"
                                                      label="Data creazione fascicolo"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      disabled
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.dataScadenza"
                                                      label="Data scadenza fascicolo"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      disabled
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-checkbox class="mt-0"
                                                    v-model="fascicolo.isTEPEP"
                                                    label="Il titolare effettivo è Persona Politicamente Esposta?">
                                        </v-checkbox>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-text-field v-model="fascicolo.tEPEPFunzione"
                                                      label="Funzione PEP"
                                                      outlined
                                                      :disabled="!fascicolo.isTEPEP"
                                                      :rules="[v=>  !fascicolo.isTEPEP || (!!v && v.length>0) || 'inserire la funzione']"
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-checkbox class="mt-0"
                                                    v-model="fascicolo.isTE"
                                                    label="Il titolare effettivo differisce dal rappresentante legale?">
                                        </v-checkbox>
                                    </v-col>

                                </v-row>

                                <v-row dense class="mt-n6">
                                    <v-layout child-flex>
                                        <v-data-table no-data-text="Non sono presenti annotazioni"
                                                      :headers="headersAnnotazione"
                                                      :items="annotazioniFascicolo"
                                                      :items-per-page="3"
                                                      dense
                                                      class="elevation-1 ma-4"
                                                      :footer-props="{
                                            'items-per-page-text':'',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[3]
                                          }">
                                            <template slot="header.elimina">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" color="info" class="ml-2"
                                                               v-on="on"
                                                               icon @click="newAnnotazione">
                                                            <v-icon>mdi-folder-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        Aggiungi Annotazione
                                                    </span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:item.data="{ item }">
                                                <span class="font-weight-light" v-text="localData(item.data)" />
                                            </template>
                                            <template v-slot:item.annotazione="{ item }">
                                                <v-textarea v-model="item.annotazione"
                                                            outlined
                                                            :rules="[v=> !!v || 'inserire annotazione']"
                                                            rows="1"
                                                            auto-grow hide-details
                                                            dense />
                                            </template>
                                            <template v-slot:item.elimina="{ item }">
                                                <v-btn icon @click="eliminaAnnotazione(item)" color="error darken-2" class="ml-2">
                                                    <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-layout>
                                </v-row>

                            </div>

                        </v-tab-item>
                    </v-form>
                    <v-form ref="form2"
                            v-model="valid2"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && (!nuovo) && fascicolo.isTE">
                            <div>
                                <v-row class="mt-4">
                                    <v-col cols="3">
                                        <v-text-field v-model="fascicolo.tECodiceFiscale"
                                                      label="Codice Fiscale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercaseCFLR"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v)) || 'inserire 16 caratteri', checkCF || 'codice fiscale non valido']">
                                            <v-tooltip slot="append" bottom v-if="fascicolo.tECodiceFiscale && fascicolo.tECodiceFiscale.length==16">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="primary" dark @click="valoridacf">
                                                        mdi-database-search
                                                    </v-icon>
                                                </template>
                                                <span>Estrai dati dal Codice Fiscale</span>
                                            </v-tooltip>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="fascicolo.tECognome"
                                                      label="Cognome"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire il cognome']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="fascicolo.tENome"
                                                      label="Nome"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire il nome']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-combobox v-model="fascicolo.tECittadinanza"
                                                    :items="listaStati"
                                                    item-value="denominazione"
                                                    item-text="denominazione"
                                                    label="Cittadinanza"
                                                    outlined
                                                    clearable
                                                    ref="v-combobox-stati"
                                                    :return-object="false"
                                                    dense></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.tEdataNascita"
                                                      label="Data di nascita"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-combobox v-model="fascicolo.tEluogoNascita"
                                                    :items="listaComuni"
                                                    item-value="comune"
                                                    item-text="comune"
                                                    label="Luogo di nascita"
                                                    outlined
                                                    clearable
                                                    v-on:change="setComune"
                                                    ref="v-combobox-comuni"
                                                    :return-object="false"
                                                    dense></v-combobox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.tEprovinciaNascita"
                                                      label="Prv.Nascita"
                                                      outlined
                                                      dense
                                                      @keyup="uppercasePRV"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{2}$/.test(v)) || 'inserire 2 caratteri']">
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="3">
                                        <v-combobox v-model="fascicolo.tEResidenzaComune"
                                                    :items="listaComuni"
                                                    item-value="comune"
                                                    item-text="comune"
                                                    label="Comune di residenza"
                                                    outlined
                                                    clearable
                                                    v-on:change="setComuneRes"
                                                    ref="v-combobox-comuni-res"
                                                    :return-object="false"
                                                    dense></v-combobox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.tEResidenzaProvincia"
                                                      label="Prv.Residenza"
                                                      outlined
                                                      dense
                                                      @keyup="uppercasePRVR"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{2}$/.test(v)) || 'inserire 2 caratteri']">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.tEResidenzaCap"
                                                      label="CAP Residenza"
                                                      outlined
                                                      dense
                                                      :rules="[v=> v.length>0 || 'inserire un CAP']">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="5">
                                        <v-text-field v-model="fascicolo.tEResidenzaIndirizzo"
                                                      label="Indirizzo Residenza"
                                                      outlined
                                                      dense
                                                      :rules="[v=> v.length>0 || 'inserire un indirizzo']">
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="3">
                                        <v-select v-model="fascicolo.tdi"
                                                  :items="listaTipiDocumentoIndentificativo"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipo documento identificativo"
                                                  :rules="[v=> !!v || 'indicare il tipo di documento']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.tENumeroDocumentoIdentificativo"
                                                      label="Numero documento"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire il numero']"
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-text-field v-model="fascicolo.tEEmittenteDocumentoIdentificativo"
                                                      label="Rilasciato da"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire l`Ente (comune, questura, ecc..)']"
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.tEDataRilascioDocumentoIdentificativo"
                                                      label="Data rilascio documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field v-model="fascicolo.tEDataScadenzaDocumentoIdentificativo"
                                                      label="Data scadenza documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                </v-row>

                            </div>
                        </v-tab-item>
                    </v-form>
                    <v-form ref="form7"
                            v-model="valid7"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && (!nuovo)">
                            <br />
                            <v-row>
                                <v-col cols="9">
                                    <v-data-table class="elevation-1 ma-2"
                                                  v-model="selectedItem"
                                                  single-select
                                                  item-key="id"
                                                  :loading="loadingDoc"
                                                  :headers="headersAllegati"
                                                  :items="allegatiFascicolo"
                                                  :footer-props="{
                                                        'items-per-page-text':'Pagina da',
                                                        'items-per-page-all-text': '',
                                                        'items-per-page-options':[8]
                                                      }"
                                                  @click:row="selezionaRiga"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun allegato presente">
                                        <template slot="body.append">
                                            <tr>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title">
                                                    <v-icon class="mr-2"
                                                            @click="newAllegato"
                                                            color="success darken-2">
                                                        mdi-file-document-plus-outline
                                                    </v-icon>
                                                </th>
                                            </tr>
                                        </template>
                                        <template v-slot:item.dataCaricamento="{ item }">
                                            <span v-text="localData(item.data)" />
                                        </template>
                                        <template v-slot:item.note="{ item }">
                                            <v-tooltip slot="append" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-on="on" v-model="item.note"
                                                                  class="mb-n6"
                                                                  outlined
                                                                  dense />
                                                </template>
                                                <span>{{item.allegatoName}}</span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.tipoDocumento="{ item }">
                                            <v-select v-model="item.tfa"
                                                      :items="listaTipiFileAntiriciclaggio"
                                                      hide-details
                                                      item-value="id"
                                                      clearable
                                                      :bg-color="coloratfa(item.tfa)"
                                                      item-text="descrizione"
                                                      outlined
                                                      dense>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-2 font-weight-medium '+(data.item.ordinamento == '0' ? 'red--text':'')">{{data.item.descrizione}}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-select>
                                        </template>
                                        <template v-slot:item.apri="{ item }">
                                            <v-icon @click="viewAllegato(item)" color="info darken-2">mdi-magnify</v-icon>
                                        </template>
                                        <template v-slot:item.scarica="{ item }">
                                            <v-icon @click="downloadAllegato(item)" color="info darken-2">mdi-download</v-icon>
                                        </template>
                                        <template v-slot:item.azioni="{ item }">
                                            <v-icon @click="eliminaAllegato(item)" color="error darken-2">mdi-trash-can-outline</v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="3">

                                    <v-card v-if="allegatiOpen" :loading="loadingPdf">
                                        <v-carousel v-model="carouselId"
                                                    show-arrows
                                                    @change="changeCarusel">
                                            <v-carousel-item v-for="(item) in allegatiFascicolo"
                                                             :key="item.id"
                                                             target="_blank"
                                                             :href="item.allegatoPath ? item.allegatoPath : 'pdf/blank.pdf'">
                                                <pdf @progress="progress" v-if="item.isPdf" :src="item.allegatoPath"></pdf>
                                                <pdf @progress="progress" v-if="item.isBlank" :src="'pdf/blank.pdf'"></pdf>
                                                <pdf @progress="progress" v-if="item.isNotPreview" :src="'pdf/notpreview.pdf'"></pdf>
                                                <v-img v-if="item.isImage" :src="item.allegatoPath" contain max-height="300"></v-img>
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-form>
                </v-tabs-items>
                <span class="caption ml-4">{{fascicolo.cliente.denominazioneNormalizzata}} [{{fascicolo.cliente.id}}]</span>
            </v-card-text>
        </v-card>

        <v-file-input v-model="fileDocumento"
                      prepend-icon=""
                      id="idFileDocumentoFascicolo"
                      label="File Documento"
                      outlined
                      hide-input
                      type="file"
                      @change="uploadFascicolo"
                      dense>
        </v-file-input>

        <v-snackbar v-model="snackSuccess" centered
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError" centered
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel" centered
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote" centered
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>

        <rischioResiduo-dialog />
        <rischioSpecifico-dialog />

    </v-dialog>

</template>

<script>

    import { callService, callPost, callPostMultipart, euro, gLocalData, gCheckData, gServerData, gDataCalendar,Snack, gControllData } from '@/modules/utilities.js'
    import pdf from 'vue-pdf'
    import { bus } from '@/main.js'
    import RischioResiduoDialog from '@/views/components/RischioResiduoDialog.vue'
    import RischioSpecificoDialog from '@/views/components/RischioSpecificoDialog.vue'

    export default {
        components: {
            pdf,
            RischioResiduoDialog,
            RischioSpecificoDialog
        },
        data: () => ({
            valid1: true,
            valid2: true,
            valid7: true,
            viewTab: true,
            expanded: [],
            rstatus: "0",
            carouselId: null,
            fileDocumento:null,
            allegatiOpen: false,
            selectedItem: [],
            dialog: false,
            tab: 0,
            tabPrec:0,
            loadingSalva: false,
            loadingAnnulla: false,
            loading: false,
            loadingDoc: false,
            loadingPdf: false,
            loading3: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogDelete: false,
            importoEdit: 0,
            id: '',
            on: '',
            attrs: [],
            ri: 1,
            dialogRI: false,
            validRI: false,
            loadingRI: false,
            esitoRIOK: false,
            esitoRIKO: false,
            theme:'',
            nuovo: false,
            fascicolo:{
                id: '',
                cliente: {
                    id: '',
                    tipoCliente: {
                        id: ''
                    },
                    professionista: {
                        id: ''
                    }
                },
                tipoAdeguataVerifica: {
                    id:''
                },
                tipoDocumentoIdentificativo: {
                    id: ''
                },
            },
            utente: {},
            checkSave: false,
            checkCF: true,
            scPEC: true,
            moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
            },
            listaTipiAdeguataVerifica: [],
            listaTipiDocumentoIndentificativo: [],
            listaTipiFileAntiriciclaggio: [],
            listaTipiPrestazioneRischioInerente: [],
            //listaTestiValutazioneRischioInerente: [],
            //listaTestiValutazioneRischioSpecifico: [],

            listaComuni: [],

            listaTags: [],
            listaTagsIni: [],
            labelInput:'',

            //listaOperatore: [],
            //listaOperatoriProfessionisti: [],

            annotazioniFascicolo: [],
            //valutazioniRischioInerente: [],
            valutazioneRischioSpecifico: { id: '' },
            valutazioneRischioResiduo: { id: '' },
            valoreVRR: 0,
            allegatiFascicolo: [],
            valoricf: [],
            headersAllegati: [
                { text: '', value: 'apri', sortable: false },
                { text: '', value: 'scarica', sortable: false },
                { text: 'Data caricamento', value: 'dataCaricamento' },
                { text: 'Tipo documento', value: 'tipoDocumento' },
                { text: 'Note', value: 'note', width: '30%' },
                { text: '', value: 'azioni', sortable: false }
            ],
            headersAnnotazione: [
                { text: 'Data', value: 'data' },
                { text: 'Annotazione', value: 'annotazione', width: '50%' },
                { text: '', value: 'elimina', sortable: false },
            ],
        }),
        //mounted() {
        //    if (!this.$store.state.logged) {
        //        this.$router.replace({ name: "login" }).catch(() => { });
        //    }            
        //},
        methods: {
            disableItem(item) {
                return item.isCancellato;
            },
            progress(val) {
                this.loadingPdf = (val < 1)
            },
            newAllegato(item) {
                let fileDocumento = document.getElementById('idFileDocumentoFascicolo')
                fileDocumento.click();
            },
            newAnnotazione() {
                this.annotazioniFascicolo.push(
                    {
                        id: new Date().getTime().toString(),
                        data: new Date()
                    })
            },
            async eliminaAnnotazione(item) {
                var i = this.annotazioniFascicolo.indexOf(item);
                this.annotazioniFascicolo.splice(i, 1);
            }, 
            controllData(v) {
                return gControllData(v);
            },
            async uploadFascicolo() {
                this.loadingDoc=true;
                const formData = new FormData();
                formData.append('partition', this.$store.state.partition);
                formData.append('username', this.$store.state.utente.username);
                formData.append('token', this.$store.state.utente.token);
                formData.append('idCliente', this.fascicolo.cliente.id);
                formData.append('fileDocumento', this.fileDocumento);
                formData.append('idFascicolo', this.fascicolo.id);
                let checkSave = this.checkSave;
                const esito = await callPostMultipart('api/allegatoCliente/uploadFascicolo', formData);
                this.loadingDoc=false;

                if (esito) this.snackSuccess = true;
                else this.snackError = true;

                if (esito) {
                    let param=[this.fascicolo.id]
                    this.allegatiFascicolo = await callService("api/antiriciclaggio/listaAllegati", param);
                    this.allegatiOpen = this.allegatiFascicolo.length>0;
                }
                this.fileDocumento = null;

                this.$nextTick(() => {
                    this.checkSave=checkSave; //ripristino il valore precedente
                });            },
            viewAllegato(item) {
                window.open(item.allegatoPath);
            },
            downloadAllegato(item) {
                var link = document.createElement('a');
                link.href = item.allegatoPath;
                link.download = item.allegato;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            async eliminaAllegato(item) {
                bus.$emit('msg-open', { message: "Eliminare l'allegato?", item });
                bus.$on('msg-cancel', this.eliminaAllegatoCancel);
                bus.$on('msg-ok', this.eliminaAllegatoOk);
            },
            async eliminaAllegatoCancel(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);
            },
            async eliminaAllegatoOk(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);
                
                let indexAllegato = this.allegatiFascicolo.indexOf(item);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    IDAllegatoFascicolo: item.id
                };

                this.loadingDoc = true;
                let checkSave = this.checkSave;
                const esito = await callPost('api/allegatoCliente/eliminaFascicolo', param);
                this.loadingDoc = false;

                if (esito) {
                    this.allegatiFascicolo.splice(indexAllegato, 1);
                    this.snackSuccess = true;
                }
                else this.snackError = true;

                this.$nextTick(() => {
                    this.checkSave=checkSave; //ripristino il valore precedente
                });

            },   
            selezionaRiga(item) {
                this.selectedItem = [item];
                this.carouselId = this.allegatiFascicolo.indexOf(item);
            },
            changeCarusel(i) {
                this.selectedItem = [this.allegatiFascicolo[i]];
            },
            checkTag() {
                let check=
                   this.labelInput
                && this.labelInput.length>2
                && this.labelInput.indexOf('-')==-1
                && this.labelInput.indexOf(' ')==-1;
                return check;
            },
            removeTag(item) {
                this.listaTags = this.listaTags.filter(c => c != item.id);
            },
            aggiungiTag() {
                if (this.labelInput && this.labelInput.length>2) {
                    this.listaTagsIni.push({ id: this.labelInput });
                    this.listaTags.push(this.labelInput);
                    this.labelInput=""
                }                
            },
            async valoridacf() {
                let cf = this.cliente.codiceFiscaleLR;
                let param = [cf];
                let valoricf = await callService("api/cliente/valoridacf", param);
                if (valoricf.comune) {
                    this.cliente.luogoNascita = valoricf.comune;
                    this.cliente.provinciaNascita = valoricf.provincia;
                    this.cliente.dataNascita = this.localData(valoricf.dataNascita);
                    this.checkCF = true;
                }
                else {
                    this.checkCF = false;
                }
            },
            checkDupCF(cf) {
                //if(!cf) return true;
                //var filtro = this.$store.state.clientiEstesi.find(ce => ce.cliente.codiceFiscale === cf && ce.cliente.id != this.cliente.id);
                //if(!filtro) return true;

                //return false;

                return true;

            },
            checkDupPIVA(piva) {
                //if(!piva) return true;
                //var filtro = this.$store.state.clientiEstesi.find(ce => ce.cliente.partitaIVA === piva && ce.cliente.id != this.cliente.id);
                //if(!filtro) return true;

                //return false;
                return true;
            },
            gestisciRischioResiduo() {
                this.valutazioneRischioResiduo = JSON.parse(JSON.stringify(this.valutazioneRischioResiduo));
                bus.$emit('dialog-show-rr', this.valutazioneRischioResiduo);
            }, 
            gestisciRischioSpecifico() {
                this.valutazioneRischioSpecifico = JSON.parse(JSON.stringify(this.valutazioneRischioSpecifico));
                bus.$emit('dialog-show-rs', this.valutazioneRischioSpecifico);
            }, 
            //logout() {
            //    this.$store.dispatch('logout');
            //    this.$store.dispatch('setUtente', null);
            //    this.$router.replace({ name: "login" }).catch(() => { });
            //},
            //importoEuro(v) {
            //    return euro(v);
            //},
            //saveNumero(item) {
            //    item.importo = Number(this.importoEdit);                
            //}, 

            setComune(comune) {
                let comuneOjb = this.listaComuni.find(x => x.comune === comune);
                if (comuneOjb) {
                    this.fascicolo.tEprovinciaNascita = comuneOjb.siglaProvincia;
                }
            },
            setComuneRes(comune) {
                let comuneOjb = this.listaComuni.find(x => x.comune === comune);
                if (comuneOjb) {
                    this.fascicolo.tEResidenzaProvincia = comuneOjb.siglaProvincia;
                }
            },
            //uppercaseCF() {
            //    this.cliente.codiceFiscale = this.cliente.codiceFiscale.toUpperCase();
            //    this.checkCF=true;
            //},
            uppercaseCFLR() {
                this.fascicolo.tECodiceFiscale = this.fascicolo.tECodiceFiscale.toUpperCase();
                this.checkCF=true;
            },
            //uppercaseSDI() {
            //    this.cliente.sdi = this.cliente.sdi.toUpperCase();
            //},
            //uppercaseIBAN() {
            //    this.cliente.iban = this.cliente.iban.toUpperCase();
            //},
            uppercasePRV() {
                this.fascicolo.tEprovinciaNascita = this.fascicolo.tEprovinciaNascita.toUpperCase();
            },
            uppercasePRVR() {
                this.fascicolo.tEResidenzaProvincia = this.fascicolo.tEResidenzaProvincia.toUpperCase();
            },
            checkData(d) {
                return gCheckData(d);
            },
            localData(v) {
                return gLocalData(v);
            },
            serverData(stringDate) {                             
                return gServerData(stringDate);                
            },
            dataCalendar(stringDate) {                             
                return gDataCalendar(stringDate);                
            },            
            validate() {
                this.$refs.form1.validate()
                this.$refs.form2.validate()
                this.$refs.form7.validate()
            },
            reset() {
                this.$refs.form1.reset()
                this.$refs.form2.reset()
                this.$refs.form7.reset()
            },
            resetValidation() {
                this.$refs.form1.resetValidation()
                this.$refs.form2.resetValidation()
                this.$refs.form7.resetValidation()

            },
            //coloraRiga(item) {
            //    if (!item.abilitato) return "style-lock";
            //    return "";
            //},
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);
                this.loadingAnnulla = false;
            },
            async onFascicoloChanged() {
                //if (sessionID=="-1" || sessionID == this.$store.state.sessionID) return;
                //if(lista.find(cod=>cod==this.fascicolo.id)) {
                //    await this.internalCreated(Snack.remote);
                //}
                //alert("Ecco");
                await this.internalCreated(Snack.success);
            },
            //async sendInvitoApp() {
            //    this.loading3 = true;
            //    //invia la mail al cliente corrente
            //    let param = {
            //        partition: this.$store.state.partition,
            //        username: this.$store.state.utente.username,
            //        token: this.$store.state.utente.token,
            //        idCliente: this.cliente.id
            //    };

            //    const result = await callPost('api/broadcast/inviaInvitoApp', param);

            //    await this.internalCreated(result ? Snack.success : Snack.error);
                
            //    this.loading3 = false;
            //},
            async salva() {
                if (this.$refs["v-combobox-comuni"]) this.$refs["v-combobox-comuni"].blur();
                if (this.$refs["v-combobox-comuni-res"]) this.$refs["v-combobox-comuni-res"].blur();
                if (this.$refs["v-combobox-stati"]) this.$refs["v-combobox-stati"].blur();

                if (!this.viewTab) {
                    await this.internalCreated(Snack.error);
                    return;
                }

                this.$nextTick(async () => {

                    if (!this.$refs.form1.validate()) return;
                    if (!this.$refs.form2.validate()) return;


                    //cambio date//

                    let fascicolo = JSON.parse(JSON.stringify(this.fascicolo));
                    fascicolo.data = this.serverData(fascicolo.data);
                    fascicolo.dataScadenza = this.serverData(fascicolo.dataScadenza);
                    fascicolo.tEDataNascita = this.serverData(fascicolo.tEDataNascita);
                    fascicolo.tEDataRilascioDocumentoIdentificativo = this.serverData(fascicolo.tEDataRilascioDocumentoIdentificativo);
                    fascicolo.tEDataScadenzaDocumentoIdentificativo = this.serverData(fascicolo.tEDataScadenzaDocumentoIdentificativo);
                    fascicolo.dataRilascioDocumentoIdentificativo = this.serverData(fascicolo.dataRilascioDocumentoIdentificativo);
                    fascicolo.dataScadenzaDocumentoIdentificativo = this.serverData(fascicolo.dataScadenzaDocumentoIdentificativo);
                    //cliente.tags = this.listaTags.join('-');

                    console.log(JSON.stringify(fascicolo));

                    let paramJson = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.utente.username,
                        token: this.$store.state.utente.token,
                        fascicoloAntiriciclaggio: fascicolo,
                        listaAllegati: this.nuovo ? [] : this.allegatiFascicolo,
                        listaAnnotazioni: this.nuovo ? [] : this.annotazioniFascicolo,
                        //listaVRI: this.nuovo ? [] : this.valutazioniRischioInerente,
                        //valutazioneRischioSpecifico: this.nuovo ? {} : this.valutazioneRischioSpecifico,
                        //ValutazioneRischioResiduo: this.nuovo ? {} : this.valutazioneRischioResiduo,
                    };
                                            
                    this.loadingSalva = true;

                    let fas = await callPost('api/antiriciclaggio/save', paramJson);
                    if (fas) {
                        this.fascicolo.id = fas.id;
                        this.nuovo = false;
                        bus.$emit('fascicoli-changed'); 
                        //alert("save");
                        //bus.$emit('fascicolo-changed', { lista: [this.fascicolo.id], sessionID: "-1" });
                    }

                    await this.internalCreated(fas ? Snack.success : Snack.error);

                    this.loadingSalva = false;
                });
            },
            async internalCreated(snack) {
                
                let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
                this.theme = theme;
                
                this.expanded=[];
                this.viewTab = false;
                this.tabPrec = this.tab;


                let param=[];
                this.listaComuni =this.$store.state.listaComuni;
                this.listaStati =this.$store.state.listaStati;
                //this.listaOperatore = this.$store.state.listaOperatore;
                //this.listaOperatoriProfessionisti=this.$store.state.listaOperatoriProfessionisti;

                this.listaTipiAdeguataVerifica = this.$store.state.listaTipiAdeguataVerifica;
                this.listaTipiDocumentoIndentificativo = this.$store.state.listaTipiDocumentoIndentificativo;
                this.listaTipiFileAntiriciclaggio = this.$store.state.listaTipiFileAntiriciclaggio;
                this.listaTipiPrestazioneRischioInerente = this.$store.state.listaTipiPrestazioneRischioInerente;
                this.listaTagsIni = await callService("api/tags", param);


                param=[this.fascicolo.id];
                let fascicolo = await callService("api/antiriciclaggio/fascicolo",param);
                this.allegatiOpen = false;

                console.log(JSON.stringify(fascicolo));

                if (!this.nuovo) {
                    param = [this.fascicolo.id];
                    //this.valutazioniRischioInerente = await callService("api/antiriciclaggio/listaValutazioniRischioInerente", param);
                    this.valutazioneRischioSpecifico = await callService("api/antiriciclaggio/valutazioneRischioSpecifico", param);
                    this.valutazioneRischioResiduo = await callService("api/antiriciclaggio/valutazioneRischioResiduo", param);
                    this.annotazioniFascicolo = await callService("api/antiriciclaggio/listaAnnotazioni", param);
                    this.allegatiFascicolo = await callService("api/antiriciclaggio/listaAllegati", param);
                    this.allegatiOpen = this.allegatiFascicolo.length > 0;

                    if (this.valutazioneRischioResiduo) {
                        this.valoreVRR = this.valutazioneRischioResiduo.valore;
                        //this.fascicolo.valutazioneRischioInerente = this.valutazioneRischioResiduo.valoreA;
                    }
                    //if (this.valutazioneRischioSpecifico) this.fascicolo.valutazioneRischioSpecifico = this.valutazioneRischioSpecifico.valore;
                    //if (this.valutazioneRischioResiduo) this.fascicolo.valutazioneRischioInerente = this.valutazioneRischioResiduo.valoreA;
                                   
                }   

                fascicolo.data = this.localData(fascicolo.data);
                fascicolo.dataScadenza = this.localData(fascicolo.dataScadenza);

                fascicolo.tEDataNascita = this.localData(fascicolo.tEDataNascita);
                fascicolo.tEDataRilascioDocumentoIdentificativo = this.localData(fascicolo.tEDataRilascioDocumentoIdentificativo);
                fascicolo.tEDataScadenzaDocumentoIdentificativo = this.localData(fascicolo.tEDataScadenzaDocumentoIdentificativo);
                fascicolo.dataRilascioDocumentoIdentificativo = this.localData(fascicolo.dataRilascioDocumentoIdentificativo);
                fascicolo.dataScadenzaDocumentoIdentificativo = this.localData(fascicolo.dataScadenzaDocumentoIdentificativo);


                if (!fascicolo.tipoDocumentoIdentificativo) fascicolo.tipoDocumentoIdentificativo = { id: '' };
                if (!fascicolo.tipoAdeguataVerifica) fascicolo.tipoAdeguataVerifica = { id: '' };
                this.listaTags=fascicolo.cliente.tags ? fascicolo.cliente.tags.split('-') : [];


                //this.rstatus = "0";
                //if (cliente.isAddebitiSospesi) this.rstatus = "1";
                //if (cliente.isSospeso) this.rstatus = "2";
                //if (cliente.isCancellato) this.rstatus = "3";


                //copio adesso sul cliente di this per non avere sfarfallamenti

                this.fascicolo = fascicolo;

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

                this.$nextTick(() => {                    
                    this.viewTab = true;
                    this.checkSave = false;
                    this.tab = this.tabPrec;
                });
            },
            chiudi() {
                //alert(this.checkSave);
                //alert(this.viewTab);
                if (!this.checkSave || !this.viewTab) {
                    //this.fascicolo = {
                    //    id:'',
                    //    cliente: {
                    //        id:''
                    //    },
                    //    professionista: {
                    //        id:''
                    //    },
                    //    tipoAdeguataVerifica: {
                    //        id:''
                    //    },
                    //    tipoDocumentoIdentificativo: {
                    //        id: ''
                    //    }
                    //};
                    bus.$emit('fascicoli-changed'); 
                    this.dialog=false;     
                }
                else  {
                    bus.$emit('msg-open', { message: "Sono state rilevate delle modifiche.", item: this.fascicolo, okTxt:"Rimani sull'anagrafica fascicolo",cancelTxt:"Chiudi senza salvare"});                
                    bus.$on('msg-cancel', this.esci); 
                    bus.$on('msg-ok', this.rimani);
                }
            },
            async rimani(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
            },
            async esci(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
                //this.fascicolo = {
                //    id:'',
                //    cliente: {
                //        id:''
                //    },
                //    professionista: {
                //        id:''
                //    },
                //    tipoAdeguataVerifica: {
                //        id:''
                //    },
                //    tipoDocumentoIdentificativo: {
                //        id: ''
                //    }
                //};
                bus.$emit('fascicoli-changed'); 
                this.dialog=false; 
            },
            coloraRischio(item, tipo) {
                if (tipo) {
                    if (item == 0) return "black";
                    else if (item <= 1.5) return "black";
                    else if (item <= 2.5) return "black";
                    else if (item <= 3.5) return "black";
                    else return "white";
                }
                else {
                    if (item == 0) return "grey";
                    else if (item <= 1.5) return "green";
                    else if (item <= 2.5) return "yellow";
                    else if (item <= 3.5) return "orange";
                    else return "red";

                }
            },
            coloratfa(item) {
                //alert(item);
                if (item == "far0") return "red";
                else return "black";
            }
        },
        computed: {
            admin(){
                return this.$store.state.admin;
            }
        },
        watch:{
            fascicolo: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            allegatiFascicolo: {
                handler() {
                    this.checkSave = true;
                },
                deep: true,
            }
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;
            //this.$updaterHub.$on('fascicolo-changed', this.onFascicoloChanged);            
            bus.$on('fascicolo-changed', this.onFascicoloChanged);

            var vm = this;

            bus.$on('dialog-show-fascicolo', async function (fascicolo) {

                if(vm.$refs.form) vm.$refs.form.reset();

                vm.checkCF = true;
                vm.dialog = true;
                vm.loading = true;
                vm.nuovo = fascicolo.id == "0";
                vm.fascicolo.id = fascicolo.id;
                vm.tab=0;
                await vm.internalCreated(Snack.nothing);
                vm.loading = false;
            });      
        },
        beforeDestroy() {
            //bus.$emit('fascicoli-changed'); 
            bus.$off('fascicolo-changed', this.onFascicoloChanged);
            //this.$updaterHub.$off('fascicolo-changed', this.onFascicoloChanged);
            bus.$off('dialog-show-fascicolo');
        }
    }

</script>

<style>
    .style-lock {
        color: grey
    }
</style>
