<template>
    <v-card class="ma-2" :loading="loading" color="pink lighten-1" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-calendar-sync
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Scadenze: {{listaScadenze.length}}
            </span>
            <v-spacer></v-spacer>
            <!--<v-btn icon @click="apriScadenza" class="ml-2">
            <v-icon>mdi-magnify</v-icon>
        </v-btn>-->
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="listaScadenze"
                          :items-per-page="5"
                          class="elevation-0 transparent"
                          disable-items-per-page
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[5]
                                          }"
                          no-data-text="Nessuna scadenza in lista">

                <template v-slot:item.dataScadenza="{ item }">
                    <span v-text="localData(item.dataScadenza)" />
                </template>

                <template v-slot:item.cliente.denominazioneNormalizzata="{ item }">
                    <span class="text-subtitle font-weight-medium">[</span>
                    <span class="text-subtitle font-weight-medium" v-text="item.cliente.codice" />
                    <span class="text-subtitle font-weight-medium">]&nbsp;</span>
                    <span class="text-subtitle font-weight-medium" v-text="item.cliente.denominazioneNormalizzata" />
                </template>
                <template v-slot:item.apri="{ item }">
                    <v-btn icon @click="apriScheda(item)" class="ml-2">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>



<script>
    import { callService, gLocalDateTime, gLocalData, euro } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {        
        data: () => ({
            loading: false,
            hCard: 450,
            listaScadenze: [],
            scadenza:{},
            headers: [
                { text: 'Cliente', value: 'cliente.denominazioneNormalizzata' },
                { text: 'Descrizione', value: 'descrizione' },
                { text: 'Data', value: 'dataScadenza' },
                { text: '', value: 'apri' }
            ],
        }),
        methods: {            
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            apriScheda(item) {
                /*alert(item.tipoScadenza);*/

                if (item.tipoScadenza == "SCA") {
                    this.$router.replace({ name: "scadenze" });
                }
                else if (item.tipoScadenza == "PRA") {
                    let pratica = JSON.parse(JSON.stringify(item.pratica));
                    if(this.$store.state.conf.isService) {
                        bus.$emit('dialog-show-servizio',pratica);
                    }
                    else {
                        bus.$emit('dialog-show-pratica', pratica);
                    }
                }
                else {
                    let cliente = JSON.parse(JSON.stringify(item.cliente));
                    bus.$emit('dialog-show-cliente', cliente);
                }
            },           
            async internalCreated() {
                this.loading = true;
                let param = [this.$store.state.utente.operatore.id];
                this.listaScadenze = await callService("api/dashboard/scadenze", param);
                this.loading = false;
            }
        },
        async created() {
            await this.internalCreated();
        }
    }</script>

