<template>
    <v-container>
        <v-row>
            <v-col cols="1">
                <v-btn color="teal" dark :loading="loading"
                       @click="value=new Date()">
                    Oggi
                    <v-icon right>mdi-calendar</v-icon> 
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-autocomplete v-model="operatoriSelezionati" class="ml-2"
                                :items="listaContattiCalendar"
                                chips
                                ref="destinatari"
                                no-data-text="Nessun utente trovato"
                                dense
                                outlined
                                multiple                                
                                persistent-hint
                                placeholder="Professionista/Operatore"
                                item-text="nominativo"
                                item-value="id"
                                @change="changeOperatori">
                    <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="remove(data.item)">
                            <v-avatar v-if="data.item.isAvatar" left>
                                <v-img style="object-fit:cover" :src="data.item.avatar"></v-img>
                            </v-avatar>
                            {{ data.item.nominativo }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-avatar color="indigo">
                                <span v-if="!data.item.isAvatar" class="white--text text-h7">{{data.item.inizialiNormalizzate}}</span>
                                <img style="object-fit:cover" v-if="data.item.isAvatar" :src="data.item.avatar" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-row>
                                    <v-col cols="10">
                                        <v-list-item-title>{{data.item.nominativo}}</v-list-item-title>
                                        <v-list-item-subtitle>{{data.item.ruolo}}</v-list-item-subtitle>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-row class="mt-1">
                    <v-btn icon
                           @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-toolbar-title class="mt-1" v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>

                    <v-btn icon
                           @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-row>

            </v-col>
            <v-col>
                <v-switch class="mt-0"
                    v-model="storico"
                    @click="change"
                    :label="storico ? 'storico: ultimo anno' : 'storico: ultimo mese'"
                    >
                </v-switch>
            </v-col>
            <v-col cols="2" align="right">
                <v-select v-model="type"
                          outlined
                          dense
                          item-value="id"
                          item-text="text"
                          :items="[{id:'day',text:'Giorno'},
                                {id:'week',text:'Settimana'},
                                {id:'month',text:'Mese'},
                                {id:'category',text:'Selezione Operatori'},
                            ]">
                </v-select>
            </v-col>
        </v-row>
        <v-card>
            <v-calendar ref="calendar"
                        :start="dataStart"
                        v-model="value"
                        color="primary"
                        :type="type"
                        :events="eventi"
                        category-show-all
                        category-text ="nominativo"
                        :categories="categories"
                        event-color="blue"
                        :event-ripple="false"
                        :weekdays="weekday"
                        :month-format="monthformat"
                        :weekday-format="weekdayformat"
                        :interval-format="intervalformat"
                        :first-interval=5
                        :interval-count=18                      
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"                    
                        @click:event="clickEvent">                
            </v-calendar>
        </v-card>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>

    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'
    import { bus } from '@/main.js'


    export default {
        
        data: () => ({
            weekday: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            type: 'week',
            loading: false,
            loadingSalva: false,
            loadingElimina: false,
            loadingAnnulla: false,
            eventi: [],
            pratica: {},           
            dragEvent: null,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,
            dialogAppuntamento: false,
            dialogDisponibilita: false,
            listaOre: [],
            listaColori: [],
            listaContatti: [],
            listaContattiCalendar:[],
            listaInvitati: [],
            categories:[],
            valid: false,
            validDisponibilita: false,
            snackSuccess: false,
            snackCancel: false,
            snackError: false,
            snackRemote: false,
            notifica: false,
            nuovo: false,
            dataStart: new Date(),
            dialogDisabled: false,
            idOperatoreCalendar:{},
            operatoriSelezionati:[],
            disponibilita: {},
            mappaColori: new Map(),
            colors: [],
            idxColor: 0,
            storico: false
        }),
        computed: {
            headerDialog() {
                if(this.nuovo) return "Nuovo Appuntamento";
                else if(this.appuntamento.isDisponibilita) return "Aggiorna Disponibilità";
                else if (this.appuntamento.isProvvisorio) return "Conferma Appuntamento";

                return "Aggiorna Appuntamento";
            },
            captionSalva(){
                return this.appuntamento.isProvvisorio ? "Conferma":"Salva";
            },
            intestazione() {
                let contatto=this.listaContattiCalendar.find(c=>c.id==this.idOperatoreCalendar);
                if(contatto) return contatto.nominativo;
                return "";
            },
        },
        methods: {
            
            weekdayformat(d) {
                if (d.weekday == 1) return "LUN";
                if (d.weekday == 2) return "MAR";
                if (d.weekday == 3) return "MER";
                if (d.weekday == 4) return "GIO";
                if (d.weekday == 5) return "VEN";
                if (d.weekday == 6) return "SAB";
                if (d.weekday == 0) return "DOM";
            },
            monthformat(d) {
                if (d.month == 1) return "Gennaio";
                if (d.month == 2) return "Febbraio";
                if (d.month == 3) return "Marzo";
                if (d.month == 4) return "Aprile";
                if (d.month == 5) return "Maggio";
                if (d.month == 6) return "Giugno";
                if (d.month == 7) return "Luglio";
                if (d.month == 8) return "Agosto";
                if (d.month == 9) return "Settembre";
                if (d.month == 10) return "Ottobre";
                if (d.month == 11) return "Novembre";
                if (d.month == 12) return "Dicembre";

            },
            intervalformat(d) {
                return d.hour + ":00";
            },
            startDrag({ event, timed }) {
                if (event && timed) {
                    this.dragEvent = event
                    this.dragTime = null
                    this.extendOriginal = null
                }
            },
            startTime(tms) {
                return;
                const mouse = this.toTime(tms)

                if (this.dragEvent && this.dragTime === null) {
                    const start = this.dragEvent.start
                    this.dragTime = mouse - start
                } else {
           
                    this.createStart = this.roundTime(mouse)
                    this.createEvent = {
                        name: 'Nuovo Evento',
                        color: "blue",
                        start: this.createStart,
                        end: this.createStart,
                        timed: true,
                    }

                    //evito la creazione così non si vede a video sotto la dialog
                    //this.eventi.push(this.createEvent)

                    this.visualizzaServizio(this.createEvent, true);
                }
            },
            extendBottom(event) {
                this.createEvent = event
                this.createStart = event.start
                this.extendOriginal = event.end
            },
            mouseMove(tms) {
                const mouse = this.toTime(tms)

                if (this.dragEvent && this.dragTime !== null) {
                    const start = this.dragEvent.start
                    const end = this.dragEvent.end
                    const duration = end - start
                    const newStartTime = mouse - this.dragTime
                    const newStart = this.roundTime(newStartTime)
                    const newEnd = newStart + duration

                    this.dragEvent.start = newStart
                    this.dragEvent.end = newEnd

                } else if (this.createEvent && this.createStart !== null) {
                    const mouseRounded = this.roundTime(mouse, false)
                    const min = Math.min(mouseRounded, this.createStart)
                    const max = Math.max(mouseRounded, this.createStart)

                    this.createEvent.start = min
                    this.createEvent.end = max
                }
            },
            endDrag() {
                var cambio = false;
                var ts1 = null;
                var ts2 = null;
                if(this.dragEvent) {
                    if(this.dragEvent.appuntamento) {
                        ts1 = new Date(this.dragEvent.appuntamento.dataInizio).getTime();
                    }
                    else ts1=0;
                    ts2 = this.dragEvent.start;
                }
                if(this.extendOriginal) {
                    ts1 = new Date(this.createEvent.appuntamento.dataFine).getTime();
                    ts2 = this.createEvent.end;
                }
                if (this.dragEvent || this.extendOriginal) {
                    var d1 = new Date(ts1)+"";
                    var d2 = new Date(ts2)+""
                    var cambio =  (d1 != d2 );
                    if(cambio) {
                        this.visualizzaServizio(this.dragEvent ? this.dragEvent : this.createEvent, false );
                    }
                }

                this.dragTime = null
                this.dragEvent = null
                this.createEvent = null
                this.createStart = null
                this.extendOriginal = null
            },
            cancelDrag() {
                if (this.createEvent) {
                    if (this.extendOriginal) {
                        this.createEvent.end = this.extendOriginal
                    } else {
                        const i = this.eventi.indexOf(this.createEvent)
                        if (i !== -1) {
                            this.eventi.splice(i, 1)
                        }
                    }
                }

                this.createEvent = null
                this.createStart = null
                this.dragTime = null
                this.dragEvent = null
            },
            clickEvent(event) {                
                this.visualizzaServizio(event.eventParsed.input, false);
            },
            visualizzaServizio(event, nuovo) {
                let dtIni = new Date(event.start);
                let dtFine = new Date(event.end);                

                let oreIni = dtIni.getHours().toString().padStart(2, '0');
                let minutiIni = dtIni.getMinutes().toString().padStart(2, '0');
                let oreFine = dtFine.getHours().toString().padStart(2, '0');
                let minutiFine = dtFine.getMinutes().toString().padStart(2, '0');

                this.pratica = event.task ? event.task.pratica: null;
                if(!this.pratica || nuovo) {

                    let oreI= oreIni+":00";
                    let id = this.listaOre.findIndex(o=>o == oreI);
                    id = id + 4;
                    if(id>=this.listaOre.length) id = this.listaOre.length -1;
                    let oreF= this.listaOre[id];                   

                    this.pratica = {
                        dataInizio : this.localData(new Date(event.start)),
                        oraInizio : oreI,
                        oraFine : oreF
                    }

                }
                else {                    

                    this.pratica.dataInizio = this.localData(new Date(event.start));
                    this.pratica.oraInizio = oreIni+":"+minutiIni;
                    this.pratica.oraFine = oreFine+":"+minutiFine;

                }
                this.nuovo = nuovo;
                this.dialogDisabled = !this.checkDataFutura(this.pratica.dataInizio);
                if (nuovo && this.dialogDisabled) return;
                bus.$emit('dialog-show-servizio',this.pratica);

            },
            async annullaModifiche() {                
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);
                this.dialogAppuntamento = false;
                this.loadingAnnulla = false;
            },
            removeApp(item) {
                this.listaInvitati = this.listaInvitati.filter(c => c!=item.id);             
            },
            roundTime(time, down = true) {
                const roundTo = 15 // minutes
                const roundDownTime = roundTo * 60 * 1000

                return down
                    ? time - time % roundDownTime
                    : time + (roundDownTime - (time % roundDownTime))
            },
            toTime(tms) {
                return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(d) {                
                return gCheckData(d);
            },
            checkDataFutura(d) {
                let ok = gCheckData(d);
                if (!ok) return false;

                let adesso = new Date();
                let data = new Date(this.serverData(d));
                data.setDate(data.getDate() + 1);
                return data.getTime()>adesso.getTime(); 
            },
            changeOraInizio() {
                var id = this.listaOre.findIndex(o=>o == this.appuntamento.oraInizio);

                id = id + 4; //ora successiva

                if(id>=this.listaOre.length) id = this.listaOre.length -1;
                this.appuntamento.oraFine= this.listaOre[id];

            },
            changeOraFine() {
                var idF = this.listaOre.findIndex(o => o == this.appuntamento.oraFine);
                var idI = this.listaOre.findIndex(o => o == this.appuntamento.oraInizio);

                if (idF <= idI) {
                    idI = idF - 4;
                }

                if (idI < 0) idI = 0;
                this.appuntamento.oraInizio = this.listaOre[idI];

            },
            serverData(stringDate) {                
                return gServerData(stringDate);
            },            
            async onCalendarioChanged({ lista, sessionID }) {
                if (sessionID == this.$store.state.sessionID) return;

                if (lista.find(cod => cod == this.idOperatoreCalendar)) {
                    await this.internalCreated(Snack.remote);
                }                
            },
            getColorOperatore(id) {
                let color = this.mappaColori.get(id);
                if(!color) {
                    color = this.getColors();
                    this.mappaColori.set(id,color);
                }
                return color;
            },
            getColors() {
                if(this.idxColor<this.colors.length-1) this.idxColor++;
                else this.idxColor=0;                
                let color = this.colors[this.idxColor];
                return color;
            },
            onCalendarioSetDate({ dataInizio }) {
                this.dataStart = new Date(dataInizio);
            },
            async internalCreated(snack) {
                let param = [];
                this.listaOre = await callService('api/calendario/listaOre', param);

                param = [];
                this.listaColori = await callService('api/calendario/listaColori', param);                

                let paramJson = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.utente.username,
                        token: this.$store.state.utente.token,
                        listaOperatori: this.operatoriSelezionati, 
                        storico: this.storico                      
                    };
                this.loadingSalva = true;
                const data = await callPost('api/calendarioService/filtra', paramJson);
                const eventi = [];

                var self = this;

                data.forEach(function (task) {
                    let data  = new Date();
                    let dataFinale = new Date();
                    if(task.dataPresaInCarico) {
                        data = new Date(task.dataPresaInCarico);
                        if(task.dataEsecuzione) {
                            dataFinale = new Date(task.dataEsecuzione);
                        }
                        else {
                            dataFinale.setTime(data.getTime()+(1)*60*60*1000); 
                        }
                    }
                    else {
                        data = new Date(task.pratica.dataInizio);  
                        let h=data.getHours();
                        if(h==0) {
                            h = 9;
                            data.setTime(data.getTime()+h*60*60*1000);
                            dataFinale.setTime(data.getTime()+(1)*60*60*1000); 
                        }
                        else {
                            dataFinale = new Date(task.pratica.dataScadenza); 
                            h=dataFinale.getHours();
                            if(h==0) { //data finale non impostata
                                dataFinale.setTime(data.getTime()+(1)*60*60*1000); //metto un'ora dopo
                            }
                        }
                    }   
                   
                    eventi.push({
                        name: task.operatore.denominazioneNormalizzata+ " - "+task.pratica.cliente.denominazioneNormalizzata,
                        start: data,
                        end: dataFinale,
                        color: self.getColorOperatore(task.operatore.id),
                        timed: true,
                        category: task.operatore.denominazioneNormalizzata,
                        //campi personalizzati
                        task: task
                    });
                });
                this.eventi = eventi;                

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            async change() {
                this.loading=true;
                await this.internalCreated(Snack.nothing);
                this.loading=false;
            },
            async changeOperatori() {               
                let nomi =  [];

                var self = this;
                console.log(JSON.stringify(this.operatoriSelezionati));
                this.operatoriSelezionati.forEach(id => {     
                    let contatto = self.listaContattiCalendar.find(c=>c.id==id);            
                    nomi.push(contatto);
                });

                console.log(JSON.stringify(nomi));       
                this.categories = nomi;
                await this.change();            
            },
            async remove(item) {
                const index = this.operatoriSelezionati.indexOf(item.id);
                this.operatoriSelezionati.splice(index, 1); 
                await this.changeOperatori();             
            },               
            
            clickCheck(item) {
                if (!item.isDisponibile) {
                    item.oreInizio = null;
                    item.oreFine = null;
                }
            },
            getStaticColors() {
                return ['red darken-4',
                        'pink darken-4',
                        'purple darken-4',
                        'deep-purple darken-4',
                        'indigo darken-4',
                        'blue darken-4',
                        'light-blue darken-4',
                        'cyan darken-4',
                        'teal darken-4',
                        'green darken-4',
                        'light-green darken-4',
                        'lime darken-4',
                        'yellow darken-4',
                        'amber darken-4',
                        'orange darken-4',
                        'deep-orange darken-4',
                        'brown darken-4',
                        'blue-grey darken-4'];
            },       
        },        
        async created() {
            bus.$on('calendario-setdate', this.onCalendarioSetDate);
            bus.$on('pratica-changed', this.change);

            this.colors = this.getStaticColors();
            this.loading = true;

            let param = [];

            param =[];
            const professionisti = await callService('api/professionista/listaprofessionisticompleta',param);

            param =[];
            const operatori = await callService('api/operatore/listaoperatoricompleta',param);

            param =[];
            const clienti = await callService('api/cliente/listaclientiapp',param);

            const listaContatti = [];
            const listaContattiCalendar = [];

            listaContatti.push(
                {header: "Professionisti"}
            );
            listaContattiCalendar.push(
                {header: "Professionisti"}
            );
            for(const operatore of professionisti) {
                param=[this.$store.state.utente.operatore.id,operatore.id,"S"];
                listaContatti.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );
                listaContattiCalendar.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );

            };
           
            listaContatti.push(
                {header: "Operatori"}
            );
            listaContattiCalendar.push(
                {header: "Operatori"}
            );
            
            for(const operatore of operatori) {                
                listaContatti.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );
                    listaContattiCalendar.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );
            };

            listaContatti.push(
                {header: "Clienti App"}
            );
            for(const cliente of clienti) {                
                listaContatti.push(
                    {
                      id: cliente.id,
                      nominativo: cliente.denominazioneNormalizzata,
                      ruolo: "Cliente",
                      avatar: cliente.avatarLink,
                      isAvatar: cliente.isAvatar,
                      inizialiNormalizzate: cliente.inizialiNormalizzate,
                    },
                );
            };
            
            this.listaContatti = listaContatti;
            this.listaContattiCalendar = listaContattiCalendar;

            this.idOperatoreCalendar = this.$store.state.utente.operatore.id;
            await this.internalCreated(Snack.nothing);
            this.loading = false;
        },
        beforeDestroy() {
            bus.$off('calendario-setdate', this.onCalendarioSetDate);
            bus.$off('pratica-changed', this.change);
        }        

    }</script>

<style scoped lang="scss">
    .v-event-draggable {
        padding-left: 6px;
    }

    .v-event-timed {
        user-select: none;
        -webkit-user-select: none;
    }

    .v-event-drag-bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        height: 4px;
        cursor: ns-resize;

        &::after {
            display: none;
            position: absolute;
            left: 50%;
            height: 4px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            width: 16px;
            margin-left: -8px;
            opacity: 0.8;
            content: '';
        }

        &:hover::after {
            display: block;
        }
    }
</style>