<template>
    <v-card class="ma-2">
        <v-card-text>

            <v-tabs v-model="tab">
                <v-tab>Dashboard Operatore</v-tab>
                <v-tab v-if="!$store.state.conf.isService && ($store.state.utente.isGestoreStudio || $store.state.utente.operatore.professionista)">Dashboard Professionista</v-tab>
                <v-tab v-if="$store.state.conf.isService && ($store.state.utente.isGestoreStudio || $store.state.utente.operatore.professionista)">Dashboard Azienda</v-tab>
                <v-tab>Dashboard Sistema</v-tab>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <v-select v-if="$store.state.utente.isGestoreStudio && tab==1"
                          class="mt-2 mr-2"
                          v-model="idProf"
                          :items="listaOperatoriProfessionisti"
                          item-value="professionista.id"
                          item-text="denominazioneNormalizzata"
                          label="Filtra per Professionista"
                          outlined
                          dense>
                </v-select>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-row dense v-if="!$store.state.conf.isService">
                        <v-col>
                            <appuntamenti-oggi />
                        </v-col>
                        <v-col>
                            <appuntamenti-conferma />
                        </v-col>
                    </v-row>  
                    <v-row dense>
                        <v-col>
                            <task-pratiche />
                        </v-col>
                        <v-col>
                            <messaggi />
                        </v-col>
                    </v-row>  
                    <v-row dense>
                        <v-col>
                            <scadenze-oggi />
                        </v-col>
                        <v-col>
                             <attivita />
                        </v-col>
                    </v-row>  
                </v-tab-item>
                <v-tab-item v-if="$store.state.utente.isGestoreStudio || $store.state.utente.operatore.professionista">
                    <v-row v-if="$store.state.utente.isGestoreStudio" dense>
                        <v-col>
                            <distribuzione-clienti :idProf="null" />
                        </v-col>
                        <v-col>
                            <distribuzione-clienti-fido :idProf="null" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <distribuzione-clienti :idProf="idProf" />
                        </v-col>
                        <v-col>
                            <distribuzione-clienti-fido :idProf="idProf" />
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <v-row dense>
                        <v-col>
                            <utenti-connessi />
                        </v-col>
                        <v-col>
                            <clienti-connessi />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
</v-card-text>
    </v-card>
</template>

<script>
    import { GChart } from 'vue-google-charts/legacy'
    import DistribuzioneClienti from '@/views/components/widgets/DistribuzioneClienti.vue'
    import DistribuzionePagamenti from '@/views/components/widgets/DistribuzionePagamenti.vue'
    import DistribuzioneClientiFido from '@/views/components/widgets/DistribuzioneClientiFido.vue'
    import Attivita from '@/views/components/widgets/Attivita.vue'
    import TaskPratiche from '@/views/components/widgets/TaskPratiche.vue'
    import AppuntamentiOggi from '@/views/components/widgets/AppuntamentiOggi.vue'
    import Messaggi from '@/views/components/widgets/Messaggi.vue'
    import ScadenzeOggi from '@/views/components/widgets/ScadenzeOggi.vue'
    import AppuntamentiConferma from '@/views/components/widgets/AppuntamentiConferma.vue'
    import UtentiConnessi from '@/views/components/widgets/UtentiConnessi.vue'
    import ClientiConnessi from '@/views/components/widgets/ClientiConnessi.vue'
    import { bus } from '@/main.js'

    export default {
        components: {
            DistribuzioneClienti,
            DistribuzioneClientiFido,
            DistribuzionePagamenti,
            Messaggi,
            TaskPratiche,
            Attivita,
            AppuntamentiOggi,
            AppuntamentiConferma,
            ScadenzeOggi,
            UtentiConnessi,
            ClientiConnessi
        },
        data: () => ({
            tab: 0,
            listaOperatoriProfessionisti: [],
            idProf:''
        }),
        methods: {            
            
        },
        computed: {
            
        },
        async created() {
            this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;
            this.idProf =
                this.$store.state.utente.operatore.professionista
                ? this.$store.state.utente.operatore.professionista.id
                : (this.listaOperatoriProfessionisti.length > 0 ? this.listaOperatoriProfessionisti[0].professionista.id : '');
        }
    }</script>
