import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import Store from '../store/store'

export default {

    install(Vue) {
        /*
        const connection = new HubConnectionBuilder()
            .withUrl('/updater-hub')
            .configureLogging(LogLevel.Information)
            .build();
            */

            const connection = new HubConnectionBuilder().withUrl('/updater-hub', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            }).configureLogging(LogLevel.Information)
            .build();

        let startedPromise = null
        function start() {
            startedPromise = connection.start()
                .then(function () {
                    console.info("SignalR OK");
                })
                .catch(err => {
                    console.error('"SignalR KO"', err)
                    return new Promise((resolve, reject) =>
                        setTimeout(() => start().then(resolve).catch(reject), 5000))
                });
            return startedPromise;
        }

        connection.onclose(() => start());

        start();

        const updaterHub = new Vue();

        Vue.prototype.$updaterHub = updaterHub;

        
        connection.on('ClienteChanged', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            console.info("ClienteChanged:" + partition + " "+ lista + " " + sessionID);

            updaterHub.$emit('cliente-changed', { lista, sessionID });

        });       

        connection.on('ClienteDeleted', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            console.info("ClienteDeleted:" + partition + " "+ lista + " " + sessionID);

            updaterHub.$emit('cliente-deleted', { lista, sessionID });

        });    

        connection.on('UtenteChanged', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            console.info("UtenteChanged:" + partition + " " + lista + "_" + sessionID);

            updaterHub.$emit('utente-changed', { lista, sessionID });

        });

        connection.on('AgenteChanged', (partition, lista, sessionID) => {

            //if (Store.state.partition != partition) return;

            console.info("AgenteChanged:" + partition + " " + lista + "_" + sessionID);

            updaterHub.$emit('agente-changed', { lista, sessionID });

        });

        connection.on('CalendarioChanged', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            console.info("CalendarioChanged:" + partition + " " + lista + "_" + sessionID);

            if (Store.state.utente.operatore.id != lista[1]) return;

            updaterHub.$emit('calendario-changed', { lista, sessionID });
            updaterHub.$emit('notifiche-changed', {title: lista[3], subTitle: "Prenotazione Slot Cliente", message: ""});
        });

        connection.on('ServiziAppChanged', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            if (Store.state.utente.operatore.id != lista[1]) return;

            console.info("ServiziAppChanged:" + partition + " " + sessionID);

            updaterHub.$emit('notifiche-changed', {title: lista[3], subTitle: "Richiesta Cliente App", message: lista[4]});

        });

        connection.on('ChatChanged', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            console.info("ChatChanged:" + partition);            
            
            if (Store.state.utente.operatore.id != lista[1]) return;

            updaterHub.$emit('chat-changed', {});
            updaterHub.$emit('notifiche-changed', {title: lista[3], subTitle: "Messaggio Chat", message: lista[4]});
        });

        connection.on('PraticheChanged', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            console.info("PraticheChanged:" + partition);            
            
            if (Store.state.utente.operatore.id != lista[1]) return;

            updaterHub.$emit('notifiche-changed', {title: lista[3], subTitle: lista[4], message: ""});

        });

        connection.on('SchedaChanged', (partition, lista, sessionID) => {

            if (Store.state.partition != partition) return;

            console.info("SchedaChanged:" + partition + " "+ lista + " " + sessionID);

            if (Store.state.utente.operatore.id != lista[1]) return;

            updaterHub.$emit('notifiche-changed', {title: lista[3], subTitle: lista[4], message: ""});

        });
    }
}
