<template>
    <v-card class="ma-2" :loading="loading" color="blue-grey" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-account-multiple
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Accessi Operatori/Professionisti - Ultimi 30m: {{utentiAdesso.length}} utent{{utentiAdesso.length==1 ? 'e':'i'}}
            </span>
        </v-card-title>
        <v-card-text>

            <v-data-table no-data-text="Non sono presenti utenti"
                          :headers="headers"
                          :items="utenti"
                          :items-per-page="5"
                          class="blue-grey"
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,10,20,-1]
                                          }">
                <template v-slot:item.dataUltimoLogin="{ item }">
                    <span v-text="localDateTime(item.dataUltimoLogin)" />
                </template>
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>

<script>
    import { gLocalDateTime } from '@/modules/utilities.js'

    export default {
        data: () => ({
            loading: false,
            hCard: 450,
            utenti: [],
            utentiAdesso:[],
            headers: [
                { text: 'Utente', align: 'start', sortable: false, value: 'username', },
                { text: 'Operatore', align: 'start', sortable: false, value: 'operatore.denominazioneNormalizzata', },
                { text: 'Data di Login', align: 'start', sortable: false, value: 'dataUltimoLogin', },
                ]
        }),   
        methods: {
            localDateTime(v) {
                return gLocalDateTime(v);
            },
        },
        async created() {
            this.loading = true;
            let utenti = this.$store.state.utenti;            
            utenti = utenti.filter(u => u.operatore != null && u.dataUltimoLogin != null);
            this.utenti = utenti.sort(
                function (u1, u2) {
                    return new Date(u2.dataUltimoLogin) - new Date(u1.dataUltimoLogin); //ordinamento descrescente
                });
            const adesso = new Date();
            const mezzoraFa = new Date(adesso.setMinutes(adesso.getMinutes() - 30))
            this.utentiAdesso = utenti.filter(u => new Date(u.dataUltimoLogin) > mezzoraFa);
            this.loading = false;
        }
    }</script>

