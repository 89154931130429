<template>
    <v-dialog v-model="dialogRR" persistent
              max-width="1200">
        <v-stepper v-model="rr">
            <v-stepper-header>
                <v-stepper-step :complete="rr > 1"
                                step="1">
                    {{descrizioneRR("A1", true)}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rr > 2"
                                step="2">
                    {{descrizioneRR("A2", true)}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rr > 3"
                                step="3">
                    {{descrizioneRR("A3", true)}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rr > 4"
                                step="4">
                    {{descrizioneRR("A4", true)}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rr > 5"
                                step="5">
                    Riepilogo
                </v-stepper-step>


                <v-divider></v-divider>

                <v-stepper-step step="6">
                    Esito
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items class="mt-n16">
                <v-stepper-content step="1">
                    <v-container class="mb-12">
                        <v-form ref="formRR1"
                                v-model="validRR1">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold">{{descrizioneRR("A1", true)}}</v-card-text>

                                    <v-card-text class="mt-n6">
                                        <div>{{descrizioneRR("A1", false)}}</div>
                                    </v-card-text>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionA1"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn :disabled="selectionA1==-1" color="success" depressed
                                   @click="rr = 2">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-container class="mb-12">
                        <v-form ref="formRR2"
                                v-model="validRR2">
                            <v-row>
                                <v-col>
                                    <v-card class="mx-auto my-12"
                                            max-width="1100">

                                        <v-card-text style="text-align:center; font-weight:bold">{{descrizioneRR("A2", true)}}</v-card-text>

                                        <v-card-text class="mt-n6">
                                            <div>{{descrizioneRR("A2", false)}}</div>
                                        </v-card-text>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                        <v-card-text>
                                            <v-chip-group v-model="selectionA2"
                                                          active-class="deep-purple accent-4 white--text"
                                                          column
                                                          class="mt-n8">
                                                <v-chip>1</v-chip>

                                                <v-chip>2</v-chip>

                                                <v-chip>3</v-chip>

                                                <v-chip>4</v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                    </v-card>

                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rr = 1">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionA2==-1" color="success" depressed
                                   @click="rr = 3">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-container class="mb-12">
                        <v-form ref="formRR3"
                                v-model="validRR3">
                            <v-row>
                                <v-col cols="10" offset="1">
                                    <v-card class="mx-auto my-12"
                                            max-width="1100">

                                        <v-card-text style="text-align:center; font-weight:bold">{{descrizioneRR("A3", true)}}</v-card-text>

                                        <v-card-text>
                                            <div>{{descrizioneRR("A3", false)}}</div>
                                        </v-card-text>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                        <v-card-text>
                                            <v-chip-group v-model="selectionA3"
                                                          active-class="deep-purple accent-4 white--text"
                                                          column
                                                          class="mt-n6">
                                                <v-chip>1</v-chip>

                                                <v-chip>2</v-chip>

                                                <v-chip>3</v-chip>

                                                <v-chip>4</v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                    </v-card>

                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rr = 2">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionA3==-1" color="success" depressed
                                   @click="rr = 4">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <v-container class="mb-12">
                        <v-form ref="formRR4"
                                v-model="validRR4">
                            <v-row>
                                <v-col>
                                    <v-card class="mx-auto my-12"
                                            max-width="1100">

                                        <v-card-text style="text-align:center; font-weight:bold">{{descrizioneRR("A4", true)}}</v-card-text>

                                        <v-card-text>
                                            <div>{{descrizioneRR("A4", false)}}</div>
                                        </v-card-text>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                        <v-card-text>
                                            <v-chip-group v-model="selectionA4"
                                                          active-class="deep-purple accent-4 white--text"
                                                          column
                                                          class="mt-n6">
                                                <v-chip>1</v-chip>

                                                <v-chip>2</v-chip>

                                                <v-chip>3</v-chip>

                                                <v-chip>4</v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                    </v-card>

                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rr = 3">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionA4==-1" color="success" depressed
                                   @click="rr = 5">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="5">
                    <v-container class="mb-12">
                        <v-row>
                            <v-col cols="6">
                                <v-row dense class="mt-10">
                                    <v-col>
                                        <v-list-item two-line>
                                            <v-list-item-icon>
                                                <v-icon color="blue">mdi-chart-bar</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6 blue--text">A - Rischio Inerente</v-list-item-title>
                                                <v-list-item-subtitle>{{descrizioneRR("A1", true)}}: <strong>{{selectionA1+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>{{descrizioneRR("A2", true)}}: <strong>{{selectionA2+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>{{descrizioneRR("A3", true)}}: <strong>{{selectionA3+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>{{descrizioneRR("A4", true)}}: <strong>{{selectionA4+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle class="blue--text"><strong>Media aritmetica: {{mediaRR}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle><strong>Coefficiente di ponderazione: 40%</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle class="blue--text"><strong>A - Rischio inerente: {{(mediaRR*0.4).toFixed(2)}}</strong></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="mt-n6">
                                        <v-list-item two-line>
                                            <v-list-item-icon>
                                                <v-icon color="purple">mdi-chart-line</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6 purple--text">B - Vulnerabilità Studio</v-list-item-title>
                                                <v-list-item-subtitle>Formazione: <strong>{{selectionB1+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>Adeguata verifica: <strong>{{selectionB2+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>Conservazione: <strong>{{selectionB3+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>Sos e contante: <strong>{{selectionB4+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle class="purple--text"><strong>Media aritmetica: {{(vulnerabilitacorrente.valore).toFixed(2)}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle><strong>Coefficiente di ponderazione: 60%</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle class="purple--text"><strong>B - Livello vulnerabilità: {{(vulnerabilitacorrente.valore*0.6).toFixed(2)}}</strong></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row dense class="mt-10">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon color="brown">mdi-chart-bar</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6 brown--text">Determinazione del Rischio Residuo</v-list-item-title>
                                            <v-list-item-subtitle class="blue--text"><strong>A - Rischio inerente: {{(mediaRR*0.4).toFixed(2)}}</strong></v-list-item-subtitle>
                                            <v-list-item-subtitle class="purple--text"><strong>B - Livello vulnerabilità: {{(vulnerabilitacorrente.valore*0.6).toFixed(2)}}</strong></v-list-item-subtitle>
                                            <v-list-item-subtitle><strong class="brown--text">Rischio Residuo: {{((mediaRR*0.4)+(vulnerabilitacorrente.valore*0.6)).toFixed(1)}}</strong></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-row>
                                <v-row>
                                    <v-col cols="8" offset="1">
                                        <v-card color="white"
                                                dark>
                                            <v-card-title class="text-h10 black--text">
                                                Rischio residuo da valori ponderati
                                            </v-card-title>
                                        </v-card>
                                        <v-card color="green"
                                                dark>
                                            <v-card-subtitle class="text-h10">
                                                <v-icon :color="coloraRR(1)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Non significativo - Punteggio 1-1.5
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-card color="yellow"
                                                dark>
                                            <v-card-subtitle class="text-h10 black--text">
                                                <v-icon :color="coloraRR(2)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Poco significativo - Punteggio 1.6-2.5
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-card color="orange"
                                                dark>
                                            <v-card-subtitle class="text-h10 black--text">
                                                <v-icon :color="coloraRR(3)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Abbastanza significativo - Punteggio 2.6-3.5
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-card color="red"
                                                dark>
                                            <v-card-subtitle class="text-h10 white--text">
                                                <v-icon :color="coloraRR(4)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Molto significativo - Punteggio 3.6-4
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-row dense class="mt-n10">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rr = 4">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="success" depressed
                                   @click="sendRR">
                                Aggiorna Valutazione Rischio Residuo
                                <v-icon right>
                                    mdi-send
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="6">
                    <v-container class="mb-12" align="center">
                        <v-col cols="6" offset="3">
                            <v-card v-if="loadingRR" color="primary"
                                    dark>
                                <v-card-text>
                                    Esecuzione Operazione...
                                    <v-progress-linear indeterminate
                                                       color="white"
                                                       class="mb-0">
                                    </v-progress-linear>
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingRR && esitoRROK" color="success"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    Operazione eseguita correttamente
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingRR && !esitoRROK" color="error"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-alert-circle
                                    </v-icon>
                                    Errore durante l'esecuzione dell'operazione
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-container>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi"
                                   color="warning"
                                   :disabled="loadingRR">
                                Chiudi
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>


    </v-dialog>
</template>


<script>

    import { callService, callPost, Snack, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        props: ['type'],
        data: () => ({
            progress: false,
            theme:false,
            rr: 1,
            dialogRR: false,
            validRR1: false,
            validRR2: false,
            validRR3: false,
            validRR4: false,
            loadingRR: false,
            esitoRROK: false,

            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },

            valutazioniRischioResiduo: {id: ''},
            nuovo: false,
            listaTVRI: [],
            listaTVV: [],
            idRI: '',
            selectionA1: 0,
            selectionA2: 0,
            selectionA3: 0,
            selectionA4: 0,
            selectionB1: 0,
            selectionB2: 0,
            selectionB3: 0,
            selectionB4: 0,
            vulnerabilitacorrente: {id: '', valore: 0},
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,

        }),
        methods: {
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            checkData(v) {
                return gCheckData(v);
            },

            descrizioneRR(codice, tipo) {
                let p = this.listaTVRI.find(x => x.codice === codice);
                if (p) 
                {
                    if (tipo) return p.descrizione;
                    else return p.criteriValutazione;
                }
              
                else return "";
            },
            descrizionevulnerabilita(codice, tipo) {
                let p = this.listaTVV.find(x => x.codice === codice);
                if (p) {
                    if (tipo) return p.descrizione;
                    else return p.criteriValutazione;
                }
                else return "";
            },
            serverData(v) {
                return gServerData(v);
            },
            async sendRR() {
                this.loadingRR = true;
                this.rr = 6;

                this.valutazioniRischioResiduo.valutazioneA1 = this.selectionA1 + 1;
                this.valutazioniRischioResiduo.valutazioneA2 = this.selectionA2 + 1;
                this.valutazioniRischioResiduo.valutazioneA3 = this.selectionA3 + 1;
                this.valutazioniRischioResiduo.valutazioneA4 = this.selectionA4 + 1;

                this.valutazioniRischioResiduo.valutazioneB1 = this.selectionB1 + 1;
                this.valutazioniRischioResiduo.valutazioneB2 = this.selectionB2 + 1;
                this.valutazioniRischioResiduo.valutazioneB3 = this.selectionB3 + 1;
                this.valutazioniRischioResiduo.valutazioneB4 = this.selectionB4 + 1;

                let valutazioniRischioResiduo = JSON.parse(JSON.stringify(this.valutazioniRischioResiduo));

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    valutazioneRischioResiduo: valutazioniRischioResiduo
                };

                let sca = await callPost('api/antiriciclaggio/savevrr', param);


                if (sca) {
                    //alert(sca.id);
                    this.valutazioniRischioResiduo.id = sca.id;
                    this.nuovo = false;
                    bus.$emit('vrr-snack-succes', {});
                    bus.$emit('fascicolo-changed');
                    this.loadingRR = false;
                    this.dialogRR = false;
                }
                else {
                    await this.internalCreated(Snack.error);
                    this.loadingRR = false;
                }
            },
            chiudi() {
                this.dialogRR = false;
            },            
            async internalCreated(snack) {

                let param = [];
                this.listaTVRI = this.$store.state.listaTVRI;
                this.listaTVV = this.$store.state.listaTVV;
                this.vulnerabilitacorrente = await callService("api/antiriciclaggio/vulnerabilitaCorrente", param);


                param = [this.valutazioniRischioResiduo.id];
                this.valutazioniRischioResiduo = await callService("api/antiriciclaggio/vrr", param);


                this.selectionA1 = this.valutazioniRischioResiduo.valutazioneA1-1;
                this.selectionA2 = this.valutazioniRischioResiduo.valutazioneA2-1;
                this.selectionA3 = this.valutazioniRischioResiduo.valutazioneA3-1;
                this.selectionA4 = this.valutazioniRischioResiduo.valutazioneA4-1;

                this.selectionB1 = this.vulnerabilitacorrente.valutazioneB1-1;
                this.selectionB2 = this.vulnerabilitacorrente.valutazioneB2-1;
                this.selectionB3 = this.vulnerabilitacorrente.valutazioneB3-1;
                this.selectionB4 = this.vulnerabilitacorrente.valutazioneB4-1;

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            coloraRR(livello) {
                let colore = (this.mediaRR * 0.4) + (this.vulnerabilitacorrente.valore * 0.6);
                if (livello == 1 && colore > 1.5) {
                    return "green";
                }
                if (livello == 2 && (colore < 1.6 || colore > 2.5)) {
                    return "yellow";
                }
                if (livello == 3 && (colore < 2.6 || colore > 3.5)) {
                    return "orange";
                }
                if (livello == 4 && colore < 3.6) {
                    return "red";
                }
                else { 
                    //alert(livello + " " + colore);    
                    return "black";
                }
            }            
        },
        computed: {
            mediaRR() {
                let media = 0;
                media = this.selectionA1 + this.selectionA2 + this.selectionA3 + this.selectionA4 + 4;
                media = media / 4;
                return media.toFixed(2);
            }
        }, 
        

        async created() {           

            var vm = this;

            bus.$on('dialog-show-rr', async function (valutazioniRischioResiduo) {
                vm.dialogRR = true;
                vm.loadingRR = true;
                vm.rr = 1;
                vm.nuovo = valutazioniRischioResiduo.id == "0";
                vm.valutazioniRischioResiduo.id = valutazioniRischioResiduo.id;
                await vm.internalCreated(Snack.nothing);
                vm.loadingRR = false;
            });   
        },
        beforeDestroy() {
            bus.$off('dialog-show-rr');
        }

    }</script>


    
