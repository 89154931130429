<template>
    <v-card :loading="loading">
        <v-form>
            <v-container>
                <v-row>                    
                    <v-col cols="4" offset="4">
                        <v-autocomplete v-model="contatto"
                                        :items="listaContatti"
                                        chips
                                        prepend-icon="mdi-undo-variant"
                                        ref="destinatari"
                                        no-data-text="Nessun utente trovato"
                                        dense
                                        outlined
                                        persistent-hint
                                        :hint="hint"
                                        placeholder="Operatore/Cliente"
                                        item-text="nominativo"
                                        item-value="id"
                                        @click:prepend="reset"
                                        @change="change">
                            <template v-slot:selection="data">
                                <v-chip v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="remove(data.item)">
                                    <v-avatar v-if="data.item.isAvatar" left>
                                        <v-img style="object-fit:cover" :src="data.item.avatar"></v-img>
                                    </v-avatar>
                                    {{ data.item.nominativo }}
                                </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar color="indigo">
                                        <span v-if="!data.item.isAvatar" class="white--text text-h7">{{data.item.inizialiNormalizzate}}</span>
                                        <img style="object-fit:cover" v-if="data.item.isAvatar" :src="data.item.avatar" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col cols="10">
                                                <v-list-item-title>{{data.item.nominativo}}</v-list-item-title>
                                                <v-list-item-subtitle>{{data.item.ruolo}}</v-list-item-subtitle>
                                            </v-col>                                           
                                        </v-row>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-switch class="mt-n1" label="mostra messaggi precedenti"
                                  v-model="all"
                                  @change="changeSwitch">
                        </v-switch>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <v-col cols="6" offset="3">
            <v-card v-if="!idOperatore && !idCliente">
                <v-list>
                    <v-subheader>Ultime Chat</v-subheader>
                    <v-list-item-group v-model="comunicazione"
                                       color="primary">
                        <v-list-item v-for="(item, i) in ultimeChat" @click="selectChat(item)"
                                     :key="i">
                            <v-list-item-avatar color="indigo">
                                <span v-if="!item.isAvatar" class="white--text text-h7">{{item.inizialiNormalizzate}}</span>
                                <img style="object-fit:cover" v-if="item.isAvatar" :src="item.avatarLink" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <b>
                                        {{item.descrizione}}
                                    </b>
                                </v-list-item-title>
                                <v-list-item-title v-text="item.testoUltimoMessaggio"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.dataUltimoMessaggioChat"></v-list-item-subtitle>

                            </v-list-item-content>
                            <v-list-item-action>
                                <v-badge class="ml-4 mt-12" v-if="item.isDaLeggere" dot color="red" overlap></v-badge>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>
        <v-col cols="6" offset="3" v-if="messaggiPresenti">
            <v-timeline>
                <v-timeline-item v-for="messaggio in messaggi"
                                 :key="messaggio.id"
                                 large
                                 :right="messaggio.isMittente"
                                 :left="messaggio.isDestinatario">
                    <template v-slot:icon>
                        <v-avatar color="indago" v-if="messaggio.isMittente">
                            <img style="object-fit:cover" v-if="messaggio.mittenteOperatore.isAvatar"
                                 :src="messaggio.mittenteOperatore.avatarLink">
                            <span v-else class="white--text text-h7">{{messaggio.mittenteOperatore.inizialiNormalizzate}}</span>
                        </v-avatar>
                        <v-avatar color="indago" v-if="messaggio.isDestinatario && messaggio.messaggioMittente.mittenteCliente">
                            <img style="object-fit:cover" v-if="messaggio.messaggioMittente.mittenteCliente.isAvatar"
                                 :src="messaggio.messaggioMittente.mittenteCliente.avatarLink">
                            <span v-else class="white--text text-h7">{{messaggio.messaggioMittente.mittenteCliente.inizialiNormalizzate}}</span>
                        </v-avatar>
                        <v-avatar color="indago" v-if="messaggio.isDestinatario && messaggio.messaggioMittente.mittenteOperatore">
                            <img style="object-fit:cover" v-if="messaggio.messaggioMittente.mittenteOperatore.isAvatar"
                                 :src="messaggio.messaggioMittente.mittenteOperatore.avatarLink">
                            <span v-else class="white--text text-h7">{{messaggio.messaggioMittente.mittenteOperatore.inizialiNormalizzate}}</span>
                        </v-avatar>
                    </template>
                    <v-card v-if="messaggio.isMittente" class="elevation-2" max-width="200" color="#056162">
                        <v-card-text class="pa-1">
                            <div v-if="messaggio.allegato" class="white--text">
                                [Allegato]
                                <v-btn icon :href="messaggio.allegatoLink" target="_blank">
                                    <v-icon class="white--text" v-if="messaggio.isPdf">mdi-file-pdf-box</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isExcel">mdi-file-excel</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isWord">mdi-file-word</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isImage">mdi-file-image</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isFile">mdi-file</v-icon>
                                </v-btn>
                            </div>
                            <div class="white--text">
                                {{messaggio.testo}}
                            </div>
                            <div v-if="messaggio.dataInvioMessaggio" class="grey--text text-caption mt-1">
                                <v-icon small class="blue--text">mdi-check</v-icon>{{messaggio.dataInvioMessaggio}}
                            </div>
                            <div v-if="messaggio.dataLetturaMessaggio" class="grey--text text-caption">
                                <v-icon small class="blue--text">mdi-check-all</v-icon>{{messaggio.dataLetturaMessaggio}}
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card align="right" v-else class="elevation-2" max-width="200" color="#262d31">
                        <v-card-text class="pa-1">
                            <div v-if="messaggio.allegato" class="white--text">
                                [Allegato]
                                <v-btn icon :href="messaggio.allegatoLink" target="_blank">
                                    <v-icon class="white--text" v-if="messaggio.isPdf">mdi-file-pdf-box</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isExcel">mdi-file-excel</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isWord">mdi-file-word</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isImage">mdi-file-image</v-icon>
                                    <v-icon class="white--text" v-if="messaggio.isFile">mdi-file</v-icon>
                                </v-btn>
                            </div>
                            <div class="white--text">
                                {{messaggio.testo}}
                            </div>
                            <div v-if="messaggio.dataInvioMessaggio" class="grey--text text-caption mt-1">
                                {{messaggio.dataInvioMessaggio}}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </v-col>
        <v-col cols="6" offset="5" v-if="contatto.length>0">
            <v-form ref="formSend"
                    v-model="valid"
                    class="mt-4 ml-4 mr-4"
                    onSubmit="return false"
                    lazy-validation>
                <v-row>
                    <v-col cols="2">
                        <v-avatar>
                            <v-img v-if="avatarLink" style="object-fit:cover"
                                   :src="avatarLink" />
                            <span v-else class="text-h6">{{altIniziali}}</span>
                        </v-avatar>
                    </v-col>
                    <v-col cols="1">
                        <v-btn class="mt-2"
                               icon
                               @click="open">
                            <v-icon color="blue">mdi-file-document-plus-outline</v-icon>
                        </v-btn>
                    </v-col>                    
                    <v-col cols="8">
                        <v-textarea v-model="testo"
                                      class="mt-1"
                                      v-on:keypress.native.enter="sendMessage"
                                      :label="testoNuovoMessaggio"
                                      outlined
                                      dense
                                      ref="testo"
                                      @blur="resetValidation"
                                      :rules="[v=>!!v || 'inserire testo']"
                                      prepend-inner-icon="mdi-message-text-outline">
                        </v-textarea>
                    </v-col>
                    <v-col cols="1">
                        <v-btn class="mt-2"
                               icon
                               :disabled="!valid"
                               @click="sendMessage">
                            <v-icon color="blue">mdi-send</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-snackbar v-model="snackError"
                            timeout="2000"
                            color="error">
                    <v-icon dark>
                        mdi-alert-circle
                    </v-icon>
                    Errore durante l'esecuzione dell'operazione.
                </v-snackbar>
            </v-form>
        </v-col>
        <v-file-input v-model="fileDocumento"
                      prepend-icon=""
                      id="idFileDocumento"
                      label="File Documento"
                      outlined
                      hide-input
                      type="file"
                      @change="upload"
                      dense>
        </v-file-input>
    </v-card>
</template>

<script>
    import { callService, callPost, callPostMultipart } from '@/modules/utilities.js'

    export default {
        data: () => ({
            valid: false,
            loading: false,
            contatto: [],
            listaContatti: [],
            ultimeChat: [],
            comunicazione:null,
            messaggi: [],
            messaggiAll: [],
            idOperatore: null,
            idCliente: null,
            fileDocumento: null,
            testo: '',
            timer: null,
            all: false,
            max: 5,
            snackError: false            
        }),
        async created() {
            
            this.$store.state.chatOpen=true;
            this.loading = true;

            let param = [];

            param =[];
            const professionisti = await callService('api/professionista/listaprofessionisticompleta',param);

            param =[];
            const operatori = await callService('api/operatore/listaoperatoricompleta',param);

            param = [this.$store.state.utente.operatore.id];
            const clienti = await callService('api/cliente/listaclientiapp',param);

            const listaContatti = [];

            listaContatti.push(
                {header: "Professionisti"}
            );

            for(const operatore of professionisti) {
                if(operatore.id==this.$store.state.utente.operatore.id) continue;
                listaContatti.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );
            };
           
            listaContatti.push(
                {header: "Operatori"}
            );           
            
            for(const operatore of operatori) {
                if (operatore.id == this.$store.state.utente.operatore.id) continue;
                listaContatti.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );                    
            };

            listaContatti.push(
                {header: "Clienti App"}
            );
            for(const cliente of clienti) {                
                listaContatti.push(
                    {
                      id: cliente.id,
                      nominativo: cliente.denominazioneNormalizzata,
                      ruolo: "Cliente",
                      avatar: cliente.avatarLink,
                      isAvatar: cliente.isAvatar,
                      inizialiNormalizzate: cliente.inizialiNormalizzate,
                    },
                );
            };
            
            this.listaContatti = listaContatti;
            param = [this.$store.state.utente.operatore.id];
            this.ultimeChat = await callService('api/chat/ultimeChat', param);
            //timer di refresh

            if (this.$route.query && this.$route.query.id) {
                this.contatto = this.$route.query.id;
                await this.change(this.contatto);
            }  

            this.timer = setInterval(this.checkRefresh, 5000);

            this.loading = false;

        },     
        beforeDestroy() {
            this.$store.state.chatOpen=false;
            this.cancelAutoUpdate();
        },
        methods: {
            resetValidation() {
                this.$refs.formSend.resetValidation();
            },
            async upload() {
                
                const formData = new FormData();
                formData.append('partition', this.$store.state.partition);
                formData.append('username', this.$store.state.utente.username);
                formData.append('token', this.$store.state.utente.token);
                formData.append('sessionID', this.$store.state.sessionID);
                formData.append('idOperatoreMittente', this.$store.state.utente.operatore.id);
                formData.append('idOperatoreDestinatario', !this.idOperatore ? "": this.idOperatore);
                formData.append('idClienteDestinatario', !this.idCliente ? "": this.idCliente);
                formData.append('fileDocumento', this.fileDocumento);
                const esito = await callPostMultipart('api/chat/upload', formData);

                this.fileDocumento = null;

                if (esito) this.snackSuccess = true;
                else this.snackError = true;

                if (esito) {
                    let id = this.idOperatore ? this.idOperatore : this.idCliente
                    await this.change(id);
                }                               
            },
            open(item) {                
                let fileDocumento = document.getElementById('idFileDocumento')
                fileDocumento.click();
            },
            cancelAutoUpdate() {
                clearInterval(this.timer);
            },
            remove(item) {
                this.contatto = [];
                this.messaggi = [];
                this.idCliente=null;
                this.idOperatore=null;
            },
            reset() {
                this.remove();
            },
            async checkRefresh(idCombo) {
                if (!idCombo && (this.contatto == null || this.contatto.length == 0)) return;
                let id = idCombo ? idCombo : (this.idCliente ? this.idCliente : this.idOperatore);
                let contatto = this.listaContatti.find(x => x.id === id);
                let messaggi = [];
                if (contatto.ruolo == "Operatore" || contatto.ruolo == "Professionista") {
                    this.idOperatore = id;
                    let param = [this.$store.state.utente.operatore.id, id, "N"];
                    messaggi = await callService('api/chat/listaMessaggiOperatore', param);                    
                }
                else {
                    this.idCliente = id;
                    let param = [this.$store.state.utente.operatore.id, id, "N"];
                    messaggi = await callService('api/chat/listaMessaggiCliente', param);   
                }
                //aggiorno se le dimensioni sono diverse o l'ultimo è stato letto
                if ((messaggi.length != this.messaggiAll.length)
                || (messaggi.length>0 && messaggi[messaggi.length-1].isLetto && !this.messaggiAll[this.messaggiAll.length-1].isLetto)) {
                    this.messaggiAll = messaggi;
                    if (this.all || messaggi.length <= this.max) {
                        this.messaggi = messaggi;
                    }
                    else {
                        let start = messaggi.length - this.max;
                        let stop = messaggi.length;
                        this.messaggi=messaggi.slice(messaggi.length - this.max, messaggi.length)
                    }
                    
                    //imposto lettura
                    let param = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.utente.username,
                        token: this.$store.state.utente.token,
                        sessionID: this.$store.state.sessionID,
                        idOperatoreMittente: this.$store.state.utente.operatore.id,
                        idOperatoreDestinatario: this.idOperatore,
                        idClienteDestinatario: this.idCliente
                    };
                    const result = await callPost('api/chat/impostaLettura', param);
                    this.$updaterHub.$emit('notifiche-refresh', {});
                    if (!result) {
                        this.snackError = true;                    
                    };
                    setTimeout(x => {
                        this.$nextTick(() => {
                            this.$refs.destinatari.focus();
                            this.$refs.testo.focus()
                        });
                    }, 200);
                }
            },
            async changeSwitch() {
                this.messaggi = [];
                this.messaggiAll = [];               
                this.checkRefresh();

                setTimeout(x => {
                    this.$nextTick(() => {
                        this.$nextTick(() => this.$refs.formSend.reset());
                    });
                }, 200);
            },
            async selectChat(item) {
                let param = [this.$store.state.utente.operatore.id,item.comunicazione.id];
                let output = await callService('api/chat/trovaChatter',param);
                let id=output.id;
                let contObj = this.listaContatti.find(x => x.id === id);
                this.contatto=contObj.id;
                await this.change(id);
            },
            async change(id) {
                this.messaggi = [];
                this.messaggiAll = [];
                this.checkRefresh(id);

                setTimeout(x => {
                    this.$nextTick(() => {
                        this.$nextTick(() => this.$refs.formSend.reset());                      
                    });
                }, 200);            
                              
            },
            async sendMessage() {
                if(!this.$refs.formSend.validate()) return;

                let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            sessionID: this.$store.state.sessionID,
                            idOperatoreMittente: this.$store.state.utente.operatore.id,
                            idOperatoreDestinatario: this.idOperatore,
                            idClienteDestinatario: this.idCliente,
                            testo: this.testo
                };

                await callPost('api/chat/inseriscimessaggio',param);
                let id = this.idOperatore ? this.idOperatore:this.idCliente
                await this.change(id);
            }
        },
        computed: {
            avatarLink() {
                return this.$store.state.utente.avatarLink;
            },
            nominativoUtente() {
                return this.$store.state.utente.operatore.denominazioneNormalizzata;
            },
            altIniziali() {
                return this.$store.state.utente.operatore.inizialiNormalizzate;
            },
            messaggiPresenti() {
                return this.messaggi != null && this.messaggi.length > 0;
            },
            hint() {
                if (this.contatto == null || this.contatto.length == 0) {
                    return "seleziona un contatto"
                }
                if (this.messaggi == null || this.messaggiAll.length == 0) {
                    return "non sono presenti messaggi precedenti"
                }
                return "sono presenti " + this.messaggiAll.length +" messaggi";
            },
            testoNuovoMessaggio() {
                let nomeContatto = this.listaContatti.find(x => x.id === this.contatto);
                return "Nuovo messaggio per " + nomeContatto.nominativo;
            }
        }
    }</script>
