<template>
    <v-container>

        <v-card class="ma-2" :loading="loading">
            <v-list-item three-line>
                <v-list-item-content>
                    <v-row>
                        <v-col cols="8">
                            <div class="text-h5 mb-4">
                                Incassi e Cassa contanti relativi al professionista <strong>{{professionista.denominazione}}</strong>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <v-select v-if="listavisibile" v-model="idProf"
                                        :items="listaOperatoriProfessionisti"
                                        item-value="professionista.id"
                                        item-text="denominazioneNormalizzata"
                                        label="Filtra per Professionista"
                                        outlined
                                        v-on:change="setProfessionista"
                                        dense></v-select>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>

            <template>
                <v-tabs v-model="tab">
                    <v-tab>Saldo Cassa: {{importoEuro(scheda1.saldoCassa)}} <br />Saldo Globale: {{importoEuro(scheda1.saldoGlobale)}}</v-tab>
                    <v-spacer></v-spacer>
                    <v-text-field class="mt-2 mx-12"
                                  v-model="dataDa"
                                  label="Dal giorno"
                                  outlined
                                  persistent-hint
                                  type="date"
                                  @change="changeSearch"                                  
                                  dense>
                    </v-text-field>
                    <v-text-field class="mt-2"
                                  v-model="dataA"
                                  label="Al giorno"
                                  outlined
                                  persistent-hint
                                  type="date"
                                  @change="changeSearch"                                  
                                  dense>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-2 mr-2" color="teal" dark
                           @click="viewPAG(false)">
                        Rabbocco
                    </v-btn>
                    <v-btn class="mt-2 mr-2" color="orange darken-2" dark
                           @click="viewPAG(true)">
                        Prelievo
                    </v-btn>

                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-data-table no-data-text="Non sono presenti movimenti"
                                      :headers="headers1"
                                      :items="scheda1.movimenti"
                                      :items-per-page="nPag"
                                      @pagination="pagination"
                                      :custom-filter="customFilter"
                                      :page="pageCassa"
                                      :search="search"
                                      class="elevation-1"
                                      :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,10,20,-1]
                                          }">
                            <template v-slot:item.data="{ item }"> 
                                <span v-text="localData(item.data)" />
                            </template>
                            <template v-slot:item.dareCassa="{ item }">
                                <span v-if="item.dareCassa != 0" class="deep-orange--text text--darken-4" dark>
                                    <b>{{importoEuro(item.dareCassa)}}</b>
                                </span>
                            </template>
                            <template v-slot:item.avereCassa="{ item }">
                                <span v-if="item.avereCassa != 0" :class="item.isCassa ? 'amber--text':'teal--text'" dark>
                                    <b>{{importoEuro(item.avereCassa)}}</b>
                                </span>
                            </template>
                            <template v-slot:item.averePos="{ item }">
                                <span v-if="item.averePos != 0" class="teal--text" dark>
                                    <b>{{importoEuro(item.averePos)}}</b>
                                </span>
                            </template>
                            <template v-slot:item.avereBonifico="{ item }">
                                <span v-if="item.avereBonifico != 0" class="teal--text" dark>
                                    <b>{{importoEuro(item.avereBonifico)}}</b>
                                </span>
                            </template>
                            <template v-slot:item.saldoCassa="{ item }">
                                <span :class="item.saldoCassa<0 ? 'deep-orange--text text--darken-4':'deep-purple--text'" dark>
                                    <b>{{importoEuro(item.saldoCassa)}}</b>
                                </span>
                            </template>
                            <template v-slot:item.saldoGlobale="{ item }">
                                <span :class="item.saldoGlobale<0 ? 'deep-orange--text text--darken-4':'indigo--text text--darken-3'" dark>
                                    <b>{{importoEuro(item.saldoGlobale)}}</b>
                                </span>
                            </template>
                            <template v-slot:item.elimina="{ item }">
                                <div v-if="item.isCassa && !$store.state.utente.isReception">
                                    <v-btn icon @click="elimina(item)">
                                        <v-icon class="ml-3" color="error darken-2">mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </div>

                                <div v-else-if="item.isCassa && $store.state.utente.isReception">
                                    <v-icon class="ml-3" color="gray">mdi-trash-can-outline</v-icon>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>

            </template>

            <template>
                <v-dialog v-model="dialogPAG" persistent
                          max-width="800">
                    <v-stepper v-model="pag">
                        <v-stepper-header>
                            <v-stepper-step :complete="pag > 1"
                                            step="1">
                                Dati {{titoloPag}}
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step :complete="pag > 2"
                                            step="2">
                                Riepilogo
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step step="3">
                                Esito
                            </v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-container class="mb-12">
                                    <v-form ref="formPAG"
                                            v-model="validPAG">
                                        <v-row>
                                            <v-col cols="4" offset="4">
                                                <v-text-field v-model="dataPagamento"
                                                              label="Data pagamento"
                                                              outlined
                                                              :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                              hint="gg/mm/aaaa"
                                                              persistent-hint
                                                              clearable
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" offset="4">
                                                <vuetify-money v-model="importoPagamento"
                                                               :label="'Importo ' + this.titoloPag"
                                                               outlined
                                                               :background-color="theme ? 'gray':'white'"
                                                               dense
                                                               :rules="[v => !!v || 'inserire importo', v => (importoPagamento && importoPagamento>0) || 'inserire importo >0']"
                                                               :options="moneyOptions">
                                                </vuetify-money>
                                            </v-col>
                                            <v-col cols="8" offset="2">
                                                <v-text-field v-model="descrizioneAbbuono" class="mt-4"
                                                              label="Descrizione"
                                                              outlined
                                                              :rules="[v=> (!!v && v.length>0) || 'inserire la descrizione del movimento cassa']"
                                                              dense>
                                                </v-text-field>
                                                <v-text-field v-model="noteAbbuono" class="mt-4"
                                                              label="Note"
                                                              outlined
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                                <v-row>
                                    <v-col cols="2">
                                        <v-btn :disabled="!validPAG" color="primary" depressed
                                               @click="pag = 2">
                                            Avanti
                                        </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-btn @click="dialogPAG = false" color="warning">
                                            Annulla
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-container class="mb-12">
                                    <v-row>
                                        <v-col cols="6" offset="1">
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-format-list-bulleted-type</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{dataPagamento}}</v-list-item-title>
                                                    <v-list-item-subtitle>data</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-cash</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{importoPagamentoEuro}}</v-list-item-title>
                                                    <v-list-item-subtitle v-text="'Importo ' + this.titoloPag"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-account-multiple</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{professionista.denominazione}}</v-list-item-title>
                                                    <v-list-item-subtitle>Professionista</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="10" offset="1">
                                            <v-list-item two-line>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-note-text-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6">{{descrizioneAbbuono}}</v-list-item-title>
                                                    <v-list-item-subtitle v-html="(noteAbbuono ? noteAbbuono: '-')"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-row>
                                    <v-col cols="2">
                                        <v-btn color="primary"
                                               @click="pag = 1">
                                            Indietro
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn color="success" depressed
                                               @click="sendPAG">
                                            Registra
                                            <v-icon right>
                                                mdi-cash-register
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-btn @click="dialogPAG = false" color="warning">
                                            Annulla
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <v-container class="mb-12" align="center">
                                    <v-col cols="6" offset="3">
                                        <v-card v-if="loadingPAG" color="primary"
                                                dark>
                                            <v-card-text>
                                                Esecuzione Operazione...
                                                <v-progress-linear indeterminate
                                                                   color="white"
                                                                   class="mb-0">
                                                </v-progress-linear>
                                            </v-card-text>
                                        </v-card>
                                        <v-card v-if="!loadingPAG && esitoPAGOK" color="success"
                                                dark>
                                            <v-card-text>
                                                <v-icon dark>
                                                    mdi-checkbox-marked-circle
                                                </v-icon>
                                                Operazione eseguita correttamente
                                            </v-card-text>
                                        </v-card>
                                        <v-card v-if="!loadingPAG && !esitoPAGOK" color="error"
                                                dark>
                                            <v-card-text>
                                                <v-icon dark>
                                                    mdi-alert-circle
                                                </v-icon>
                                                Errore durante l'esecuzione dell'operazione
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                </v-container>

                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-btn @click="dialogPAG = false"
                                               color="warning"
                                               :disabled="loadingPAG">
                                            Chiudi
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-dialog>
            </template>

            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Modifiche annullate.
            </v-snackbar>
            <v-snackbar v-model="snackRemote"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Aggiornamento remoto.
            </v-snackbar>


        </v-card>

        <msg-dialog />

    </v-container>

</template>


<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, gControllData, Snack } from '@/modules/utilities.js'

    import { GChart } from 'vue-google-charts/legacy'
    import MsgDialog  from '@/views/components/MsgDialog.vue'

    import { bus } from '@/main.js'

    export default {
        components: {
            GChart, MsgDialog
        },
        data: () => ({
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            tab: null,
            dataA: '',
            dataDa: '',
            search:'',
            professionista: {
                id: ''
            },
            idProf: '',
            tipiPagamento:[],
            scheda: {
                datiGrafico: null
            },
            scheda1: {
                datiGrafico: null
            },
            loading: true,
            hCard: 200,
            headers1: [
                {
                    text: 'Data',
                    align: 'start',
                    sortable: true,
                    value: 'data',
                },
                { text: 'Cliente', value: 'cliente', sortable: false, width: '30%' },
                { text: 'Entrate: Contanti, Assegni e Rabbocchi', value: 'avereCassa', sortable: false},
                { text: 'Uscite: Prelievi', value: 'dareCassa', sortable: false },
                { text: 'Saldo cassa', value: 'saldoCassa', sortable: false },
                { text: 'POS/App', value: 'averePos', sortable: false },
                { text: 'Bonifici', value: 'avereBonifico', sortable: false },
                { text: 'Saldo globale', value: 'saldoGlobale', sortable: false },
                { text: '', value: 'elimina', sortable: false },
            ],
            nPag:8,
            abbuono: false,
            pag: 1,
            pageCassa:1,
            dialogPAG: false,
            oggetto:'',
            testo:'',
            validPAG: false,
            loadingPAG: false,
            esitoPAGOK: false,
            dataPagamento:'',
            importoPagamento: 0,
            descrizioneAbbuono: '',
            listavisibile: true,
            listaOperatoriProfessionisti: [],
            noteAbbuono: '',
            idTipoPagamento: '',
            theme:'',
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },                                 
            chartOptions: {
                height: 120,
                legend: { position: 'none' },
                backgroundColor: { fill:'transparent' },
                vAxis: { format: '€ #' }
            },            
        }),
        methods: {           
            importoEuro(v) {   
                return euro(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            controllData(v) {
                return gControllData(v);
            },
            dataCalendar(v) {
                return gDataCalendar(v);
            },
            viewPAG(abb) {
                this.abbuono = abb;
                this.pag=1;
                this.loadingPAG=false;
                this.dialogPAG=true;
                this.$nextTick(() => {
                    this.$refs.formPAG.resetValidation();
                    this.$refs.formPAG.reset();
                    this.$nextTick(() => {
                        this.dataPagamento = this.localData(new Date());
                    });  
                });                
            },
            changeSearch() {
                this.searchState =
                    [this.dataDa,
                    this.dataA
                    ];
                    this.search=this.searchState.toString();
            },
            customFilter(value, search, item) {
                console.log(search);
                let ok = true;
                //dataCalendar trasforma le date UTC in data italiana e poi inverte per fare il confronto tra stringhe
                if (ok && this.dataDa) {
                    ok = this.dataCalendar(item.data) >= this.dataCalendar(this.dataDa);
                }
                if (ok && this.dataA) {
                    ok = this.dataCalendar(item.data) <= this.dataCalendar(this.dataA);
                }
                return ok;
            },
            setProfessionista(idProf) {
                this.internalCreated(Snack.nothing);
            },
            async sendPAG() {
                this.loadingPAG = true;
                this.pag = 3;

                //imposto lettura

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idProfessionista: this.professionista.id,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    data: this.serverData(this.dataPagamento),
                    importo: this.importoPagamento,
                    isAbbuono: this.abbuono,
                    descrizioneCassa: this.descrizioneAbbuono,
                    noteCassa: this.noteAbbuono
                };
                        
                const result = await callPost('api/cassa/movimento', param);
                        
                this.esitoPAGOK = result;
                if (result) {
                    await this.internalCreated(Snack.none);
                }               
                this.loadingPAG = false;
            },
            async eliminaCancel(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);
            },
            async eliminaOk(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idProfessionista: this.professionista.id,
                    id: item.id,
                    isDare: item.isDare,
                    isAvere: item.isAvere,
                    isAbbuono: item.isAbbuono
                };

                this.loading = true;

                const result = await callPost('api/cassa/eliminaMovimento', param);

                if (result) {
                    await this.internalCreated(Snack.success);
                }
                else {
                    await this.internalCreated(Snack.error);
                }
                this.loading = false;
            },
            async elimina(item) {
                bus.$emit('msg-open', { message: "Eliminare il movimento contabile?", item });                
                bus.$on('msg-cancel', this.eliminaCancel); 
                bus.$on('msg-ok', this.eliminaOk);
            },            
            async onProfessionistaChanged({ lista, sessionID }) {
                if (sessionID == "-1" || sessionID == this.$store.state.sessionID) return;

                if (lista.find(cod => cod == this.professionista.id)) {
                    await this.internalCreated(Snack.remote);
                }
            },
            async internalCreated(snack) {
                //se sono un professionista allora carico solo me stesso direttamente e nascondo la combo

                this.loading = true;

                let param = [this.idProf];
                this.professionista = await callService("api/professionista/professionista", param);

                param = [this.idProf, this.$store.state.idAnnoContabile];
                //this.scheda = await callService('api/cassa', param);
                this.scheda1 = await callService('api/movimenti', param);

                this.dataA = this.controllData(new Date());
                var d = new Date();
                d.setDate(d.getDate() - 2);
                this.dataDa = this.controllData(d);
                this.search = "go";
                this.pageCassa = Math.ceil(this.scheda1.movimenti.length/this.nPag);

                //esegue il filtro

                this.loading = false;

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },    
            pagination(pag) {
                let nPag = pag.itemsPerPage;
                this.$cookie.set('cassa-pagination', nPag.toString(), { expires: '1Y' });
            } 
        },
        computed: {
            chartData() {
                if (this.scheda==null) return null;
                return this.scheda.datiGrafico;
            },
            chartData1() {
                if (this.scheda1 == null) return null;
                return this.scheda1.datiGrafico;
            },
            importoPagamentoEuro() {
                return euro(this.importoPagamento);
            },
            descrizionePagamento(){
                let p = this.tipiPagamento.find(x => x.id === this.idTipoPagamento);
                if(p) return p.descrizione;
                else return "";
            },
            titoloPag() {
                return this.abbuono ? 'Uscita' : 'Incasso';
            },
            isServicePro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isPulizia || this.$store.state.lplApp.isChiamata || this.$store.state.lplApp.isManutenzione);
            },
            isStudioPro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isLavoro || this.$store.state.lplApp.isContabilita);
            }             
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.nPag = Number(this.$cookie.get('cassa-pagination')); //leggo il coockie della paginazione
            this.theme = theme;
            //this.$updaterHub.$on('cliente-changed', this.onClienteChanged)
            //bus.$on('cliente-changed', this.onClienteChanged)
            let param = [];
            this.tipiPagamento = await callService("api/tipopagamento", param);
            this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;

            if (this.$store.state.utente.operatore.isProfessionista && this.$store.state.utente.isGestoreStudio) {
                    this.idProf = this.$store.state.utente.operatore.professionista.id;
                    this.listavisibile = true;
            }
            else if (this.$store.state.utente.operatore.isProfessionista) {
                this.idProf = this.$store.state.utente.operatore.professionista.id;
                this.listavisibile = false;
            }
            else {
                this.idProf = this.listaOperatoriProfessionisti[0].professionista.id;
                this.listavisibile = true;
            }

            if (this.isServicePro) this.listavisibile = false;


            await this.internalCreated(Snack.nothing);


        },
        beforeDestroy() {
            //this.$updaterHub.$off('utente-changed', this.onUtenteChanged);
            //bus.$off('utente-changed', this.onUtenteChanged)
        }
    }</script>
