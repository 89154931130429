<template>
    <v-dialog v-model="dialog" persistent max-width="550px">
        <v-card>
            <v-card-title class="text-subtitle-1 grey lighten-2">
                Invio Mail {{title}}
            </v-card-title>
            <v-card-text>
                <br />
                <v-text-field v-model="mail"
                              label="Indirizzo e-mail"
                              placeholder="Indirizzo e-mail"
                              outlined
                              dense>
                </v-text-field>
                <v-text-field v-model="label"
                              label="Oggetto"
                              placeholder="Oggetto"
                              outlined
                              dense>
                </v-text-field>
                <v-text-field v-model="message"
                              label="testo della mail"
                              placeholder="Testo della mail"
                              outlined
                              dense>
                </v-text-field>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="deep-orange darken-4" dark
                       @click="onOk">
                    {{okTxt}}
                </v-btn>
                <v-btn color="teal" dark
                       @click="onAnnulla">
                    {{cancelTxt}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import { bus } from '@/main.js'

    export default {
        data: () => ({
            dialog: false,
            label: '',
            message: '',
            value: '',
            title: '',
            item: {},
            mail: [],
            okTxt: 'Ok',
            cancelTxt:'Annulla' 
        }),
        methods: {                      
            onOpen({ message, label, value, item, okTxt, cancelTxt, title, mail }) {
                this.message = message;
                this.label = label;
                this.item = item;
                this.value = value;
                this.title = title;
                this.mail = mail;

                //alert(JSON.stringify(mail));

                if(okTxt) this.okTxt=okTxt;
                else this.okTxt ='Ok';

                if(cancelTxt) this.cancelTxt=cancelTxt;
                else this.cancelTxt = 'Annulla';

                this.dialog = true;
            },
            onOk() {
                bus.$emit('msg-mail-ok', this.item, this.value, this.mail, this.message);
                this.dialog = false;
            },
            onAnnulla() {
                bus.$emit('msg-mail-cancel', this.item);
                this.dialog = false;
            },
        },
        async created() {           
            bus.$on('msg-mail-open', this.onOpen)            
        },
        beforeDestroy() {
            bus.$off('msg-mail-open', this.onOpen);
        }
    }

</script>

