<template>
    <v-dialog v-model="dialog" persistent max-width="1500px">

        <v-card :loading="loading">
            <v-card-text>
                <v-form ref="form"
                        v-model="valid"
                        class="mt-4 ml-4 mr-4"
                        lazy-validation>
                    <v-tabs>
                        <v-tab>Anagrafica</v-tab>
                        <v-tab-item>
                            <v-row class="mt-4">
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.denominazione"
                                                  label="Denominazione"
                                                  outlined
                                                  dense
                                                  required
                                                  :rules="[v=> v!=null || v.length>0 || 'inserire la denominazione']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="1">
                                    <v-switch class="mt-0 mx-4"
                                              color="green"
                                              v-model="partizione.isTest"
                                              label="Test">
                                    </v-switch>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.partition"
                                                  label="Codice"
                                                  disabled
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.webAppBaseLink"
                                                  label="Host link"
                                                  disabled
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.appID"
                                                  label="Base dati"
                                                  outlined
                                                  disabled
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-select v-model="partizione.agente.id"
                                              :items="listaAgenti"
                                              item-value="id"
                                              item-text="denominazioneNormalizzata"
                                              label="Agente"
                                              outlined
                                              dense>
                                    </v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.codiceFiscale"
                                                  label="Codice Fiscale"
                                                  outlined
                                                  dense
                                                  required
                                                  @keyup="uppercaseCFLR"
                                                  :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v) || 'inserire 11 cifre o 16 caratteri']">
                                    </v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <v-text-field v-model="partizione.partitaIVA"
                                                  label="Partita IVA"
                                                  outlined
                                                  dense
                                                  required
                                                  :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || 'inserire 11 cifre']">
                                    </v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <v-text-field v-model="partizione.dataScadenza"
                                                  label="Data scadenza licenza"
                                                  outlined
                                                  :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                  hint="gg/mm/aaaa"
                                                  persistent-hint
                                                  clearable
                                                  dense></v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <v-switch class="mt-0 mx-12"
                                              v-model="isContabilita"
                                              readonly
                                              label="Contabilita`">
                                    </v-switch>
                                </v-col>

                                <v-col cols="2">
                                    <v-switch class="mt-0 mx-10"
                                              color="red"
                                              readonly
                                              v-model="isLavoro"
                                              label="Lavoro">
                                    </v-switch>
                                </v-col>

                                <v-col cols="2">
                                    <v-switch class="mt-0 mx-8"
                                              color="green"
                                              readonly
                                              v-model="isPulizia"
                                              label="Servizi">
                                    </v-switch>
                                </v-col>

                            </v-row>


                            <v-row>
                                <v-col cols="3">
                                    <v-text-field v-model="partizione.mail"
                                                  label="Mail"
                                                  outlined
                                                  dense
                                                  :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.telefono"
                                                  label="Telefono"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <v-text-field v-model="partizione.pec"
                                                  label="PEC"
                                                  outlined
                                                  required
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.sdi"
                                                  label="SDI"
                                                  outlined
                                                  @keyup="uppercaseSDI"
                                                  dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="3">
                                    <v-text-field v-model="partizione.web"
                                                  label="Sito cliente"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="2">
                                    <v-text-field v-model="partizione.nProfessionisti"
                                                  label="Professionisti"
                                                  outlined
                                                  persistent-hint
                                                  :rules="[v=> v>0 && /^[0-9]*$/.test(v) || 'valore numerico >0']"
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.nOperatori"
                                                  label="Operatori"
                                                  outlined
                                                  persistent-hint
                                                  :rules="[v=> v>0 && /^[0-9]*$/.test(v) || 'valore numerico >0']"
                                                  dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <v-text-field v-model="partizione.nClienti"
                                                  label="Clienti"
                                                  outlined
                                                  persistent-hint
                                                  :rules="[v=> v>0 && /^[0-9]*$/.test(v) || 'valore numerico >0']"
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="partizione.spazioWeb"
                                                  label="Spazio Web (in Mb)"
                                                  outlined
                                                  :rules="[v=> v>499 && /^[0-9]*$/.test(v) || 'valore numerico >499']"
                                                  persistent-hint
                                                  dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="2">
                                    <vuetify-money v-model="partizione.canone"
                                                   label="Canone annuo"
                                                   outlined
                                                   persistent-hint
                                                   :rules="[v=> !!v || 'inserire importo']"
                                                   :options="moneyOptions"
                                                   dense>
                                    </vuetify-money>
                                </v-col>
                                <v-col cols="2">
                                    <vuetify-money v-model="partizione.saldo"
                                                   label="saldo"
                                                   outlined
                                                   readonly
                                                   persistent-hint
                                                   dense>
                                    </vuetify-money>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-spacer></v-spacer>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="valid"
                               @click="salva"
                               :loading="loadingSalva"
                               :disabled="!valid">
                            Salva
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="orange darken-2" dark
                               :loading="loadingAnnulla"
                               @click="annulla">
                            Annulla modifiche
                        </v-btn>
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="dialog = false">
                            Chiudi
                        </v-btn>
                    </v-tabs>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
        <v-snackbar v-model="snackErrorOp"
                    timeout="2000"
                    color="error"
                    centered>
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore: superato limite operatori
        </v-snackbar>
        <v-snackbar v-model="snackErrorProf"
                    timeout="2000"
                    color="error"
                    centered>
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore: superato limite professionisti
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'
    import { bus } from '@/main.js'


    export default {
        data: () => ({
            dialog: false,
            valid: true,
            nuovo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            snackErrorOp: false,
            snackErrorProf: false,
            dialogDelete: false,
            loading: false,
            loadingSalva: false,
            loadingAnnulla: false,
            isContabilita: false,
            isLavoro: false,
            isPulizia: false,
            id: '',
            partizione: {
                id: '',
                agente: {
                    id: ''
                },
            },
            moneyOptions: {
                locale: "it-IT",
                prefix: "�",
                suffix: "",
                length: 10,
                precision: 2
            },
            listaAgenti: [],
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" });
            }            
        },
        computed: {

        },
        methods: {
            //logout() {
            //    this.$store.dispatch('logout');
            //    this.$store.dispatch('setUtente', null);
            //    this.$router.replace({ name: "login" });
            //},
            uppercaseCFLR() {
                this.partizione.codiceFiscale = this.partizione.codiceFiscale.toUpperCase();
            },
            uppercaseSDI() {
                this.partizione.sdi = this.partizione.sdi.toUpperCase();
            },
            checkData(d) {
                var tok = d.split("/");
                var dIso = new Date([tok[2],tok[1],tok[0]].join("-"));
                return !isNaN(dIso);
            },
            localData(stringDate) {
                if (!stringDate) return null;
                var d = new Date(stringDate);
                let month = (d.getMonth() + 1).toString();
                let day = d.getDate().toString();
                let year = d.getFullYear();
                if (month.length < 2) {
                    month = '0' + month;
                }
                if (day.length < 2) {
                    day = '0' + day;
                }
                return [day, month, year].join('/');                
            },
            importoEuro(v) {
                return euro(v);
            },
            changePersona(persona) {
                //if (persona) {
                //    this.idPeriodicitaContabile = 0;
                //    this.tariffeContabilitaCliente = [];
                //}
                //else {
                //    //vorrei chiamare la funzione con la periodicit� di default
                //    this.idPeriodicitaContabile = 1;
                //    this.changePerdiodicitaContabile();
                //}
            },
            serverData(stringDate) {
                //da gg/mm/aaaa a aaaa-mm-gg//

                if (!stringDate) return null;
                var tok = stringDate.split("/");                
                return [tok[2], tok[1], tok[0]].join('/');                
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);    
                this.loadingAnnulla = false;
            },            
            async onPartizioneChanged({ lista, sessionID }) {
                if (sessionID == "-1" || sessionID == this.$store.state.sessionID) return;

                if (lista.find(cod => cod == this.partizione.id)) {
                    await this.internalCreated(Snack.remote);
                }
            }, 
            async salva() {
                if (!this.$refs.form.validate()) return;
                
                //preparazione dati
                this.partizione.isContabilita = this.isContabilita;
                this.partizione.isLavoro = this.isLavoro;
                this.partizione.isPulizia = this.isPulizia;
                

                let partizione = JSON.parse(JSON.stringify(this.partizione));
                partizione.dataScadenza = this.serverData(partizione.dataScadenza);



                let paramJson={
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    partizione: partizione
                };

                this.loadingSalva = true;
                let ute = await callPost('api/lplapp/save', paramJson);

                if (ute) {
                    this.partizione.id = ute.id;
                    this.nuovo = false;
                    bus.$emit('lplapp-changed-bus', { lista: [this.partizione.id], sessionID: "-1" });
                    bus.$emit('lplapp-snack-succes',{});
                    this.loadingSalva = false;  
                    this.dialog = false;
                }
                else {
                    await this.internalCreated(Snack.error);
                    this.loadingSalva = false;   
                }

            },
            async internalCreated(snack) {
                let param=[];
                this.listaAgenti = await callService('api/agente/lista', param);
           
                param = [this.partizione.id];
                let partizione = await callService("api/lplapp/partizione", param);


                partizione.dataScadenza = this.localData(partizione.dataScadenza);

                this.isContabilita = partizione.isContabilita;
                this.isLavoro = partizione.isLavoro;
                this.isPulizia = partizione.isPulizia;

                if (!partizione.agente) partizione.agente = { id: '' };

                this.partizione = partizione;



                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
           
        },
        async created() {           

            this.$updaterHub.$on('lplapp-changed', this.onAgenteChanged)
            var vm = this;

            bus.$on('dialog-show-lplapp', async function (partizione) {
                vm.dialog = true;
                vm.loading = true;
                vm.nuovo = partizione.id == "0";
                vm.partizione.id = partizione.id;
                await vm.internalCreated(Snack.nothing);
                vm.loading = false;
            });   
        },
        beforeDestroy() {
            this.$updaterHub.$off('lplapp-changed', this.onAgenteChanged);
            this.$updaterHub.$off('dialog-show-lplapp');
        }
    }

</script>

