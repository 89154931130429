<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="5">
                                        <v-text-field v-model="searchText"
                                                      append-icon="mdi-magnify"
                                                      label="Ricerca una tariffa"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @input="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-select v-model="idTipoConsulenza"
                                                  :items="listaConsulenze"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Filtra per Tipo Consulenza"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-select v-model="idTipoPeriodicita"
                                                  :items="listaPeriodicita"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Filtra per Periodicità"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-data-table v-model="selezione"
                                          :headers="headers"
                                          :items="tariffe"
                                          ref="tabella"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          progress="78"
                                          class="elevation-1"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                          show-select
                                          @toggle-select-all="toggleAll"
                                          @item-selected="toggleSelect"
                                          @current-items="filterItems"
                                          @pagination="pagination"
                                          :single-select="false"
                                          :search="search"
                                          :custom-filter="customFilter"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessuna tariffa presente in archivio"
                                          no-results-text="Nessuna tariffa corrispodende ai criteri di ricerca">
                                <template v-slot:item.azioni="{item}">
                                    <v-row>
                                        <v-col class="mx-n4">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-n4" icon @click="anagrafica(item)"
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon color="blue darken-2">
                                                            mdi-calculator-variant-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Anagrafica Tariffa</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col class="mx-n4">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-n4" icon @click="duplica(item)"
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon color="orange darken-2">
                                                            mdi-arrange-bring-forward
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Duplica Tariffa (per tutte le periodicità standard, se necessario)</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </template>


                                <template v-slot:item.tipoServizio.isOscurato="{ item }">
                                    <v-tooltip bottom v-if="item.tipoServizio.isOscurato">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="blue"
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   @click="abilita(item,true)">
                                                <v-icon color="error darken-2">mdi-tag-off</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Abilita tariffa</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else-if="!item.tipoServizio.isOscurato">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="blue"
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   @click="abilita(item,false)">
                                                <v-icon color="green darken-2">mdi-tag</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Disabilita tariffa</span>
                                    </v-tooltip>
                                    <!--<v-tooltip bottom v-else-if="!item.tipoServizio.isOscurato && !item.isCancellabile">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="grey"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon color="gray">mdi-tag</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Tariffa non disabilitabile</span>
                                    </v-tooltip>-->
                                </template>

                                <template v-slot:item.elimina="{ item }">
                                    <v-tooltip bottom v-if="item.tipoServizio.isSistema || !item.isCancellabile">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="grey"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon color="grey">mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Tariffa non eliminabile (di sistema o utilizzata)</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else-if="item.isCancellabile">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="red"
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   @click="elimina(item)">
                                                <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Elimina tariffa</span>
                                    </v-tooltip>
                                </template>

                                <template v-slot:item.tipoServizio.isSpot="{ item }">
                                    <v-tooltip bottom v-if="item.tipoServizio.isSpot && item.tipoServizio.tipoPeriodicita">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="grey"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon color="green">mdi-timer-edit-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Tariffa addebitabile senza restrizioni</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else-if="item.tipoServizio.isSpot && !item.tipoServizio.tipoPeriodicita">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="grey"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon color="orange">mdi-timer-edit-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Tariffa addebitabile solo come Addebito Spesa selezionando un singolo cliente</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="red"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon color="error darken-2">mdi-timer-lock-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Tariffa addebitabile solo nelle procedure massive</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <tariffa-dialog />
        
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>


    </v-container>   

</template>




<script>
    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'
    import TariffaDialog from '@/views/components/TariffaDialog.vue'
    import { bus } from '@/main.js'

    export default {
                components: {
                    TariffaDialog
                }, 
                data: () => ({
                    selezione: [],
                    filtro: [],
                    statoAll: false,
                    loading: true,
                    showError: false,
                    viewMenuUtente: false,
                    snackSuccess: false,
                    snackError: false,
                    snackCancel: false,
                    snackRemote: false,
                    x: 0,
                    y: 0,
                    nPag: 8,
                    search: '',
                    searchText: '',
                    tariffe: [],
                    listaConsulenze: [],
                    listaPeriodicita: [],
                    tariffa: {
                        id: ''
                    },
                    headers: [
                        { text: 'Azioni', value: 'azioni', sortable: false, width: '10%', align: 'center' },
                        { text: 'Tipo Consulenza', value: 'tipoServizio.tipoConsulenza.descrizione' },
                        { text: 'Tipo Periodicità', value: 'tipoServizio.tipoPeriodicita.descrizione' },
                        { text: 'Descrizione', value: 'tipoServizio.descrizione' },
                        { text: 'Utilizzabile', value: 'tipoServizio.isOscurato', align: 'center' },
                        { text: 'Spot', value: 'tipoServizio.isSpot', align: 'center' },
                        { text: 'Elimina', value: 'elimina', align: 'center' },
                    ],
                    conteggioUtenti: [],
                    idTipoPeriodicita: '',
                    notifica: true,
                    idTipoConsulenza: '',
                    notifica1: true,
                    notifica2: true,
                    moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
                    }
                }),
                methods: {
                    localData(v) {
                        return gLocalData(v);
                    },
                    importoEuro(v) {
                        return euro(v);
                    },
                    descrizioneApp(v) {
                        if (v == false) return "Mail/Telefono";
                        return "App StudioPro";
                    },
                    descrizioneRisposta(v) {
                        if (v == false) return "Non autorizzata";
                        return "Autorizzata";
                    },
                    searchChange(){
                        this.selezione=[];
                        this.$store.dispatch('setStatoAll',false);
                        this.$store.dispatch('setSelezione',this.selezione);
                        this.$store.dispatch('setSearch',this.search);
                    },
                    async elimina(item) {
                        bus.$emit('msg-open', { message: "Eliminare la tariffa?", item });
                        bus.$on('msg-cancel', this.eliminaCancel);
                        bus.$on('msg-ok', this.eliminaOk);
                    }, 
                    async eliminaCancel(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);
                    },
                    async eliminaOk(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);

                        let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            sessionID: this.$store.state.sessionID,
                            id: item.tipoServizio.id
                        };

                        this.loading = true;

                        const result = await callPost('api/tariffaCliente/eliminaTariffa', param);

                        if (result) {
                            await this.internalCreated(Snack.success);
                        }
                        else {
                            await this.internalCreated(Snack.error);
                        }
                        this.loading = false;
                    },
                    filterItems(v){
                        this.$nextTick(() => {
                            this.filtro=this.$refs.tabella.$children[0].filteredItems;
                        });
                    },
                    anagrafica(item) {
                        this.tariffa = JSON.parse(JSON.stringify(item.tipoServizio));
                        //alert(this.tariffa.id);
                        bus.$emit('dialog-show-tariffa', this.tariffa);
                    },  
                    nuovo() {
                        this.tariffa.id = "0";
                        bus.$emit('dialog-show-tariffa', this.tariffa);
                    },
                    toggleAll() {
                        this.statoAll = !this.statoAll;
                        this.$store.dispatch('setStatoAll',this.statoAll);

                        if(this.statoAll) {
                            this.selezione=this.filtro;
                        }
                        else {
                            this.selezione=[];
                        };
                        this.$store.dispatch('setSelezione',this.selezione);
                    },
                    toggleSelect() {
                        this.$nextTick(() => {
                            this.$store.dispatch('setSelezione',this.selezione);
                        });
                    },
                    async duplica(item) {
                        this.tariffa = JSON.parse(JSON.stringify(item.tipoServizio));
                        //alert(this.tariffa.id);
                        this.tariffa.id = '-' + this.tariffa.id;
                        bus.$emit('dialog-show-tariffa', this.tariffa);
                    },
                    async onTariffaChanged({ lista, sessionID }) {
                        //if (sessionID == this.$store.state.sessionID) return;
                        //for (var i = 0; i < lista.length; i++) {

                        //    let param = [lista[i]];

                        //    let tariffa = await callService("api/tariffaCliente/tariffaEstesa", param);

                        //    //cerco nella lista la tariffa ricalcolata

                        //    const index = this.tariffe.findIndex(u => {
                        //        return u.tipoServizio.id === tariffa.tipoServizio.id;
                        //    });

                        //    if (index >= 0) {
                        //        this.tariffe.splice(index, 1, tariffa);
                        //    }
                        //    else {
                        //        this.tariffe.push(tariffa);
                        //    }
                        //}
                        await this.internalCreated(Snack.nothing);
                    },
                    async abilita(item, value) {
                        bus.$emit('msg-open', { message: value ? "Abilitare la tariffa?" : "Disabilitare la tariffa?", item });
                        bus.$on('msg-cancel', this.abilitaCancel);
                        if (value) bus.$on('msg-ok', this.abilitaOk);
                        else bus.$on('msg-ok', this.disabilitaOk);
                    },
                    async abilitaCancel(item) {
                        bus.$off('msg-cancel', this.abilitaCancel);
                        bus.$off('msg-ok', this.abilitaOk);
                        bus.$off('msg-ok', this.disabilitaOk);
                    },
                    async abilitaOk(item) {
                        await this.setStatoTariffa(item, true);
                    },
                    async disabilitaOk(item) {
                        await this.setStatoTariffa(item, false);
                    },
                    async setStatoTariffa(item, value) {
                        bus.$off('msg-cancel', this.abilitaCancel);
                        bus.$off('msg-ok', this.abilitaOk);

                        this.tariffa = JSON.parse(JSON.stringify(item.tipoServizio));

                        let paramJson = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            tipoServizio: this.tariffa,
                            abilita: value
                        };

                        this.loading = true;

                        let result = await callPost('api/tariffaCliente/abilitatiposervizio', paramJson);

                        if (result) {
                            bus.$emit('tariffa-changed', { lista: [this.tariffa.id], sessionID: "-1" });
                            this.snackSuccess = true;
                        }
                        else {
                            this.snackError = true;
                        }
                        this.loading = false;
                    },
                    onSnackSuccess() {
                        this.snackSuccess = true;
                    },
                    pagination(pag) {
                        let nPag = pag.itemsPerPage;
                        this.$cookie.set('tariffe-pagination', nPag.toString(), { expires: '1Y' });
                    },
                    async internalCreated(snack) {
                        let param = [];
                        this.tariffe = await callService('api/tariffacliente/tariffe', param);
                        this.changeSearch();

                        this.snackSuccess = (snack == Snack.success);
                        this.snackError = (snack == Snack.error);
                        this.snackCancel = (snack == Snack.cancel);
                        this.snackRemote = (snack == Snack.remote);
                    },       
                    customFilter(value, search, item) {
                        let ok = true;
                        if (ok && this.searchText) { //codice/dnz/fiscale in OR
                            let src = this.searchText.toLowerCase();
                            let ok1 = item.tipoServizio.tipoConsulenza.descrizione && item.tipoServizio.tipoConsulenza.descrizione.toLowerCase().indexOf(src) >= 0;
                            let ok2 = !ok1 && item.tipoServizio.descrizione && item.tipoServizio.descrizione.toLowerCase().indexOf(src) >= 0;
                            let ok3 = !ok2 && item.tipoServizio.tipoPeriodicita ? item.tipoServizio.tipoPeriodicita.descrizione && item.tipoServizio.tipoPeriodicita.descrizione.toLowerCase().indexOf(src) >= 0 : false;
                            ok = ok1 || ok2 || ok3;
                        }
                        if (ok && this.idTipoConsulenza) {
                            ok = item.tipoServizio.tipoConsulenza.id == this.idTipoConsulenza;
                        }
                        if (ok && this.idTipoPeriodicita == "-1") {
                            ok = item.tipoServizio.tipoPeriodicita == null;
                        }
                        else if (ok && this.idTipoPeriodicita) {
                            ok = item.tipoServizio.tipoPeriodicita ? item.tipoServizio.tipoPeriodicita.id == this.idTipoPeriodicita : false;
                        }

                        return ok;
                    },
                    changeSearch() {
                        this.search =
                        this.searchText +
                        this.idTipoConsulenza +
                        this.idTipoPeriodicita
                    },
                    
                },
                computed: {
                    hintSelezione() {
                        if(this.selezione.length==1) return "1 tariffa selezionata su "+this.filtro.length;
                        if(this.selezione.length>1) return this.selezione.length+" tariffe selezionate su "+this.filtro.length;
                        return "";
                    }
                },
                async created() {
                    let param = [];
                    this.listaConsulenze = await callService('api/tariffacliente/listaconsulenze', param);
                    this.listaPeriodicita = await callService('api/tariffacliente/listaperiodicita', param);


                    bus.$on('tariffa-changed', this.onTariffaChanged);
                    bus.$on('tariffa-snack-succes', this.onSnackSuccess);

                    this.nPag = Number(this.$cookie.get('tariffe-pagination')); //leggo il coockie della paginazione

                    //this.idOperatoreScadenza = this.$store.state.utente.operatore.id;

                    await this.internalCreated(Snack.nothing);

                    this.loading = false;
                },
                beforeDestroy() {
                    bus.$off('tariffa-changed', this.onTariffaChanged);
                    bus.$off('tariffa-snack-succes', this.onSnackSuccess);
                }
    }
</script>
