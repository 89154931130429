<template>
    <v-dialog v-model="dialogRS" persistent
              max-width="1200">
        <v-stepper v-model="rs" alt-labels>
            <v-stepper-header>
                <v-stepper-step :complete="rs > 1"
                                step="1">
                    A.1
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 2"
                                step="2">
                    A.2
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 3"
                                step="3">
                    A.3
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 4"
                                step="4">
                    A.4
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 5"
                                step="5">
                    B.1
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 6"
                                step="6">
                    B.2
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 7"
                                step="7">
                    B.3
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 8"
                                step="8">
                    B.4
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 9"
                                step="9">
                    B.5
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 10"
                                step="10">
                    B.6
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="rs > 11"
                                step="11">
                    Riepilogo
                </v-stepper-step>


                <v-divider></v-divider>

                <v-stepper-step step="12">
                    Esito
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items class="mt-n16">
                <v-stepper-content step="1">
                    <v-container class="mb-2">
                        <v-form ref="formRS1"
                                v-model="validRSA1">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella A - Aspetti connessi al cliente:</font><font color="Blue"> A.1 - Natura giuridica</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isA11"
                                                :label="descrizioneRS('A11')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA12"
                                                :label="descrizioneRS('A12')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA13"
                                                :label="descrizioneRS('A13')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA14"
                                                :label="descrizioneRS('A14')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA15"
                                                :label="descrizioneRS('A15')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isA16"
                                                        :label="descrizioneRS('A16')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroA1"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isA16"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>


                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionA1"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn :disabled="selectionA1==-1" color="success" depressed
                                   @click="rs = 2">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-container class="mb-2">
                        <v-form ref="formRS2"
                                v-model="validRSA2">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella A - Aspetti connessi al cliente:</font><font color="Blue"> A.2 - Prevalente attività svolta</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isA21"
                                                :label="descrizioneRS('A21')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA22"
                                                :label="descrizioneRS('A22')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA23"
                                                :label="descrizioneRS('A23')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isA24"
                                                        :label="descrizioneRS('A24')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroA2"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isA24"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionA2"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 1">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionA2==-1" color="success" depressed
                                   @click="rs = 3">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-container class="mb-2">
                        <v-form ref="formRS3"
                                v-model="validRSA3">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella A - Aspetti connessi al cliente:</font><font color="Blue"> A.3 - Comportamento tenuto al momento del conferimento dell’incarico</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isA31"
                                                :label="descrizioneRS('A31')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA32"
                                                :label="descrizioneRS('A32')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA33"
                                                :label="descrizioneRS('A33')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA34"
                                                :label="descrizioneRS('A34')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isA35"
                                                        :label="descrizioneRS('A35')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroA3"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isA35"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionA3"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 2">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionA3==-1" color="success" depressed
                                   @click="rs = 4">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <v-container class="mb-2">
                        <v-form ref="formRS4"
                                v-model="validRSA4">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella A - Aspetti connessi al cliente:</font><font color="Blue"> A.4 - Area geografica di residenza del cliente</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isA41"
                                                :label="descrizioneRS('A41')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isA42"
                                                :label="descrizioneRS('A42')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isA43"
                                                        :label="descrizioneRS('A43')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroA4"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isA43"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionA4"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 3">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionA4==-1" color="success" depressed
                                   @click="rs = 5">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="5">
                    <v-container class="mb-2">
                        <v-form ref="formRS5"
                                v-model="validRSB1">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella B - Aspetti connessi all’operazione e/o prestazione professionale:</font><font color="Blue"> B.1 - Tipologia</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isB11"
                                                :label="descrizioneRS('B11')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB12"
                                                :label="descrizioneRS('B12')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB13"
                                                :label="descrizioneRS('B13')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isB14"
                                                        :label="descrizioneRS('B14')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroB1"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isB14"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionB1"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 4">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionB1==-1" color="success" depressed
                                   @click="rs = 6">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="6">
                    <v-container class="mb-2">
                        <v-form ref="formRS6"
                                v-model="validRSB2">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella B - Aspetti connessi all’operazione e/o prestazione professionale:</font><font color="Blue"> B.2 - Modalità di svolgimento</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isB21"
                                                :label="descrizioneRS('B21')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB22"
                                                :label="descrizioneRS('B22')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB23"
                                                :label="descrizioneRS('B23')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB24"
                                                :label="descrizioneRS('B24')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isB25"
                                                        :label="descrizioneRS('B25')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroB2"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isB25"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionB2"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 5">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionB2==-1" color="success" depressed
                                   @click="rs = 7">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="7">
                    <v-container class="mb-2">
                        <v-form ref="formRS7"
                                v-model="validRSB3">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella B - Aspetti connessi all’operazione e/o prestazione professionale:</font><font color="Blue"> B.3 - Ammontare dell’operazione</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isB31"
                                                :label="descrizioneRS('B31')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB32"
                                                :label="descrizioneRS('B32')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isB33"
                                                        :label="descrizioneRS('B33')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroB3"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isB33"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionB3"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 6">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionB3==-1" color="success" depressed
                                   @click="rs = 8">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="8">
                    <v-container class="mb-2">
                        <v-form ref="formRS8"
                                v-model="validRSB4">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella B - Aspetti connessi all’operazione e/o prestazione professionale:</font><font color="Blue"> B.4 - Frequenza e volume delle operazioni/durata della prestazione professionale</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isB41"
                                                :label="descrizioneRS('B41')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB42"
                                                :label="descrizioneRS('B42')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isB43"
                                                        :label="descrizioneRS('B43')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroB4"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isB43"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionB4"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 7">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionB4==-1" color="success" depressed
                                   @click="rs = 9">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="9">
                    <v-container class="mb-2">
                        <v-form ref="formRS9"
                                v-model="validRSB5">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella B - Aspetti connessi all’operazione e/o prestazione professionale:</font><font color="Blue"> B.5 – Ragionevolezza</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isB51"
                                                :label="descrizioneRS('B51')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB52"
                                                :label="descrizioneRS('B52')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB53"
                                                :label="descrizioneRS('B53')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isB54"
                                                        :label="descrizioneRS('B54')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroB5"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isB54"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionB5"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 8">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionB5==-1" color="success" depressed
                                   @click="rs = 10">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="10">
                    <v-container class="mb-2">
                        <v-form ref="formRS10"
                                v-model="validRSB6">
                            <v-col>
                                <v-card class="mx-auto my-12"
                                        max-width="1100">

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n4"><font color="#9900FF">Tabella B - Aspetti connessi all’operazione e/o prestazione professionale:</font><font color="Blue"> B.6 - Area geografica di destinazione</font></v-card-text>

                                    <v-checkbox class="mt-0"
                                                v-model="valutazioniRischioSpecifico.isB61"
                                                :label="descrizioneRS('B61')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB62"
                                                :label="descrizioneRS('B62')">
                                    </v-checkbox>
                                    <v-checkbox class="mt-n4"
                                                v-model="valutazioniRischioSpecifico.isB63"
                                                :label="descrizioneRS('B63')">
                                    </v-checkbox>
                                    <v-row>
                                        <v-col cols="1">
                                            <v-checkbox class="mt-n4"
                                                        v-model="valutazioniRischioSpecifico.isB64"
                                                        :label="descrizioneRS('B64')">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-text-field v-model="valutazioniRischioSpecifico.altroB6"
                                                          label="Descrizione altro aspetto"
                                                          outlined
                                                          :disabled="!valutazioniRischioSpecifico.isB64"
                                                          dense
                                                          style="font-size:smaller"
                                                          class="mt-n4 ml-n4">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text style="text-align:center; font-weight:bold" class="mt-n3">Imposta l`indice di rischiosità</v-card-text>

                                    <v-card-text>
                                        <v-chip-group v-model="selectionB6"
                                                      active-class="deep-purple accent-4 white--text"
                                                      column
                                                      class="mt-n8">
                                            <v-chip>1</v-chip>

                                            <v-chip>2</v-chip>

                                            <v-chip>3</v-chip>

                                            <v-chip>4</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row dense class="mt-n16">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 9">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="selectionB6==-1" color="success" depressed
                                   @click="rs = 11">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="11">
                    <v-container class="mb-12">
                        <v-row>
                            <v-col cols="6">
                                <v-row dense class="mt-10">
                                    <v-col>
                                        <v-list-item two-line>
                                            <v-list-item-icon>
                                                <v-icon color="blue">mdi-chart-bar</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6 blue--text">A - Aspetti connessi al cliente</v-list-item-title>
                                                <v-list-item-subtitle>A.1 - Natura giuridica: <strong>{{selectionA1+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>A.2 - Prevalente attività svolta: <strong>{{selectionA2+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>A.3 - Comportamento tenuto al momento del conferimento dell’incarico: <strong>{{selectionA3+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>A.4 - Area geografica di residenza del cliente: <strong>{{selectionA4+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle class="blue--text"><strong>Media aritmetica A: {{mediaRSA}}</strong></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="mt-2">
                                        <v-list-item two-line>
                                            <v-list-item-icon>
                                                <v-icon color="purple">mdi-chart-line</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6 purple--text text-wrap">B - Aspetti connessi all’operazione e/o prestazione professionale</v-list-item-title>
                                                <v-list-item-subtitle>B.1 - Tipologia: <strong>{{selectionB1+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>B.2 - Modalità di svolgimento: <strong>{{selectionB2+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>B.3 - Ammontare dell’operazione: <strong>{{selectionB3+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>B.4 - Frequenza e volume delle operazioni/durata della prestazione: <strong>{{selectionB4+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>B.5 – Ragionevolezza: <strong>{{selectionB5+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle>B.6 - Area geografica di destinazione: <strong>{{selectionB6+1}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle class="purple--text"><strong>Media aritmetica: {{mediaRSB}}</strong></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row dense class="mt-10">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon color="brown">mdi-chart-bar</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6 brown--text">Determinazione del Rischio Specifico</v-list-item-title>
                                            <v-list-item-subtitle class="blue--text"><strong>A - Rischio Specifico Cliente: {{selectionA1+selectionA2+selectionA3+selectionA4+4}}</strong></v-list-item-subtitle>
                                            <v-list-item-subtitle class="purple--text"><strong>B - Rischio Specifico Prestazione: {{selectionB1+selectionB2+selectionB3+selectionB4+selectionB5+selectionB6+6}}</strong></v-list-item-subtitle>
                                            <v-list-item-subtitle><strong class="brown--text">Rischio Specifico Complessivo: {{mediaRS}}</strong></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-row>
                                <v-row>
                                    <v-col cols="9" offset="1">
                                        <v-card color="white"
                                                dark>
                                            <v-card-title class="text-h10 black--text">
                                                Rischio specifico da valori ponderati
                                            </v-card-title>
                                        </v-card>
                                        <v-card color="green"
                                                dark>
                                            <v-card-subtitle class="text-h10">
                                                <v-icon :color="coloraRS(1)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Non significativo - Punteggio 1-1.5
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-card color="yellow"
                                                dark>
                                            <v-card-subtitle class="text-h10 black--text">
                                                <v-icon :color="coloraRS(2)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Poco significativo - Punteggio 1.6-2.5
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-card color="orange"
                                                dark>
                                            <v-card-subtitle class="text-h10 black--text">
                                                <v-icon :color="coloraRS(3)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Abbastanza significativo - Punteggio 2.6-3.5
                                            </v-card-subtitle>
                                        </v-card>
                                        <v-card color="red"
                                                dark>
                                            <v-card-subtitle class="text-h10 white--text">
                                                <v-icon :color="coloraRS(4)">
                                                    mdi-arrow-right-circle
                                                </v-icon>
                                                Molto significativo - Punteggio 3.6-4
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-row dense class="mt-n10">
                        <v-col cols="2">
                            <v-btn color="primary"
                                   @click="rs = 10">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="success" depressed
                                   @click="sendRS">
                                Aggiorna Valutazione Rischio Specifico
                                <v-icon right>
                                    mdi-send
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="12">
                    <v-container class="mb-12" align="center">
                        <v-col cols="6" offset="3">
                            <v-card v-if="loadingRS" color="primary"
                                    dark>
                                <v-card-text>
                                    Esecuzione Operazione...
                                    <v-progress-linear indeterminate
                                                       color="white"
                                                       class="mb-0">
                                    </v-progress-linear>
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingRS && esitoRSOK" color="success"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    Operazione eseguita correttamente
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingRS && !esitoRSOK" color="error"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-alert-circle
                                    </v-icon>
                                    Errore durante l'esecuzione dell'operazione
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-container>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi"
                                   color="warning"
                                   :disabled="loadingRS">
                                Chiudi
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>


    </v-dialog>
</template>


<script>

    import { callService, callPost, Snack, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        props: ['type'],
        data: () => ({
            progress: false,
            theme:false,
            rs: 1,
            dialogRS: false,
            validRSA1: false,
            validRSA2: false,
            validRSA3: false,
            validRSA4: false,

            validRSB1: false,
            validRSB2: false,
            validRSB3: false,
            validRSB4: false,
            validRSB5: false,
            validRSB6: false,

            loadingRS: false,
            esitoRSOK: false,

            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },

            valutazioniRischioSpecifico: {id: ''},
            nuovo: false,
            listaTVRS: [],
            //listaTVV: [],
            idRI: '',
            selectionA1: 0,
            selectionA2: 0,
            selectionA3: 0,
            selectionA4: 0,
            selectionB1: 0,
            selectionB2: 0,
            selectionB3: 0,
            selectionB4: 0,
            selectionB5: 0,
            selectionB6: 0,
            //vulnerabilitacorrente: {id: '', valore: 0},
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,

        }),
        methods: {
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            checkData(v) {
                return gCheckData(v);
            },

            descrizioneRS(codice) {
                let p = this.listaTVRS.find(x => x.codice === codice);
                if (p) return p.descrizione;
                else return "";
            },

            serverData(v) {
                return gServerData(v);
            },
            async sendRS() {
                this.loadingRS = true;
                this.rs = 12;

                this.valutazioniRischioSpecifico.valutazioneA1 = this.selectionA1 + 1;
                this.valutazioniRischioSpecifico.valutazioneA2 = this.selectionA2 + 1;
                this.valutazioniRischioSpecifico.valutazioneA3 = this.selectionA3 + 1;
                this.valutazioniRischioSpecifico.valutazioneA4 = this.selectionA4 + 1;

                this.valutazioniRischioSpecifico.valutazioneB1 = this.selectionB1 + 1;
                this.valutazioniRischioSpecifico.valutazioneB2 = this.selectionB2 + 1;
                this.valutazioniRischioSpecifico.valutazioneB3 = this.selectionB3 + 1;
                this.valutazioniRischioSpecifico.valutazioneB4 = this.selectionB4 + 1;
                this.valutazioniRischioSpecifico.valutazioneB5 = this.selectionB5 + 1;
                this.valutazioniRischioSpecifico.valutazioneB6 = this.selectionB6 + 1;

                let valutazioniRischioSpecifico = JSON.parse(JSON.stringify(this.valutazioniRischioSpecifico));

                //alert(JSON.stringify(valutazioniRischioSpecifico));

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    valutazioneRischioSpecifico: valutazioniRischioSpecifico
                };

                let sca = await callPost('api/antiriciclaggio/savevrs', param);


                if (sca) {
                    //alert(sca.id);
                    this.valutazioniRischioSpecifico.id = sca.id;
                    this.nuovo = false;
                    //bus.$emit('vrr-snack-succes', {});
                    bus.$emit('fascicolo-changed');
                    this.loadingRS = false;
                    this.dialogRS = false;
                }
                else {
                    await this.internalCreated(Snack.error);
                    this.loadingRS = false;
                }
            },
            chiudi() {
                this.dialogRS = false;
            },            
            async internalCreated(snack) {

                let param = [];
                this.listaTVRS = this.$store.state.listaTVRS;
                //this.listaTVV = await callService("api/antiriciclaggio/listaTVV", param);
                //this.vulnerabilitacorrente = await callService("api/antiriciclaggio/vulnerabilitaCorrente", param);


                param = [this.valutazioniRischioSpecifico.id];
                this.valutazioniRischioSpecifico = await callService("api/antiriciclaggio/vrs", param);


                this.selectionA1 = this.valutazioniRischioSpecifico.valutazioneA1-1;
                this.selectionA2 = this.valutazioniRischioSpecifico.valutazioneA2-1;
                this.selectionA3 = this.valutazioniRischioSpecifico.valutazioneA3-1;
                this.selectionA4 = this.valutazioniRischioSpecifico.valutazioneA4-1;

                this.selectionB1 = this.valutazioniRischioSpecifico.valutazioneB1-1;
                this.selectionB2 = this.valutazioniRischioSpecifico.valutazioneB2-1;
                this.selectionB3 = this.valutazioniRischioSpecifico.valutazioneB3-1;
                this.selectionB4 = this.valutazioniRischioSpecifico.valutazioneB4-1;
                this.selectionB5 = this.valutazioniRischioSpecifico.valutazioneB5-1;
                this.selectionB6 = this.valutazioniRischioSpecifico.valutazioneB6-1;

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            coloraRS(livello) {
                let colore = this.mediaRS;
                if (livello == 1 && colore > 1.5) {
                    return "green";
                }
                if (livello == 2 && (colore < 1.6 || colore > 2.5)) {
                    return "yellow";
                }
                if (livello == 3 && (colore < 2.6 || colore > 3.5)) {
                    return "orange";
                }
                if (livello == 4 && colore < 3.6) {
                    return "red";
                }
                else { 
                    //alert(livello + " " + colore);    
                    return "black";
                }
            }            
        },
        computed: {
            mediaRSA() {
                let media = 0;
                media = this.selectionA1 + this.selectionA2 + this.selectionA3 + this.selectionA4 + 4;
                media = media / 4;
                return media.toFixed(2);
            },
            mediaRSB() {
                let media = 0;
                media = this.selectionB1 + this.selectionB2 + this.selectionB3 + this.selectionB4+ this.selectionB5+ this.selectionB6 + 6;
                media = media / 6;
                return media.toFixed(2);
            },
            mediaRS() {
                let media = 0;
                media = this.selectionA1 + this.selectionA2 + this.selectionA3 + this.selectionA4 + 4; 
                media = media + this.selectionB1 + this.selectionB2 + this.selectionB3 + this.selectionB4+ this.selectionB5+ this.selectionB6 + 6;
                media = media / 10;
                return media.toFixed(1);
            }
        }, 
        

        async created() {           

            var vm = this;

            bus.$on('dialog-show-rs', async function (valutazioniRischioSpecifico) {
                vm.dialogRS = true;
                vm.loadingRS = true;
                vm.rs = 1;
                vm.nuovo = valutazioniRischioSpecifico.id == "0";
                vm.valutazioniRischioSpecifico.id = valutazioniRischioSpecifico.id;
                await vm.internalCreated(Snack.nothing);
                vm.loadingRS = false;
            });   
        },
        beforeDestroy() {
            bus.$off('dialog-show-rs');
        }

    }</script>


    
