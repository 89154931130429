<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="searchText"
                                                      append-icon="mdi-magnify"
                                                      label="Ricerca un fascicolo per codice cliente, nominativo, piva, cf, etichetta, località"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @input="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-btn-toggle class="mt-n1 mx-2">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           @click="downloadAllegato('AV2')">
                                                        <v-icon color="blue">
                                                            mdi-file-pdf-box
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Scarica il modello di check-list ai fini della formazione del fascicolo del cliente (AV.2)</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           @click="downloadAllegato('AV3')">
                                                        <v-icon color="red">
                                                            mdi-file-pdf-box
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Scarica il modello di istruttoria cliente (AV.3)</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           @click="downloadAllegato('AV4')">
                                                        <v-icon color="green">
                                                            mdi-file-pdf-box
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Scarica il modello di dichiarazione del cliente (AV.4)</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           @click="downloadAllegato('AV5')">
                                                        <v-icon color="orange">
                                                            mdi-file-pdf-box
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Scarica il modello di dichiarazione del professionista attestante ex. art. 26 D.LGS. 23/2007 (AV.5)</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           @click="downloadAllegato('AV6')">
                                                        <v-icon color="cyan">
                                                            mdi-file-pdf-box
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Scarica il modello di dichiarazione di astensione del professionista (AV.6)</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           @click="downloadAllegato('AV7')">
                                                        <v-icon color="purple">
                                                            mdi-file-pdf-box
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Scarica il modello di procedura di controllo costante (AV.7)</span>
                                            </v-tooltip>

                                        </v-btn-toggle>

                                    </v-col>
                                    <v-col cols="2" class="mx-n16">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       :color="coloraVV"
                                                       dark
                                                       @click="gestisciVulnerabilita(vulnerabilita)">
                                                    <v-icon left>
                                                        mdi-chart-box-outline
                                                    </v-icon>
                                                    Vulnerabilità studio
                                                </v-btn>
                                            </template>
                                            <span>Gestione della Valutazione della Vulnerabilità dello Studio</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-n4">
                                    <v-col cols="12">
                                        <v-data-table v-model="selezione"
                                                      :headers="headers"
                                                      :items="fascicoliEstesi"
                                                      item-key="pratica.id"
                                                      ref="tabellaFascicoli"
                                                      :loading="loading"
                                                      progress="78"
                                                      :items-per-page="nPag"
                                                      class="elevation-1"
                                                      :item-class="coloraStatus"
                                                      :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                                      @current-items="filterItems"
                                                      @pagination="pagination"
                                                      :single-select="false"
                                                      :search="search"
                                                      :custom-filter="customFilter"
                                                      loading-text="Caricamento dati..."
                                                      no-data-text="Nessun fascicolo presente in archivio"
                                                      no-results-text="Nessun fascicolo corrispodende ai criteri di ricerca">
                                            <template v-slot:item.azioni="{item}">
                                                <v-row>
                                                    <v-col class="mx-n4">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn class="mx-3" icon @click="anagrafica(item)"
                                                                       v-bind="attrs"
                                                                       v-on="on">
                                                                    <v-icon color="blue darken-2">
                                                                        mdi-bank-circle-outline
                                                                    </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Aggiorna Fascicolo</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-slot:item.dataScadenza="{ item }">
                                                <span class="text-subtitle" v-text="localData(item.dataScadenza)" />
                                            </template>

                                            <template v-slot:item.tav="{ item }">
                                                <span class="text-subtitle" v-text="descrizioneAV(item.tav)" />
                                            </template>

                                            <template v-slot:item.data="{ item }">
                                                <span class="text-subtitle" v-text="localData(item.data)" />
                                            </template>
                                            <template v-slot:header.cliente.denominazioneNormalizzata>
                                                Nominativo
                                                <br />
                                                P.IVA/C.Fiscale
                                            </template>
                                            <template v-slot:item.cliente.denominazioneNormalizzata="{ item }">
                                                <span class="text-subtitle font-weight-medium" v-text="item.cliente.denominazioneNormalizzata" />
                                                <br />
                                                <span v-if="!item.cliente.tipoCliente.ordinamento==6" class="font-weight-light" v-text="item.cliente.partitaIVA+' '" />
                                                <span v-if="item.cliente.codiceFiscale" class="font-weight-light" v-text="item.cliente.codiceFiscale" />
                                                <span v-else class="font-weight-light" v-text="item.cliente.codiceFiscaleLR" />
                                            </template>
                                            <template v-slot:item.bStatus="{ item }">
                                                <v-tooltip bottom v-if="item.bStatus">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon
                                                               color="grey"
                                                               v-bind="attrs"
                                                               v-on="on">
                                                            <v-icon color="green">mdi-file-document-check-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Fascicolo completo e valido</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon
                                                               color="red"
                                                               v-bind="attrs"
                                                               v-on="on">
                                                            <v-icon color="error darken-2">mdi-file-document-alert-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Fascicolo da completare</span>
                                                </v-tooltip>
                                            </template>

                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <vulnerabilita-dialog />

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>

    </v-container>

</template>

<script>import { callService, callPost, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'
    import { bus } from '@/main.js'
    import VulnerabilitaDialog from '@/views/components/VulnerabilitaDialog.vue'

    export default {
        components: {
            VulnerabilitaDialog
        },
        data: () => ({
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogCliente: false,
            selezione: [],
            filtro: [],
            statoAll: false,
            loading: true,
            showError: false,
            viewMenuCliente: false,
            x: 0,
            y: 0,
            search: '',
            searchState: [],
            searchText: '',
            searchBP: '',
            fascicolo: [],
            vulnerabilitaStudio: [],
            listaTipiAdeguataVerifica: [],
            vulnerabilita: {
                id: '0'
            },
            fascicoliEstesi: [],
            headers: [
                { text: 'Azioni', value: 'azioni', sortable: false, width: '5%', align:'center'  },
                { text: 'Cliente', value: 'cliente.denominazioneNormalizzata', width:'30%'},
                { text: 'Professionista', value: 'professionista.denominazione', width: '20%' },
                { text: 'Status', value: 'bStatus'},
                { text: 'Adeguata Verifica', value: 'tav', width: '10%' },
                { text: 'Data', value: 'data', width: '10%' },
                { text: 'Scadenza', value: 'dataScadenza', width: '10%' },
            ],
            nPag: 8,
            theme: '',
            oggetto: '',
            testo: '',
            idTipoServizio: '',
            tipiServizioEstesi: [],
            descrizioneAddebito: '',
            dataAddebito: '',
            importoAddebito: 0,
            periodi: [],
            listamodelli: [],
            listaoperatori: [],
            listascadenze: [],
            listaDoc: [],
            idModello: '',
            idScadenza: '',
            idOperatore: '',
            importoScadenza: 0,
            periodiConnessi: [],
            idPeriodo: '',
            loadingExcel: false,
            corrente: true,
            fileExcel:'',
            dataScadenza: '',
            lockImporto: false,
            numeroEdit: 0,
            mode: 0,
            clientiTariffa: [],
            conteggioClienti: [],
            listaClientiNoTariffa: [],
            listaClientiTariffaAddebitata: [],
            listaClientiNoAddebito: [],
            listaClientiOk: [],
            notifica: true,
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },
            operatore: { id: '' },
            professionista: { id: '' },
            isAccessoLimitato: false,
            idProf: '',
            idOpe: '',
            regimeIVA: { id: '' },
            regimeContabile: { id: '' },
            tipoCliente: { id: '' },
            fido: '',
            listaModuliPdf: [],
        }),
        methods: {
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            descrizioneAV(elem) {
                //alert(JSON.stringify(this.listaTipiAdeguataVerifica));
                var descr = this.listaTipiAdeguataVerifica.find(ce => ce.id === elem);
                return descr.descrizione;
            },
            gestisciVulnerabilita(vulnerabilita) {
                this.vulnerabilita = JSON.parse(JSON.stringify(vulnerabilita));
                bus.$emit('dialog-show-vulnerabilita', this.vulnerabilita);
            }, 
            async listaExcel() {
                this.loadingExcel = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                let outputPdf = await callPost('api/excel/listacontatti', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                }
                else this.snackError = true;
                this.loadingExcel = false;
            },
            scaricaExcel() {
                //alert(this.filePdf);
                window.open(this.fileExcel);
                this.fileExcel = '';
            },
            async onFascicoliChanged() {

                await this.internalCreated(Snack.nothing);
            },
            async onFascicoloDeleted({ lista, sessionID }) {

                if (sessionID == this.$store.state.sessionID) return;
                for (var i = 0; i < lista.length; i++) {

                    let idPratica = lista[i];

                    //cerco nella lista la pratica eliminata

                    const index = this.praticheEstese.findIndex(u => {
                        return u.pratica.id === idPratica;
                    });

                    if (index >= 0) {
                        this.praticheEstese.splice(index, 1);
                    }
                }
            },
            onSnackSuccess() {
                this.snackSuccess = true;
            },
            coloraRiga(item) {
                if (item.colore == "grey") return "style-lock";
                return "";
            },
            coloraStatus(item) {
                //if (item.cliente.isSospeso) return "style-suspended";
                //if (item.cliente.isAddebitiSospesi) return "style-fee-suspended";
                //if (item.cliente.isCancellato) return "style-deleted";
                return "";
            },
            filterItems(v) {
                this.$nextTick(() => {
                    this.filtro = this.$refs.tabellaFascicoli.$children[0].filteredItems;
                });
            },
            saveNumero(item) {
                if (item.lockNumero && this.numeroEdit && this.numeroEdit != 1 && this.numeroEdit != 0) return;
                item.numero = Number(this.numeroEdit);
                item.totale = item.numero * item.tariffa.importo;
                if (item.numero > 0) item.colore = "green";
                if (item.numero == 0) item.colore = "blue";
            },
            anagrafica(fascicolo) {
                this.fascicolo = JSON.parse(JSON.stringify(fascicolo));
                //alert(this.fascicolo);
                console.log(JSON.stringify(JSON.stringify(fascicolo)));
                bus.$emit('dialog-show-fascicolo', this.fascicolo);
                //this.$router.replace({ name: "cliente", query: { id: cliente.id } });
            },
            pagination(pag) {
                let nPag = pag.itemsPerPage;
                this.$cookie.set('fascicoli-pagination', nPag.toString(), { expires: '1Y' });
            },
            customFilter(value, search, item) {
                let ok = true;
                if (ok && this.searchText) { //codice/dnz/fiscale in OR
                    let src = this.searchText.toLowerCase();
                    src = src.replace(/\s+/g, ' '); //elimino i doppi spazi interni
                    src = src.trim();
                    var tokens = src.split(" ");
                    var i=0;
                    //console.log("*"+src+"*");
                    while (ok && i<tokens.length) {
                        let srcToken = tokens[i];
                        let ok1 = item.cliente.denominazioneNormalizzata && item.cliente.denominazioneNormalizzata.toLowerCase().indexOf(srcToken) >= 0;
                        let ok2 = !ok1 && item.cliente.denominazioneNormalizzataRL && item.cliente.denominazioneNormalizzataRL.toLowerCase().indexOf(srcToken) >= 0;
                        let ok3 = !ok2 && item.cliente.codice && item.cliente.codice.toLowerCase().indexOf(srcToken) >= 0;
                        let ok4 = !ok3 && item.cliente.codiceFiscale && item.cliente.codiceFiscale.toLowerCase().indexOf(srcToken) >= 0;
                        let ok5 = !ok4 && item.cliente.codiceFiscaleLR && item.cliente.codiceFiscaleLR.toLowerCase().indexOf(srcToken) >= 0;
                        let ok6 = !ok5 && item.cliente.tags && item.cliente.tags.toLowerCase().indexOf(srcToken) >= 0;
                        let ok7 = !ok6 && item.mailList && item.listaRecapitiMail.toLowerCase().indexOf(srcToken) >= 0;
                        let ok8 = !ok7 && item.mailList && item.listaRecapitiTel.toLowerCase().indexOf(srcToken) >= 0;
                        let ok9 = !ok8 && item.mailList && item.listaRecapitiAltro.toLowerCase().indexOf(srcToken) >= 0;
                        let ok10 = !ok9 && item.cliente.partitaIVA && item.cliente.partitaIVA.toLowerCase().indexOf(srcToken) >= 0;


                        ok = ok1 || ok2 || ok3 || ok4 || ok5 || ok6 || ok7 || ok8 || ok9 || ok10;
                        i++;
                    }                    
                }

                return ok;
            },
            changeSearch() {
                this.searchState =
                    [this.searchText,
                    this.operatore.id,       
                    this.professionista.id, 
                    this.regimeIVA.id,     
                    this.regimeContabile.id, 
                    this.tipoCliente.id, 
                    this.fido,  
                    ];
                    this.search=this.searchState.toString();
                    this.writeState(); //memorizzo lo stato della ricerca
            },
            visualizzaClienti(lista) {
                if (!lista) return;
                bus.$emit('msg-list-open', { list: lista.map(c => ({ ...c, desc: c.codice + " - " + c.denominazioneNormalizzata })), title: "Elenco Clienti" });
            },

            writeState() {

                //console.log(this.search);

                this.$store.dispatch('setStatoAll', false);
                this.$store.dispatch('setSelezione', this.selezione);
                this.$store.dispatch('setSearchState', this.searchState);

            },
            async internalCreated(snack) {
                    this.loading = true;
                    this.mode = 0;
                    let param = [this.corrente];
                    this.vulnerabilitaStudio = await callService('api/antiriciclaggio/listaVulnerabilita', param);
                    this.vulnerabilita = this.vulnerabilitaStudio[0];
                    //alert(JSON.stringify(this.vulnerabilita))
                    this.listaTipiAdeguataVerifica = this.$store.state.listaTipiAdeguataVerifica;

                    this.idProf = 'x';
                    let param2 = [this.corrente, this.mode, this.idProf];

                    if (this.isAccessoLimitato && !this.$store.state.utente.isGestoreStudio)
                    {
                        if (this.$store.state.utente.operatore.isProfessionista) {
                            this.idProf = this.$store.state.utente.operatore.professionista.id;
                            this.mode = 1;
                            //filtro la lista dei fascicoli per professionista
                            param2 = [this.corrente, this.mode, this.idProf];
                        }
                        else //si tratta di un operatore
                        {
                            //mostro solo i clienti che hanno l'operatore corrente assegnato a qualche attività
                            this.idOpe = this.$store.state.utente.operatore.id;
                            this.mode = 2;
                            param2 = [this.corrente, this.mode, this.idOpe];
                        }
                    }

                    //alert(param2);
                    //eseguo la chiamata con i parametri impostati
                    this.fascicoliEstesi = await callService('api/antiriciclaggio/lista', param2);


                    this.loading = false;

                    //carica i modili pdf standard per il download
                    this.listaModuliPdf = this.$store.state.listaModuliArPdf;


                    this.search = "x"; //faccio partire il filtro perché chiedo tutte le prstiche ma filtro sulle aperte
                    this.snackSuccess = (snack == Snack.success);
                    this.snackError = (snack == Snack.error);
                    this.snackCancel = (snack == Snack.cancel);
                    this.snackRemote = (snack == Snack.remote);
            },
            downloadAllegato(elemento) {
                let item = this.listaModuliPdf.find(x => x.allegato === elemento);
                if (item) {
                    var link = document.createElement('a');
                    link.href = item.allegatoPath;
                    link.download = item.allegato;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },           
            //async caricaVV(snack) {
            //    this.loading = true;
            //    let param = [this.corrente];
            //    this.vulnerabilitaStudio = await callService('api/antiriciclaggio/listaVulnerabilita', param);
            //    this.vulnerabilita = this.vulnerabilitaStudio[0];
            //    this.loading = false;
            //}, 
        },
        computed: {
            hintSelezione() {
                if (this.selezione.length == 1) return "1 cliente selezionato su " + this.filtro.length;
                if (this.selezione.length > 1) return this.selezione.length + " clienti selezionati su " + this.filtro.length;
                return "";
            },
            coloraVV() {
                if (this.vulnerabilita.valore == 0) return "grey black--text";
                else if (this.vulnerabilita.valore <= 1.5) return "green black--text";
                else if (this.vulnerabilita.valore <= 2.5) return "yellow black--text";
                else if (this.vulnerabilita.valore <= 3.5) return "orange black--text";
                else return "red";
            }
            //importoAddebitoEuro() {
            //    return euro(this.importoAddebito);
            //},
        },

        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;

            this.clientiEstesi = this.$store.state.clientiEstesi;
            this.listaOperatore = this.$store.state.listaOperatore;
            this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;

            bus.$on('fascicoli-changed', this.onFascicoliChanged);
            bus.$on('fascicoli-deleted', this.onFascicoliDeleted);
            bus.$on('fascicoli-snack-succes', this.onSnackSuccess);

            this.nPag = Number(this.$cookie.get('fascicoli-pagination')); //leggo il coockie della paginazione

            if (this.$store.state.utente.operatore.isAccessoLimitato) {
                this.isAccessoLimitato = true;
            }

            await this.internalCreated(Snack.nothing);

        },
        beforeDestroy() {
            bus.$off('fascicoli-changed', this.onFascicoliChanged);
            bus.$off('fascicoli-deleted', this.onFascicoliDeleted);
            bus.$off('fascicoli-snack-succes', this.onSnackSuccess);

        }
    }</script>

<style>
    .style-lock {
        color: grey
    }

    .style-suspended {
        background-color: orange
    }

    .style-fee-suspended {
        background-color: yellow
    }

    .style-deleted {
        background-color: red
    }
</style>


    
