<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="2">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="blue darken-2"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewAO"
                                                       :disabled="selezione.length==0">
                                                    <v-icon class="ma-0">mdi-calendar-account</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelAO}}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="orange darken-2"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewMB"
                                                       :disabled="selezione.length==0">
                                                    <v-icon class="ma-0">mdi-broadcast</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelMB}}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="red darken-2"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewCS"
                                                       :disabled="selezione.length==0">
                                                    <v-icon class="ma-0">mdi-calendar-lock</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelCS}}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="teal"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="nuovo">
                                                    <v-icon class="ma-0">mdi-calendar-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Nuova Scadenza</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="searchText"
                                                      append-icon="mdi-magnify"
                                                      label="Ricerca una scadenza"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @input="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-select v-model="cliente.id"
                                                  :items="clientiEstesi"
                                                  item-value="cliente.id"
                                                  item-text="cliente.denominazioneNormalizzata"
                                                  label="Filtra per Cliente"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>

                                    <v-col cols="5">
                                        <v-radio-group @change="changeSearch" hide-details class="mt-0" v-model="statoScadenza" row mandatory>
                                            <v-spacer></v-spacer>
                                            <v-radio label="Nuove"
                                                     value="1"></v-radio>
                                            <v-radio label="In lavorazione"
                                                     value="2"></v-radio>
                                            <v-radio label="Tutte"
                                                     value="3"></v-radio>
                                            <v-radio label="Chiuse"
                                                     value="4"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-n8">
                                    <v-col cols="3">
                                        <v-select v-model="idOperatoreScadenza"
                                                  :items="listaOperatore"
                                                  item-value="id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Filtra per Operatore"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>


                                    <v-col cols="3">
                                        <v-select v-model="professionista.id"
                                                  :items="listaOperatoriProfessionisti"
                                                  item-value="professionista.id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Filtra per Professionista"
                                                  :disabled="isAccessoLimitato"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field class="mx-1"
                                                      v-model="dataDa"
                                                      label="Scadenza dal giorno"
                                                      outlined
                                                      clearable
                                                      persistent-hint
                                                      type="date"
                                                      @change="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="dataA"
                                                      label="Scadenza al giorno"
                                                      outlined
                                                      clearable
                                                      persistent-hint
                                                      type="date"
                                                      @change="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-data-table v-model="selezione"
                                          :headers="headers"
                                          :items="scadenze"
                                          ref="tabella"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          progress="78"
                                          class="elevation-1 mt-n4"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                          show-select
                                          @toggle-select-all="toggleAll"
                                          @item-selected="toggleSelect"
                                          @current-items="filterItems"
                                          @pagination="pagination"
                                          :single-select="false"
                                          :search="search"
                                          :custom-filter="customFilter"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessuna scadenza presente in archivio"
                                          no-results-text="Nessuna scadenza corrispodende ai criteri di ricerca">
                                <template v-slot:item.menu="{item}">
                                    <v-menu offset-y max-width="200">
                                        <template v-slot:activator="{ on, attrs }">
                                            <td v-bind="attrs"
                                                v-on="on">
                                                <v-icon color="orange darken-2">
                                                    mdi-launch
                                                </v-icon>
                                            </td>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="anagrafica(item)">
                                                <v-list-item-title>
                                                    <v-icon color="green darken-2">
                                                        mdi-calendar-sync
                                                    </v-icon>
                                                    Gestione scadenza
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>

                                <template v-slot:item.invio="{ item }">
                                    <div v-if="item.dataInvioRichiesta!=null">
                                        <span class="text-subtitle" v-text="localData(item.dataInvioRichiesta)" />
                                        <br />
                                        <span class="text-caption grey--text" v-text="descrizioneRisposta(item.isAutorizzata)" />
                                    </div>
                                </template>

                                <template v-slot:item.risposta="{ item }">
                                    <div v-if="item.dataRisposta!=null">
                                        <span class="text-subtitle" v-text="localData(item.dataRisposta)" />
                                        <br />
                                        <span class="text-caption grey--text" v-text="descrizioneApp(item.isRispostaApp)" />
                                    </div>
                                </template>

                                <template v-slot:item.dataScadenza="{ item }">
                                    <div v-if="item.importo>0">

                                        <span class="text-subtitle teal--text font-weight-medium" v-text="importoEuro(item.importo)" />
                                        <br />
                                        <span class="text-caption grey--text" v-text="localData(item.dataScadenza)" />
                                    </div>
                                </template>

                                <template v-slot:item.cliente.denominazioneNormalizzata="{ item }">
                                    <span class="text-subtitle font-weight-medium">[</span>
                                    <span class="text-subtitle font-weight-medium" v-text="item.cliente.codice" />
                                    <span class="text-subtitle font-weight-medium">]&nbsp;</span>
                                    <span class="text-subtitle font-weight-medium" v-text="item.cliente.denominazioneNormalizzata" />
                                </template>

                                <template v-slot:item.status="{ item }">
                                    <div v-if="item.isChiusa">
                                        <v-icon class="ml-3" color="green">mdi-check-outline</v-icon>
                                    </div>
                                </template>

                                <template v-slot:item.elimina="{ item }">
                                    <div v-if="item.isCancellabile">
                                        <v-btn icon @click="elimina(item)">
                                            <v-icon class="ml-3" color="error darken-2">mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </div>
                                    <div v-else>
                                        <v-icon class="ml-3" color="gray">mdi-trash-can-outline</v-icon>
                                    </div>
                                </template>

                            </v-data-table>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <scadenza-dialog />

        <v-dialog v-model="dialogMB" persistent
                  max-width="800">
            <v-stepper v-model="mb">
                <v-stepper-header>
                    <v-stepper-step :complete="mb > 1"
                                    step="1">
                        Messaggio
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="mb > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formMB"
                                    v-model="validMB">
                                <v-row>
                                    <v-col cols="10" offset="1">
                                        <v-checkbox class="ml-2" v-model="notifica1"
                                                    label="Inviare, per ogni scadenza nuova, la richiesta di autorizzazione al pagamento"
                                                    color="blue darken-3">
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="10" offset="1">
                                        <v-checkbox class="ml-2" v-model="notifica2"
                                                    label="Inviare, per ogni scadenza in lavorazione, un sollecito di richiesta di autorizzazione al pagamento"
                                                    color="green darken-3">
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!notifica1 && !notifica2" color="primary" depressed
                                       @click="mb = 2">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogMB = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="6" offset="1">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon color="(notifica1 ? 'blue darken-2' : 'gray')">mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Prima comunicazione</v-list-item-title>
                                            <v-list-item-subtitle v-html="(!notifica1 ? 'Opzione non selezionata':'Opzione selezionata')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon color="(notifica2 ? 'green darken-2' : 'grey'">mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Comunicazione di sollecito</v-list-item-title>
                                            <v-list-item-subtitle v-html="(!notifica2 ? 'Opzione non selezionata':'Opzione selezionata')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Nessuna comunicazione</v-list-item-title>
                                            <v-list-item-subtitle>Azione non necessaria</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-bulleted</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{labelNuove}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="'Nuov' +(labelNuove>1 ? 'e':'a')+ ' scadenz'+(labelNuove>1 ? 'e':'a')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-check</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{labelSollecito}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="'Scadenz'+(labelSollecito>1 ? 'e':'a') + ' in lavorazione'"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-bulleted-off</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{labelNoMessaggi}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="'Scadenz'+(labelNoMessaggi>1 ? 'e':'a') + ' già chius' +(labelNoMessaggi>1 ? 'e':'a')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="mb = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendMB">
                                    Invia
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogMB = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingMB" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingMB && esitoMBOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingMB && esitoMBKO" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogMB = false"
                                       color="warning"
                                       :disabled="loadingMB">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-dialog v-model="dialogCS" persistent
                  max-width="800">
            <v-stepper v-model="cs">
                <v-stepper-header>
                    <v-stepper-step :complete="cs > 1"
                                    step="1">
                        Messaggio
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="cs > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formCS"
                                    v-model="validCS">
                                <v-row>
                                    <v-col cols="10" offset="1">
                                        <h2 align="center">Le scadenze selezionate saranno chiuse</h2>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="10" offset="1">
                                        <v-checkbox class="ml-2" v-model="notifica3"
                                                    label="Inviare al cliente, per ogni scadenza selezionata, la notifica di chiusura delle attività."
                                                    color="blue darken-3">
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary" depressed
                                       @click="cs = 2">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCS = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="5" offset="1">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-bulleted</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{labelNuove}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="'Nuov' +((labelNuove>1 || labelNuove==0) ? 'e':'a')+ ' scadenz'+((labelNuove>1 || labelNuove==0) ? 'e':'a')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-bulleted-off</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{labelChiuse}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="'Scadenz'+((labelChiuse>1 || labelChiuse==0) ? 'e':'a') + ' già chius' +((labelChiuse>1 || labelChiuse==0) ? 'e':'a')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-check</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{labelLavorazione}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="'Scadenz'+((labelLavorazione>1 || labelLavorazione==0) ? 'e':'a') + ' in lavorazione'"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon color="(notifica3 ? 'blue darken-2' : 'gray'">mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Notifica su chiusura</v-list-item-title>
                                            <v-list-item-subtitle v-html="(!notifica3 ? 'Opzione non selezionata':'Opzione selezionata')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="cs = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendCS">
                                    Invia
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCS = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingCS" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCS && esitoCSOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCS && esitoCSKO" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCS = false"
                                       color="warning"
                                       :disabled="loadingCS">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-dialog v-model="dialogAO" persistent
                  max-width="800">
            <v-stepper v-model="ao">
                <v-stepper-header>
                    <v-stepper-step :complete="ao > 1"
                                    step="1">
                        Scelta operatore
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="ao > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formAO"
                                    v-model="validAO">
                                <v-row>

                                    <v-col cols="6" offset="3">
                                        <v-select v-model="idAssegnaOperatore"
                                                  :items="listaOperatore"
                                                  item-value="id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Operatore da assegnare"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare operatore']"
                                                  dense></v-select>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validAO" :loading="loadingAO" color="primary" depressed
                                       @click="ao=2">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogAO = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="7">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Da assegnare a {{descrizioneOperatore}}</v-list-item-title>
                                            <v-list-item-subtitle>Attualmente assegnate ad operatori diversi</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Già assegnati a {{descrizioneOperatore}}</v-list-item-title>
                                            <v-list-item-subtitle>Nessuna azione prevista</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-bulleted</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="labelDaAssegnare + ' scadenz'+(labelDaAssegnare>1 ? 'e':'a') + ' da assegnare'"></v-list-item-title>
                                            <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-message-check</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="labelAssegnate + ' scadenz'+(labelAssegnate>1 ? 'e':'a') + ' già assegnat'+(labelAssegnate>1 ? 'e':'a')"></v-list-item-title>
                                            <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="ao = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendAO">
                                    Invia
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogAO = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingAO" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingAO && esitoAOOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingAO && !esitoAOOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogAO = false"
                                       color="warning"
                                       :disabled="loadingAO">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>


        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>


    </v-container>   

</template>


<script>
    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, Snack, gDataCalendar } from '@/modules/utilities.js'
    import ScadenzaDialog from '@/views/components/ScadenzaDialog.vue'
    import { bus } from '@/main.js'

    export default {
                components: {
                    ScadenzaDialog
                }, 
                data: () => ({
                    selezione: [],
                    filtro: [],
                    statoAll: false,
                    loading: true,
                    showError: false,
                    viewMenuUtente: false,
                    snackSuccess: false,
                    snackError: false,
                    snackCancel: false,
                    snackRemote: false,
                    x: 0,
                    y: 0,
                    nPag: 8,
                    isAccessoLimitato: false,
                    mb: 1,
                    dialogMB: false,
                    validMB: false,
                    loadingMB: false,
                    esitoMBOK: false,
                    esitoMBKO: false,
                    cs: 1,
                    dialogCS: false,
                    validCS: false,
                    loadingCS: false,
                    esitoCSOK: false,
                    esitoCSKO: false,
                    ao: 1,
                    dialogAO: false,
                    validAO: false,
                    loadingAO: false,
                    esitoAOOK: false,
                    esitoAOKO: false,
                    idAssegnaOperatore: '',
                    search: '',
                    searchText: '',
                    dataA: '',
                    dataDa: '',
                    scadenze: [],
                    scadenza: {
                        id:'0'
                    },
                    headers: [
                        { text: 'Azioni', value: 'menu' },
                        { text: 'Cliente', value: 'cliente.denominazioneNormalizzata', width: '18%' },
                        { text: 'Operatore', value: 'operatore.denominazioneNormalizzata', width: '15%' },
                        { text: 'Importo e Data', align: 'center', value: 'dataScadenza' },
                        { text: 'Scadenza', value: 'descrizione' },
                        { text: 'Invio e esito', value: 'invio' },
                        { text: 'Data risposta e fonte', value: 'risposta' },
                        { text: 'Chiusa', value: 'status' },
                        { text: 'Elimina', value: 'elimina' },
                    ],
                    conteggioUtenti: [],
                    idOperatoreScadenza: {},
                    notifica: true,
                    operatore: { id: '' },
                    professionista: { id: '' },
                    cliente: { id: '' },
                    notifica1: true,
                    notifica2: true,
                    notifica3: false,
                    moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
                    },
                    statoScadenza: '2'
                }),
                methods: {
                    localData(v) {
                        return gLocalData(v);
                    },
                    importoEuro(v) {
                        return euro(v);
                    },
                    dataCalendar(v) {
                        return gDataCalendar(v);
                    },
                    descrizioneApp(v) {
                        if (v == false) return "Mail/Telefono";
                        return "App StudioPro";
                    },
                    descrizioneRisposta(v) {
                        if (v == false) return "Non autorizzata";
                        return "Autorizzata";
                    },
                    viewMB() {
                        this.mb = 1;
                        this.loadingMB = false;
                        this.dialogMB = true;
                        this.$nextTick(() => {
                            this.$refs.formMB.resetValidation();
                        });
                    },
                    viewCS() {
                        this.cs = 1;
                        this.loadingCS = false;
                        this.dialogCS = true;
                        this.$nextTick(() => {
                            this.$refs.formCS.resetValidation();
                        });
                    },
                    viewAO() {
                        this.ao = 1;
                        this.loadingAO = false;
                        this.dialogAO = true;
                        this.$nextTick(() => {
                            this.$refs.formAO.resetValidation();
                        });
                    },
                    async sendMB() {
                        this.loadingMB = true;
                        this.mb = 3;

                        //imposto lettura

                        let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            notifica1: this.notifica1,
                            notifica2: this.notifica2,
                            listaScadenze: this.selezione,
                        };

                        const result = await callPost('api/broadcast/inviaMessaggioScadenze', param);

                        this.esitoMBKO = !result;
                        this.esitoMBOK = result;
                        this.loadingMB = false;

                        await this.internalCreated(Snack.nothing);

                    },
                    async sendAO() {
                        this.loadingAO = true;
                        this.ao = 3;
                        let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            idOperatore: this.idAssegnaOperatore,
                            listaScadenze: this.selezione,
                        };

                        const result = await callPost('api/scadenze/assegnaoperatore', param);

                        this.esitoAOKO = !result;
                        this.esitoAOOK = result;
                        this.loadingAO = false;

                        await this.internalCreated(Snack.nothing);

                    },
                    async sendCS() {
                        this.loadingCS = true;
                        this.cs = 3;
                        let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            listaScadenze: this.selezione,
                        };

                        const result = await callPost('api/scadenze/chiudiscadenze', param);

                        this.esitoCSKO = !result;
                        this.esitoCSOK = result;
                        this.loadingCS = false;

                        await this.internalCreated(Snack.nothing);

                    },
                    async elimina(item) {
                        bus.$emit('msg-open', { message: "Eliminare la scadenza?", item });
                        bus.$on('msg-cancel', this.eliminaCancel);
                        bus.$on('msg-ok', this.eliminaOk);
                    }, 
                    async eliminaCancel(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);
                    },
                    async eliminaOk(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);

                        let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            sessionID: this.$store.state.sessionID,
                            id: item.id
                        };

                        this.loading = true;

                        const result = await callPost('api/scadenze/eliminaScadenza', param);

                        if (result) {
                            await this.internalCreated(Snack.success);
                        }
                        else {
                            await this.internalCreated(Snack.error);
                        }
                        this.loading = false;
                    },
                    filterItems(v){
                        this.$nextTick(() => {
                            this.filtro=this.$refs.tabella.$children[0].filteredItems;
                        });
                    },
                    anagrafica(scadenza) {
                        this.scadenza = JSON.parse(JSON.stringify(scadenza));
                        bus.$emit('dialog-show-scadenza', this.scadenza);
                    },  
                    scheda(utente) {
                       this.$router.replace({ name: "scheda", query: { id: utente.id } });
                    },
                    nuovo() {
                        this.scadenza.id = "0";
                        bus.$emit('dialog-show-scadenza', this.scadenza);
                    },
                    toggleAll() {
                        this.statoAll = !this.statoAll;
                        this.$store.dispatch('setStatoAll',this.statoAll);

                        if(this.statoAll) {
                            this.selezione=this.filtro;
                        }
                        else {
                            this.selezione=[];
                        };
                        this.$store.dispatch('setSelezione',this.selezione);
                    },
                    toggleSelect() {
                        this.$nextTick(() => {
                            this.$store.dispatch('setSelezione',this.selezione);
                        });
                    },
                    async onScadenzaChanged({ lista, sessionID }) {

                        if (sessionID == this.$store.state.sessionID) return;
                        for (var i = 0; i < lista.length; i++) {

                            let param = [lista[i]];
                            let scadenza = await callService("api/scadenze/scadenza", param);

                            //cerco nella lista il cliente ricalcolato

                            const index = this.scadenze.findIndex(u => {
                                return u.id === scadenza.id;
                            });

                            if (index >= 0) {
                                this.scadenze.splice(index, 1, scadenza);
                            }
                            else {
                                this.scadenze.push(scadenza);
                            }
                        }
                    },
                    onSnackSuccess() {
                        this.snackSuccess = true;
                    },
                    pagination(pag) {
                        let nPag = pag.itemsPerPage;
                        this.$cookie.set('scadenze-pagination', nPag.toString(), { expires: '1Y' });
                    },
                    async internalCreated(snack) {
                        let param = [];
                        this.scadenze = await callService('api/scadenze/listascadenzeglobali', param);
                        this.changeSearch();

                        this.snackSuccess = (snack == Snack.success);
                        this.snackError = (snack == Snack.error);
                        this.snackCancel = (snack == Snack.cancel);
                        this.snackRemote = (snack == Snack.remote);
                    },       
                    customFilter(value, search, item) {
                        let ok = true;
                        if (ok && this.searchText) { //codice/dnz/fiscale in OR
                            let src = this.searchText.toLowerCase();
                            let ok1 = item.cliente.denominazioneNormalizzata && item.cliente.denominazioneNormalizzata.toLowerCase().indexOf(src) >= 0;
                            let ok2 = !ok1 && item.descrizione && item.descrizione.toLowerCase().indexOf(src) >= 0;
                            let ok3 = !ok2 && item.cliente.codiceFiscale && item.cliente.codiceFiscale.toLowerCase().indexOf(src) >= 0;
                            let ok4 = !ok3 && item.cliente.codiceFiscaleLR && item.cliente.codiceFiscaleLR.toLowerCase().indexOf(src) >= 0;
                            let ok5 = !ok4 && item.importo.toString().indexOf(src) >= 0;
                            ok = ok1 || ok2 || ok3 || ok4 || ok5;
                        }
                        if (ok && this.idOperatoreScadenza) {
                            ok = item.operatore.id == this.idOperatoreScadenza;
                        }
                        if (ok && this.professionista.id) {
                            ok = item.cliente.professionista.id == this.professionista.id;
                        }
                        if (ok && this.cliente.id) {
                            ok = item.cliente.id == this.cliente.id;
                        }
                        if (ok) {
                            if (this.statoScadenza == '3') //TUTTI
                                ok = ok;
                            else if (this.statoScadenza == '1') //NUOVE
                                ok = !item.dataInvioRichiesta
                            else if (this.statoScadenza == '2') //IN LAVORAZIONE
                                ok = item.dataInvioRichiesta && !item.isChiusa
                            else if (this.statoScadenza == '4') //CHIUSE
                                ok = item.dataInvioRichiesta && item.dataRisposta && item.isChiusa
                        }

                        if (ok && this.dataDa) {
                            ok = this.dataCalendar(item.dataScadenza) >= this.dataCalendar(this.dataDa);
                        }
                        if (ok && this.dataA) {
                            ok = this.dataCalendar(item.dataScadenza) <= this.dataCalendar(this.dataA);
                        }

                        return ok;
                    },
                    changeSearch() {
                        this.search =
                            this.searchText +
                            this.idOperatoreScadenza +
                            this.professionista.id +
                            this.cliente.id +
                            this.statoScadenza +
                            this.dataDa +
                            this.dataA
                    },
                    
                },
                computed: {
                    hintSelezione() {
                        if(this.selezione.length==1) return "1 scadenza selezionata su "+this.filtro.length;
                        if(this.selezione.length>1) return this.selezione.length+" scadenze selezionate su "+this.filtro.length;
                        return "";
                    },
                    labelMB() {
                        return "Comunicazioni ai clienti (" + this.selezione.length + ")";
                    },
                    labelCS() {
                        return "Chiusura massiva delle scadenze selezionate (" + this.selezione.length + ")";
                    },
                    labelAO() {
                        return "Assegnazione scadenze ad un operatore (" + this.selezione.length + ")";
                    },
                    labelDaAssegnare() {
                        return this.selezione.filter(s => s.operatore.id != this.idAssegnaOperatore).length;
                    },
                    labelAssegnate() {
                        return this.selezione.filter(s => s.operatore.id == this.idAssegnaOperatore).length;
                    },
                    descrizioneOperatore() {
                        let p = this.listaOperatore.find(x => x.id === this.idAssegnaOperatore);
                        if (p) return p.denominazioneNormalizzata;
                        else return "";
                    },
                    labelNuove() {
                        return this.selezione.filter(s => s.dataInvioRichiesta == null && s.dataRisposta == null).length;
                    },
                    labelSollecito() {
                        return this.selezione.filter(s => s.dataInvioRichiesta != null && s.dataRisposta == null).length;
                    },
                    labelNoMessaggi() {
                        return this.selezione.filter(s => s.dataInvioRichiesta != null && s.dataRisposta != null).length;
                    },
                    labelChiuse() {
                        return this.selezione.filter(s => s.isChiusa == true).length;
                    },
                    labelLavorazione() {
                        return this.selezione.filter(s => s.isChiusa == false && s.dataInvioRichiesta != null).length;
                    }
                },
                async created() {

                    this.clientiEstesi = this.$store.state.clientiEstesi;
                    this.listaOperatore = this.$store.state.listaOperatore;
                    this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;


                    bus.$on('scadenza-changed', this.onScadenzaChanged);
                    bus.$on('scadenza-snack-succes', this.onSnackSuccess);

                    this.nPag = Number(this.$cookie.get('scadenze-pagination')); //leggo il coockie della paginazione

                    this.idOperatoreScadenza = this.$store.state.utente.operatore.id;

                    await this.internalCreated(Snack.nothing);


                    if (this.$store.state.utente.operatore.isProfessionista) {
                        this.professionista.id = this.$store.state.utente.operatore.professionista.id;
                        if (this.$store.state.utente.operatore.isAccessoLimitato) {
                            this.isAccessoLimitato = true;
                        }
                    }

                    this.loading = false;
                },
                beforeDestroy() {
                    bus.$off('scadenza-changed', this.onScadenzaChanged);
                    bus.$off('scadenza-snack-succes', this.onSnackSuccess);
                }
    }
</script>
