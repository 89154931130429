<template>
    <v-container>
        <v-row>
            <v-col cols="1">
                <v-btn color="teal" dark :loading="loading"
                       @click="value=new Date()">
                    Oggi
                    <v-icon right>mdi-calendar</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-autocomplete v-model="idOperatoreCalendar" class="ml-2"
                                :items="listaContattiCalendar"
                                chips
                                ref="destinatari"
                                no-data-text="Nessun utente trovato"
                                dense
                                outlined
                                persistent-hint
                                :readonly="!$store.state.utente.isReception"
                                placeholder="Professionista/Operatore"
                                item-text="nominativo"
                                item-value="id"
                                @change="change">
                    <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="remove(data.item)">
                            <v-avatar v-if="data.item.isAvatar" left>
                                <v-img style="object-fit:cover" :src="data.item.avatar"></v-img>
                            </v-avatar>
                            {{ data.item.nominativo }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-avatar color="indigo">
                                <span v-if="!data.item.isAvatar" class="white--text text-h7">{{data.item.inizialiNormalizzate}}</span>
                                <img style="object-fit:cover" v-if="data.item.isAvatar" :src="data.item.avatar" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-row>
                                    <v-col cols="10">
                                        <v-list-item-title>{{data.item.nominativo}}</v-list-item-title>
                                        <v-list-item-subtitle>{{data.item.ruolo}}</v-list-item-subtitle>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-row class="mt-1">
                    <v-btn icon
                           @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-toolbar-title class="mt-1" v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>

                    <v-btn icon
                           @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-row>

            </v-col>
            <v-col cols="2">
                <v-btn color="grey" dark
                       @click="apriDisponibilita">
                    Disponibilità
                    <v-icon right>mdi-calendar</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="2" offset="1" align="right">
                <v-select v-model="type"
                          outlined
                          dense
                          item-value="id"
                          item-text="text"
                          :items="[{id:'day',text:'Giorno'},
                                {id:'week',text:'Settimana'},
                                {id:'month',text:'Mese'}
                            ]">
                </v-select>
            </v-col>
        </v-row>
        <v-card>
            <v-calendar ref="calendar"
                        :start="dataStart"
                        v-model="value"
                        color="primary"
                        :type="type"
                        :events="eventi"
                        event-color="blue"
                        :event-ripple="false"
                        :weekdays="weekday"
                        :month-format="monthformat"
                        :weekday-format="weekdayformat"
                        :interval-format="intervalformat"
                        :first-interval=8
                        :interval-count=11
                        @mousedown:event="startDrag"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                        @click:event="clickEvent">

                <template v-slot:event="{ event, timed, eventSummary }">
                    <div class="v-event-draggable">
                        <span class="text-caption">
                            <b>{{event.appuntamento.testoAppuntamento}}</b><br />
                            {{event.appuntamento.oraInizio}}-{{event.appuntamento.oraFine}}
                        </span>
                    </div>
                    <div v-if="timed"
                         class="v-event-drag-bottom"
                         @mousedown.stop="extendBottom(event)">
                    </div>
                </template>
            </v-calendar>
        </v-card>
        <v-dialog v-model="dialogDisponibilita"
                  max-width="800px"
                  persistent>
            <v-form ref="formDisponibilita" lazy-validation
                    v-model="validDisponibilita">
                <v-card style=" overflow: hidden;">
                    <v-card-title class="text-subtitle-1 grey lighten-3">Disponibilità {{intestazione}}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4" offset="2">
                                <v-text-field v-model="disponibilita.dataIni"
                                              label="Data iniziale"
                                              outlined
                                              :rules="[v=> (v!=null && v.length>0 && /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v=> (v!=null && v.length>0 && /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkDataFutura(v)) || 'inserire una data futura' ]"
                                              hint="gg/mm/aaaa"
                                              persistent-hint
                                              clearable
                                              dense></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="disponibilita.dataFine"
                                              label="Data finale"
                                              outlined
                                              :rules="[v=> (v!=null && v.length>0 && /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v=> (v!=null && v.length>0 && /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkDataFutura(v)) || 'inserire una data futura' ]"
                                              hint="gg/mm/aaaa"
                                              persistent-hint
                                              clearable
                                              dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-for="(item, i) in disponibilita.lista" :key="i">
                            <v-col cols="2" offset="2">
                                <v-checkbox v-model="item.isDisponibile"
                                            class="mt-1"
                                            hide-details
                                            :label="item.giorno"
                                            @click="clickCheck(item)">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="2" offset="2">
                                <v-select v-model="item.oreInizio"
                                          :items="listaOre"
                                          item-value="id"
                                          item-text="id"
                                          label="Dalle"
                                          outlined
                                          :disabled="!item.isDisponibile"
                                          hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="2">
                                <v-select v-model="item.oreFine"
                                          :items="listaOre"
                                          item-value="id"
                                          item-text="id"
                                          label="Alle"
                                          outlined
                                          :disabled="!item.isDisponibile"
                                          hide-details
                                          dense></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <span class="ml-2">Cliccando sul tasto Salva eventuali disponibilità impostate in precedenza saranno sostituite</span>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>

                        <v-btn color="teal" depressed :dark="validDisponibilita"
                               @click="salvaDisponibilita"
                               :disabled="!validDisponibilita"
                               :loading="loadingSalva">
                            Salva
                            <v-icon right>
                                mdi-send
                            </v-icon>
                        </v-btn>

                        <v-btn @click="eliminaDisponibilitaCheck" :loading="loadingElimina" dark color="deep-orange darken-4">
                            Elimina Tutte le disponibilità
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialogDisponibilita=false" color="orange darken-2" dark>
                            Chiudi
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="dialogAppuntamento"
                  max-width="800px"
                  persistent>
            <v-form ref="formAppuntamento"
                    v-model="valid"
                    :disabled="dialogDisabled">
                <v-card style=" overflow: hidden;">
                    <v-card-title class="text-subtitle-1 grey lighten-3">{{headerDialog}}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <br />
                        <v-row>
                            <v-col cols="4">
                                <v-text-field v-model="appuntamento.dataInizio"
                                              label="Data"
                                              outlined
                                              :rules="[v=> (v!=null && v.length>0 && /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v=> (v!=null && v.length>0 && /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkDataFutura(v)) || 'inserire una data futura' ]"
                                              hint="gg/mm/aaaa"
                                              persistent-hint
                                              clearable
                                              dense></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-select v-model="appuntamento.oraInizio"
                                          :items="listaOre"
                                          item-value="id"
                                          item-text="id"
                                          label="Inizio"
                                          outlined
                                          @change="changeOraInizio"
                                          dense></v-select>
                            </v-col>
                            <v-col cols="2">
                                <v-select v-model="appuntamento.oraFine"
                                          :items="listaOre"
                                          item-value="id"
                                          item-text="id"
                                          label="Fine"
                                          outlined
                                          @change="changeOraFine"
                                          dense></v-select>
                            </v-col>
                            <v-col cols="4" v-if="!appuntamento.isDisponibilita">
                                <v-text-field v-model="appuntamento.luogo"
                                              label="Luogo"
                                              outlined
                                              dense>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="!appuntamento.isDisponibilita">
                            <v-col cols="10">
                                <v-text-field v-model="appuntamento.testoAppuntamento"
                                              label="Descrizione"
                                              outlined
                                              dense
                                              required
                                              :rules="[v=> !!v || 'inserire una descrizione']"></v-text-field>

                            </v-col>
                            <v-col cols="2" v-if="!appuntamento.isProvvisorio">
                                <v-select v-model="appuntamento.colore"
                                          :items="listaColori"
                                          dense
                                          outlined
                                          disable-lookup
                                          label="Colore"
                                          item-text="colore"
                                          item-value="colore">
                                    <template v-slot:selection="{item}">
                                        <v-icon class="ml-2" :color="item.colore">mdi-checkbox-blank-circle</v-icon>

                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-icon class="ml-2" :color="item.colore">mdi-checkbox-blank-circle</v-icon>
                                    </template>

                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="!appuntamento.isDisponibilita">
                            <v-col cols="12">
                                <v-autocomplete v-model="listaInvitati"
                                                :items="listaContatti"
                                                chips
                                                ref="destinatari"
                                                no-data-text="Nessun utente trovato"
                                                dense
                                                outlined
                                                persistent-hint
                                                hint="digitare i nomi dei partecipanti"
                                                placeholder="Operatore/Cliente"
                                                item-text="nominativo"
                                                multiple
                                                item-value="id">
                                    <template v-slot:selection="data">
                                        <v-chip v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="removeApp(data.item)">
                                            <v-avatar v-if="data.item.isAvatar" left>
                                                <v-img style="object-fit:cover" :src="data.item.avatar"></v-img>
                                            </v-avatar>
                                            {{ data.item.nominativo }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="typeof data.item !== 'object'">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-avatar color="indigo">
                                                <span v-if="!data.item.isAvatar" class="white--text text-h7">{{data.item.inizialiNormalizzate}}</span>
                                                <img style="object-fit:cover" v-if="data.item.isAvatar" :src="data.item.avatar" />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col cols="10">
                                                        <v-list-item-title>{{data.item.nominativo}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{data.item.ruolo}}</v-list-item-subtitle>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-badge v-if="data.item.daLeggere" dot color="red" overlap>
                                                        </v-badge>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row v-if="!appuntamento.isDisponibilita">
                            <v-col cols="12">
                                <v-textarea v-model="appuntamento.nota"
                                            label="Note"
                                            rows="3"
                                            outlined
                                            dense>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="3">
                                <v-btn color="teal" :dark="valid"
                                       @click="salvaAppuntamento"
                                       :disabled="!valid"
                                       :loading="loadingSalva">
                                    {{captionSalva}}
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="5">
                                <v-checkbox class="mt-0" v-model="notifica" v-if="!appuntamento.isDisponibilita"
                                            label="notifica riunione in StudioPro App"
                                            color="red darken-3">
                                </v-checkbox>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn v-if="!nuovo" @click="eliminaAppuntamento" :loading="loadingElimina" dark color="deep-orange darken-4">
                                    Elimina
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn @click="annullaModifiche" :loading="loadingAnnulla" dark color="orange darken-2">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>

    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'
    import { bus } from '@/main.js'


    export default {
        
        data: () => ({
            weekday: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            type: 'week',
            loading: false,
            loadingSalva: false,
            loadingElimina: false,
            loadingAnnulla: false,
            eventi: [],
            appuntamento: {},           
            dragEvent: null,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,
            dialogAppuntamento: false,
            dialogDisponibilita: false,
            listaOre: [],
            listaColori: [],
            listaContatti: [],
            listaContattiCalendar:[],
            listaInvitati: [],
            valid: false,
            validDisponibilita: false,
            snackSuccess: false,
            snackCancel: false,
            snackError: false,
            snackRemote: false,
            notifica: false,
            nuovo: false,
            dataStart: new Date(),
            dialogDisabled: false,
            idOperatoreCalendar:{},
            disponibilita: {}               
        }),
        computed: {
            headerDialog() {
                if(this.nuovo) return "Nuovo Appuntamento";
                else if(this.appuntamento.isDisponibilita) return "Aggiorna Disponibilità";
                else if (this.appuntamento.isProvvisorio) return "Conferma Appuntamento";

                return "Aggiorna Appuntamento";
            },
            captionSalva(){
                return this.appuntamento.isProvvisorio ? "Conferma":"Salva";
            },
            intestazione() {
                let contatto=this.listaContattiCalendar.find(c=>c.id==this.idOperatoreCalendar);
                if(contatto) return contatto.nominativo;
                return "";
            }

        },
        methods: {
            weekdayformat(d) {
                if (d.weekday == 1) return "LUN";
                if (d.weekday == 2) return "MAR";
                if (d.weekday == 3) return "MER";
                if (d.weekday == 4) return "GIO";
                if (d.weekday == 5) return "VEN";
                if (d.weekday == 6) return "SAB";
                if (d.weekday == 0) return "DOM";
            },
            monthformat(d) {
                if (d.month == 1) return "Gennaio";
                if (d.month == 2) return "Febbraio";
                if (d.month == 3) return "Marzo";
                if (d.month == 4) return "Aprile";
                if (d.month == 5) return "Maggio";
                if (d.month == 6) return "Giugno";
                if (d.month == 7) return "Luglio";
                if (d.month == 8) return "Agosto";
                if (d.month == 9) return "Settembre";
                if (d.month == 10) return "Ottobre";
                if (d.month == 11) return "Novembre";
                if (d.month == 12) return "Dicembre";

            },
            intervalformat(d) {
                return d.hour + ":00";
            },
            startDrag({ event, timed }) {
                if (event && timed) {
                    this.dragEvent = event
                    this.dragTime = null
                    this.extendOriginal = null
                }
            },
            startTime(tms) {
                const mouse = this.toTime(tms)

                if (this.dragEvent && this.dragTime === null) {
                    const start = this.dragEvent.start
                    this.dragTime = mouse - start
                } else {
           
                    this.createStart = this.roundTime(mouse)
                    this.createEvent = {
                        name: 'Nuovo Evento',
                        color: "blue",
                        start: this.createStart,
                        end: this.createStart,
                        timed: true,
                    }

                    //evito la creazione così non si vede a video sotto la dialog
                    //this.eventi.push(this.createEvent)

                    this.visualizzaAppuntamento(this.createEvent, true);
                }
            },
            extendBottom(event) {
                this.createEvent = event
                this.createStart = event.start
                this.extendOriginal = event.end
            },
            mouseMove(tms) {
                const mouse = this.toTime(tms)

                if (this.dragEvent && this.dragTime !== null) {
                    const start = this.dragEvent.start
                    const end = this.dragEvent.end
                    const duration = end - start
                    const newStartTime = mouse - this.dragTime
                    const newStart = this.roundTime(newStartTime)
                    const newEnd = newStart + duration

                    this.dragEvent.start = newStart
                    this.dragEvent.end = newEnd

                } else if (this.createEvent && this.createStart !== null) {
                    const mouseRounded = this.roundTime(mouse, false)
                    const min = Math.min(mouseRounded, this.createStart)
                    const max = Math.max(mouseRounded, this.createStart)

                    this.createEvent.start = min
                    this.createEvent.end = max
                }
            },
            endDrag() {
                var cambio = false;
                var ts1 = null;
                var ts2 = null;
                if(this.dragEvent) {
                    if(this.dragEvent.appuntamento) {
                        ts1 = new Date(this.dragEvent.appuntamento.dataInizio).getTime();
                    }
                    else ts1=0;
                    ts2 = this.dragEvent.start;
                }
                if(this.extendOriginal) {
                    ts1 = new Date(this.createEvent.appuntamento.dataFine).getTime();
                    ts2 = this.createEvent.end;
                }
                if (this.dragEvent || this.extendOriginal) {
                    var d1 = new Date(ts1)+"";
                    var d2 = new Date(ts2)+""
                    var cambio =  (d1 != d2 );
                    if(cambio) {
                        this.visualizzaAppuntamento(this.dragEvent ? this.dragEvent : this.createEvent, false );
                    }
                }

                this.dragTime = null
                this.dragEvent = null
                this.createEvent = null
                this.createStart = null
                this.extendOriginal = null
            },
            cancelDrag() {
                if (this.createEvent) {
                    if (this.extendOriginal) {
                        this.createEvent.end = this.extendOriginal
                    } else {
                        const i = this.eventi.indexOf(this.createEvent)
                        if (i !== -1) {
                            this.eventi.splice(i, 1)
                        }
                    }
                }

                this.createEvent = null
                this.createStart = null
                this.dragTime = null
                this.dragEvent = null
            },
            clickEvent(event) {
                this.visualizzaAppuntamento(event.eventParsed.input, false);

            },
            visualizzaAppuntamento(event, nuovo) {
                
                let dtIni = new Date(event.start);
                let dtFine = new Date(event.end);                

                let oreIni = dtIni.getHours().toString().padStart(2, '0');
                let minutiIni = dtIni.getMinutes().toString().padStart(2, '0');
                let oreFine = dtFine.getHours().toString().padStart(2, '0');
                let minutiFine = dtFine.getMinutes().toString().padStart(2, '0');

                this.appuntamento = event.appuntamento;
                if(!this.appuntamento || nuovo) {

                    let oreI= oreIni+":00";
                    let id = this.listaOre.findIndex(o=>o == oreI);
                    id = id + 4;
                    if(id>=this.listaOre.length) id = this.listaOre.length -1;
                    let oreF= this.listaOre[id];                   

                    this.appuntamento = {
                        testoAppuntamento: "",
                        dataInizio : this.localData(new Date(event.start)),
                        oraInizio : oreI,
                        oraFine : oreF
                    }
                    this.listaInvitati=[];

                }
                else {                    

                    this.appuntamento.dataInizio = this.localData(new Date(event.start));
                    this.appuntamento.oraInizio = oreIni+":"+minutiIni;
                    this.appuntamento.oraFine = oreFine+":"+minutiFine;

                    this.listaInvitati = event.listaInvitati;
                }
                this.nuovo = nuovo;
                this.dialogDisabled = !this.checkDataFutura(this.appuntamento.dataInizio);
                if (nuovo && this.dialogDisabled) return;

                this.dialogAppuntamento = true;
                this.$nextTick(() => {
                    this.$refs.formAppuntamento.resetValidation();
                });
            },
            async annullaModifiche() {                
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);
                this.dialogAppuntamento = false;
                this.loadingAnnulla = false;
            },
            removeApp(item) {
                this.listaInvitati = this.listaInvitati.filter(c => c!=item.id);             
            },
            roundTime(time, down = true) {
                const roundTo = 15 // minutes
                const roundDownTime = roundTo * 60 * 1000

                return down
                    ? time - time % roundDownTime
                    : time + (roundDownTime - (time % roundDownTime))
            },
            toTime(tms) {
                return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(d) {                
                return gCheckData(d);
            },
            checkDataFutura(d) {
                let ok = gCheckData(d);
                if (!ok) return false;

                let adesso = new Date();
                let data = new Date(this.serverData(d));
                data.setDate(data.getDate() + 1);
                return data.getTime()>adesso.getTime(); 
            },
            changeOraInizio() {
                var id = this.listaOre.findIndex(o=>o == this.appuntamento.oraInizio);

                id = id + 4; //ora successiva

                if(id>=this.listaOre.length) id = this.listaOre.length -1;
                this.appuntamento.oraFine= this.listaOre[id];

            },
            changeOraFine() {
                var idF = this.listaOre.findIndex(o => o == this.appuntamento.oraFine);
                var idI = this.listaOre.findIndex(o => o == this.appuntamento.oraInizio);

                if (idF <= idI) {
                    idI = idF - 4;
                }

                if (idI < 0) idI = 0;
                this.appuntamento.oraInizio = this.listaOre[idI];

            },
            serverData(stringDate) {                
                return gServerData(stringDate);
            },
            async salvaAppuntamento(event) {

                if (!this.$refs.formAppuntamento.validate()) return;

                //cambio date//
                let appuntamento = JSON.parse(JSON.stringify(this.appuntamento));

                appuntamento.dataInizio = this.serverData(this.appuntamento.dataInizio);
                this.dataStart=appuntamento.dataInizio.replaceAll('/', '-');

                appuntamento.dataFine = appuntamento.dataInizio + "T" + appuntamento.oraFine;
                appuntamento.dataInizio = appuntamento.dataInizio + "T" + appuntamento.oraInizio;


                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idOperatore: this.idOperatoreCalendar,
                    appuntamento: appuntamento,
                    listaInvitati: this.listaInvitati,
                    notifica: this.notifica
                };
                this.loadingSalva = true;
                const ok = await callPost('api/calendario/aggiornaAppuntamento', paramJson);
                this.dialogAppuntamento = false;
                await this.internalCreated(ok ? Snack.success : Snack.error);                
                this.loadingSalva = false; 

            },
            async eliminaAppuntamento(event) {

                //cambio date//
                let appuntamento = JSON.parse(JSON.stringify(this.appuntamento));

                appuntamento.dataInizio = this.serverData(this.appuntamento.dataInizio);
                this.dataStart = appuntamento.dataInizio.replaceAll('/', '-');

                appuntamento.dataFine = appuntamento.dataInizio + "T" + appuntamento.oraFine;
                appuntamento.dataInizio = appuntamento.dataInizio + "T" + appuntamento.oraInizio;

                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idOperatore: this.idOperatoreCalendar,
                    appuntamento: appuntamento,
                    listaInvitati: this.listaInvitati,
                    notifica: this.notifica
                };

                this.loadingElimina = true;
                const ok = await callPost('api/calendario/eliminaAppuntamento', paramJson);
                this.dialogAppuntamento = false;
                await this.internalCreated(ok ? Snack.success : Snack.error);                
                this.loadingElimina = false; 

            },
            async onCalendarioChanged({ lista, sessionID }) {
                if (sessionID == this.$store.state.sessionID) return;

                if (lista.find(cod => cod == this.idOperatoreCalendar)) {
                    await this.internalCreated(Snack.remote);
                }                
            },

            onCalendarioSetDate({ dataInizio }) {
                this.dataStart = new Date(dataInizio);
            },
            async internalCreated(snack) {
                let param = [];
                this.listaOre = await callService('api/calendario/listaOre', param);

                param = [];
                this.listaColori = await callService('api/calendario/listaColori', param);                

                param = [this.idOperatoreCalendar];
                const data = await callService('api/calendario', param);
                const eventi = [];

                data.forEach(function (appExt) {
                    let app = appExt.appuntamento;
                    eventi.push({
                        name: app.testoAppuntamento,
                        start: new Date(app.dataInizio),
                        end: new Date(app.dataFine),
                        color: app.colore ? app.colore : 'blue',
                        timed: true,
                        //campi personalizzati
                        appuntamento: app,
                        listaInvitati: appExt.listaInvitati
                    });
                });
                this.eventi = eventi;                

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            async change() {
                this.loading=true;
                await this.internalCreated(Snack.nothing);
                this.loading=false;
            },
            remove(item) {
                if(this.$store.state.utente.isReception) {
                    this.idOperatoreCalendar = {};
                    this.eventi=[];
                }
            },
            apriDisponibilita() {
                this.disponibilita = {
                    dataIni: new Date(), dataFine: new Date(),
                    lista:
                        [
                            { giorno: 'lunedì', isDisponibile: false, oreInizio: null, oreFine: null },
                            { giorno: 'martedì', isDisponibile: false, oreInizio: null, oreFine: null },
                            { giorno: 'mercoledì', isDisponibile: false, oreInizio: null, oreFine: null },
                            { giorno: 'giovedì', isDisponibile: false, oreInizio: null, oreFine: null },
                            { giorno: 'venerdì', isDisponibile: false, oreInizio: null, oreFine: null }
                        ]
                };
                var today = new Date();
                this.disponibilita.dataIni = this.localData(today);
                this.disponibilita.dataFine = this.localData(today.setMonth(today.getMonth() + 1));
                this.dialogDisponibilita = true;
                this.$nextTick(() => {
                    this.$refs.formDisponibilita.resetValidation();
                });
            },
            async salvaDisponibilita() {
                var almenoUna = false;
                var errore = false;
                var ordine = false;
                for (var i = 0; i < 5 && !errore && !ordine; i++) {
                    if (this.disponibilita.lista[i].isDisponibile) {almenoUna = true;}

                    if (this.disponibilita.lista[i].isDisponibile
                         && (this.disponibilita.lista[i].oreInizio == null || this.disponibilita.lista[i].oreFine == null)) errore = true;
                    else if (this.disponibilita.lista[i].isDisponibile
                         && (this.disponibilita.lista[i].oreInizio>=this.disponibilita.lista[i].oreFine)) ordine = true;
                }
                if (!almenoUna) bus.$emit('msg-simple-open', { message: "Impostare almeno un giorno di disponibilità", title: "Disponibilità" });
                else if (errore) bus.$emit('msg-simple-open', { message: "Impostare gli intervalli orari per tutti i giorni di disponibilità", title: "Disponibilità" });
                else if (ordine) bus.$emit('msg-simple-open', { message: "Impostare correttamente gli intervalli orari", title: "Disponibilità" });
                else {
                    //cambio date//
                    let disponibilita = JSON.parse(JSON.stringify(this.disponibilita));

                    disponibilita.dataIni = this.serverData(this.disponibilita.dataIni);
                    disponibilita.dataFine = this.serverData(this.disponibilita.dataFine);                    

                    let paramJson = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.utente.username,
                        token: this.$store.state.utente.token,
                        sessionID: this.$store.state.sessionID,
                        idOperatore: this.idOperatoreCalendar,
                        disponibilita: disponibilita,
                    };
                    this.loadingSalva = true;
                    const ok = await callPost('api/calendario/aggiornaDisponibilita', paramJson);
                    this.dialogDisponibilita = false;
                    await this.internalCreated(ok ? Snack.success : Snack.error);
                    this.loadingSalva = false; 
                }
            },
            async eliminaDisponibilitaCheck() {
                bus.$emit('msg-open', { message: "Eliminare tutte le disponibilità?", item: null });                
                bus.$on('msg-ok', this.eliminaDisponibilita);
            }, 
            async eliminaDisponibilita() {
                    let paramJson = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.utente.username,
                        token: this.$store.state.utente.token,
                        sessionID: this.$store.state.sessionID,
                        idOperatore: this.idOperatoreCalendar,
                    };
                    this.loadingElimina = true;
                    const ok = await callPost('api/calendario/eliminaDisponibilita', paramJson);
                    this.dialogDisponibilita = false;
                    await this.internalCreated(ok ? Snack.success : Snack.error);
                    this.loadingElimina = false;
            },
            clickCheck(item) {
                if (!item.isDisponibile) {
                    item.oreInizio = null;
                    item.oreFine = null;
                }
            }
        },
        async created() {
            bus.$on('calendario-setdate', this.onCalendarioSetDate);
            this.$updaterHub.$on('calendario-changed', this.onCalendarioChanged);

            this.loading = true;

            let param = [];

            param =[];
            const professionisti = await callService('api/professionista/listaprofessionisticompleta',param);

            param =[];
            const operatori = await callService('api/operatore/listaoperatoricompleta',param);

            param =[];
            const clienti = await callService('api/cliente/listaclientiapp',param);

            const listaContatti = [];
            const listaContattiCalendar = [];

            listaContatti.push(
                {header: "Professionisti"}
            );
            listaContattiCalendar.push(
                {header: "Professionisti"}
            );
            for(const operatore of professionisti) {
                param=[this.$store.state.utente.operatore.id,operatore.id,"S"];
                listaContatti.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );
                listaContattiCalendar.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );

            };
           
            listaContatti.push(
                {header: "Operatori"}
            );
            listaContattiCalendar.push(
                {header: "Operatori"}
            );
            
            for(const operatore of operatori) {                
                listaContatti.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );
                    listaContattiCalendar.push(
                    {
                      id: operatore.id,
                      nominativo: operatore.denominazioneNormalizzata,
                      ruolo: operatore.ruolo,
                      avatar: operatore.avatarLink,
                      isAvatar: operatore.isAvatar,
                      inizialiNormalizzate: operatore.inizialiNormalizzate,
                    },
                );
            };

            listaContatti.push(
                {header: "Clienti App"}
            );
            for(const cliente of clienti) {                
                listaContatti.push(
                    {
                      id: cliente.id,
                      nominativo: cliente.denominazioneNormalizzata,
                      ruolo: "Cliente",
                      avatar: cliente.avatarLink,
                      isAvatar: cliente.isAvatar,
                      inizialiNormalizzate: cliente.inizialiNormalizzate,
                    },
                );
            };
            
            this.listaContatti = listaContatti;
            this.listaContattiCalendar = listaContattiCalendar;

            this.idOperatoreCalendar = this.$store.state.utente.operatore.id;
            await this.internalCreated(Snack.nothing);
            this.loading = false;
        },
        beforeDestroy() {
            bus.$off('calendario-setdate', this.onCalendarioSetDate);
            this.$updaterHub.$off('calendario-changed', this.onCalendarioChanged);
        }        

    }</script>

<style scoped lang="scss">
    .v-event-draggable {
        padding-left: 6px;
    }

    .v-event-timed {
        user-select: none;
        -webkit-user-select: none;
    }

    .v-event-drag-bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        height: 4px;
        cursor: ns-resize;

        &::after {
            display: none;
            position: absolute;
            left: 50%;
            height: 4px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            width: 16px;
            margin-left: -8px;
            opacity: 0.8;
            content: '';
        }

        &:hover::after {
            display: block;
        }
    }
</style>