<template>
    <v-card class="ma-2" :height="hCard" :loading="loading" color="light-green darken-1" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-chart-timeline-variant
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Distribuzione Fido Clienti - {{nomeProf}}
            </span>
        </v-card-title>
        <v-card-text>
            <GChart v-if="datiClienti && datiClienti.length>1" type="PieChart" 
                    :data="chartClienti"
                    :options="chartOptionsClienti"                    
                    :settings="{language: 'it'}" />
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>

<script>import { callService } from '@/modules/utilities.js'

    import { GChart } from 'vue-google-charts/legacy'

    export default {
        props: ['idProf'],
        components: { GChart },
        data: () => ({
            loading: false,
            hCard: 450,
            datiClienti: null,
            chartOptionsClienti: {
                height: 400,
                chartArea: { width: '90%', height: '90%' },
                pieSliceText: 'value-and-percentage',
                legend: { position: 'left' },
                backgroundColor: { fill: 'transparent' },
                colors: ["teal", "orange", "brown"]
                //vAxis: { format: '€ #' }
            },
            nomeProf: ''
        }),
        methods: {
            async load() {
                this.loading = true;
                let clientiEstesi = this.$store.state.clientiEstesi.filter(ce=>!ce.cliente.isCancellato);
                if (this.idProf) {
                    clientiEstesi = clientiEstesi.filter(ce => ce.cliente.professionista.id == this.idProf);
                    let operProf = this.$store.state.listaOperatoriProfessionisti.find(o => o.professionista.id == this.idProf)
                    this.nomeProf = operProf.denominazioneNormalizzata;
                }
                else {
                    this.nomeProf = "Totale";
                }

                let gialli = clientiEstesi.filter(ce => ce.fido == "giallo").length;;
                let rossi = clientiEstesi.filter(ce => ce.fido == "rosso").length;
                let verdi = clientiEstesi.filter(ce => ce.fido == "verde").length;
                this.datiClienti = [
                    ["Fido", "N"],
                    ["Clienti in Attivo", verdi],
                    ["Fido Utilizzato", gialli],
                    ["Fido Superato", rossi]
                ];
                this.loading = false;
            }
        },
        computed: {
            chartClienti() {
                if (this.datiClienti == null) return null;
                return this.datiClienti;
            }
        },
        watch: {
            idProf: function (val) {
                this.load();
            }
        },
        async created() {
            await this.load();
        }
    }</script>

