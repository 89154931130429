<template>
    <v-dialog v-model="dialog" persistent max-width="550px">
        <v-card>
            <v-card-title class="text-subtitle-1 grey lighten-2">
                Richiesta conferma
            </v-card-title>
            <v-card-text>
                <br />
                <p>{{message}}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="deep-orange darken-4" dark
                       @click="onOk">
                    {{okTxt}}
                </v-btn>
                <v-btn color="teal" dark
                       @click="onAnnulla">
                    {{cancelTxt}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import { bus } from '@/main.js'

    export default {
        data: () => ({
            dialog: false,
            message: '',
            item: {},
            okTxt: 'Ok',
            cancelTxt:'Annulla' 
        }),
        methods: {                      
            onOpen({ message, item, okTxt, cancelTxt }) {
                this.message = message;
                this.item = item;
                
                if(okTxt) this.okTxt=okTxt;
                else this.okTxt ='Ok';

                if(cancelTxt) this.cancelTxt=cancelTxt;
                else this.cancelTxt = 'Annulla';

                this.dialog = true;
            },
            onOk() {
                bus.$emit('msg-ok',this.item);
                this.dialog = false;
            },
            onAnnulla() {
                bus.$emit('msg-cancel',this.item);
                this.dialog = false;
            },
        },
        async created() {           
            bus.$on('msg-open', this.onOpen)            
        },
        beforeDestroy() {
            bus.$off('msg-open', this.onOpen);
        }
    }

</script>

