<template>
    <v-app>
        <v-container fill-height fluid style="background-color:gray">
            <v-row align="center"
                   justify="center">
                <v-col cols="8">
                     <v-card class="elevation-24 rounded-lg">
                         <v-card-text>
                            <v-row class="mt-0 mb-2" align="center">
                                 <v-col v-if="$store.state.conf.isService" cols="4">                                     
                                     <v-img v-if="theme" src="@/assets/servicepro_login_dark.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                     <v-img v-else src="@/assets/servicepro_login.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                 </v-col>
                                 <v-col v-else cols="4">
                                    <v-img v-if="theme" src="@/assets/studiopro_login_dark.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                     <v-img v-else src="@/assets/studiopro_login.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                 </v-col>
                                 <v-col>
                                    <p class="font-weight-black text-h4">Copia Partizione</p>
                                     <p v-if="admin" class="text-caption">admin</p>
                                 </v-col>
                             </v-row>                            

                             <v-stepper v-model="step" class="mx-2">
                                 <v-stepper-header>
                                     <v-stepper-step :complete="step > 1"
                                                     step="1">
                                         Dati Partizione
                                     </v-stepper-step>

                                     <v-divider></v-divider>

                                     <v-stepper-step :complete="step > 2"
                                                     step="2">
                                         Creazione ed Esito
                                     </v-stepper-step>
                                 </v-stepper-header>

                                 <v-stepper-items>

                                     <v-stepper-content step="1">
                                         <v-form ref="form1" v-model="valid1">
                                             <v-row class="mt-2">
                                                 <v-col cols="4" offset="2">
                                                     <v-select v-model="oldDB"
                                                               outlined
                                                               label="DB di partenza"
                                                               dense                                                                                                                           
                                                                :rules="[v=>  !!v  || 'campo obbligatorio']"
                                                                :items="[
                                                                        'StudioProDB',
                                                                        'StudioProDB-Demo',
                                                                        'StudioProDB-Test',
                                                                        'StudioProDB2',
                                                                        'ServiceProDB',
                                                                        'ServiceProDB-Demo'
                                                                       ]">
                                                     </v-select>                                                     
                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="oldPartition"
                                                                   label="partizione da copiare"
                                                                   outlined
                                                                   @keyup="uppercaseOldPartition"
                                                                   :rules="[v=>  !!v  || 'campo obbligatorio']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="4" offset="2">
                                                     <v-select v-model="newDB"
                                                               outlined
                                                               label="DB di arrivo"
                                                               dense
                                                               :rules="[v=>  !!v  || 'campo obbligatorio', oldDB!=newDB || 'indicare DB diversi']"
                                                               :items="[
                                                                        'StudioProDB',
                                                                        'StudioProDB-Demo',
                                                                        'StudioProDB-Test',
                                                                        'StudioProDB2',
                                                                        'ServiceProDB',
                                                                        'ServiceProDB-Demo'
                                                                       ]">
                                                     </v-select>
                                                 </v-col>
                                                <v-col cols="4">
                                                    <v-text-field v-model="newPartition"
                                                                label="nuova partizione"
                                                                outlined
                                                                @keyup="uppercaseNewPartition"
                                                                :rules="[v=>  !!v  || 'campo obbligatorio']"
                                                                dense>
                                                    </v-text-field>
                                                </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col offset="2" cols="4">
                                                     <v-checkbox
                                                         label="Copia anche i dati e sposta AppID"
                                                         v-model="dati"
                                                         small/>
                                                 </v-col>
                                             </v-row>
                                             <v-row v-if="dati">
                                                 <v-col offset="2" cols="4">
                                                     <v-select v-model="lplApp.id"
                                                               :items="lplApps"
                                                               item-value="id"
                                                               item-text="appID"
                                                               label="App da spostare"
                                                               :rules="[v=> !!v || 'campo obbligatorio']"
                                                               outlined
                                                               dense>
                                                         <template v-slot:selection="data">
                                                             <template>
                                                                 {{data.item.partition }} [{{data.item.appID }} ]
                                                             </template>
                                                         </template>
                                                         <template v-slot:item="data">
                                                             <template>
                                                                 {{data.item.partition }} [{{data.item.appID }} ]
                                                             </template>
                                                         </template>

                                                     </v-select>
                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="newAppID"
                                                                   label="nuova AppID"
                                                                   outlined
                                                                   :rules="[v=>  !!v  || 'campo obbligatorio']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="2">
                                                     <v-btn @click="chiudi" color="warning">
                                                         Esci
                                                     </v-btn>
                                                 </v-col>
                                                 <v-spacer></v-spacer>
                                                 <v-col cols="3" align="right">
                                                     <v-btn :disabled="!valid1" color="primary" depressed
                                                            @click="copia">
                                                         Copia Partizione
                                                     </v-btn>
                                                 </v-col>
                                             </v-row>

                                         </v-form>
                                     </v-stepper-content>

                                     <v-stepper-content step="2">
                                         <v-form ref="form3">
                                             <v-row class="my-12">
                                                 <v-col v-if="loading" offset="2" cols="8">
                                                     <v-progress-linear indeterminate
                                                                        color="yellow darken-2">
                                                     </v-progress-linear>
                                                 </v-col>
                                                 <v-col v-if="!loading & result" offset="3" cols="6">
                                                     <v-row>
                                                         <v-col align="center">
                                                             <v-alert color="teal"
                                                                      dark
                                                                      icon="mdi-information-slab-circle-outline">
                                                                 Copia Partizione riuscita!
                                                             </v-alert>
                                                         </v-col>
                                                     </v-row>
                                                     <v-row>
                                                         <v-col align="center">
                                                             <v-btn color="info" to="/login">
                                                                 Vai alla login
                                                             </v-btn>
                                                         </v-col>
                                                     </v-row>
                                                 </v-col>
                                                 <v-col v-if="!loading & !result" offset="4" cols="4">
                                                     <v-row>
                                                         <v-col align="center">
                                                             <v-alert color="deep-orange darken-4"
                                                                      dark
                                                                      icon="mdi-alert-circle-outline">
                                                                 Copia Partizione non riuscita.
                                                             </v-alert>
                                                         </v-col>
                                                     </v-row>
                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="2">
                                                     <v-btn v-if="!result && !loading" @click="step--" color="warning">
                                                         Indietro
                                                     </v-btn>
                                                 </v-col>
                                                 <v-spacer></v-spacer>
                                             </v-row>
                                         </v-form>

                                     </v-stepper-content>

                                 </v-stepper-items>
                             </v-stepper>
                         </v-card-text>
                     </v-card>
                </v-col>    
            </v-row>
        </v-container>
        
    </v-app>
</template>

<script>

    import { callPost, callService, gCheckData, gServerData, gDataCalendar } from '@/modules/utilities.js'

    export default {
        name: 'NewPartition',
        data() {
            return {
                lplApp: {},
                utente: {},
                operatore: {},
                step: 1,
                valid1: false,
                valid2: false,
                loading: false,
                result:false,
                lplApps:[],
                listaComuni:[],
                oldDB: '',
                newDB: '',
                newPartition: '',
                newAppID: '',
                oldPartition: '',
                oldAppID: '',
                dati: false,
                otp: '',
                otpCliente: '',
                otpFulcro: '081174',
                utenzaPresente: false
            }
        },
        computed: {
            admin(){
                return this.$store.state.admin;
            },
            theme() {
                return this.$cookie.get('theme') == "true";
            },
            valid3() {
                return (this.otp && this.otpCliente && this.otp == this.otpCliente) || this.otpCliente == this.otpFulcro;
            }
        },
        methods: {                                  
            chiudi() {
                this.$store.state.admin = false;
                this.$router.replace({ name: "login" });
            },
            uppercaseNewPartition() {
                this.newPartition = this.newPartition.toUpperCase();
            },   
            uppercaseOldPartition() {
                this.oldPartition=this.oldPartition.toUpperCase();
            },            
            checkData(d) {
                return gCheckData(d);
            },
            serverData(d) {
                return gServerData(d);
            },
            dataCalendar(d){
                return gDataCalendar(d);
            },
            async copia() {
                this.step++;
                this.loading = true;
                
                let paramJson = {
                    dati: this.dati,
                    lplApp: this.lplApp,
                    newDB: this.newDB,
                    oldDB: this.oldDB,
                    newPartition: this.newPartition,
                    oldPartition: this.oldPartition,
                    newAppID: this.newAppID
                };
                this.result = await callPost('api/partition/copy', paramJson);
                this.loading = false;
            }
        },        
        async created() {
            this.$store.dispatch('logout');
            this.listaComuni = this.$store.state.listaComuni;
            let param = [];
            this.lplApps = await callService('api/partition', param, true);
            let output = await callService('api/partition/start', param, true);
            output.lplApp.dataScadenza = this.dataCalendar(output.lplApp.dataScadenza);
            this.lplApp = output.lplApp;
            this.operatore=output.operatore;
            this.utente = output.utente;

            this.$nextTick(() => {
                this.$refs.form1.resetValidation();
                this.$refs.form2.resetValidation();
                this.$refs.form3.resetValidation();
            });

            
        },  

    }</script>
